// @flow
import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions, treeViewActions } from '../../../reducers/actions';
import TreeView from '../../../components/treeview';
import { useTreeViewContext } from '../../../contexts/TreeViewContext';
import { treeData } from '../../../constants/treeData';
import { cpGetAllInitialFolder, cpFolderReset } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { setEditorChanges } from '../storage';
import { useNotification } from '@ovrture/react-redux/dist/helpers/toasterNotification/NotificationContext';
import { parentTemplate } from '../../../components/treeview/react-simple-jstree';
import { RedirectTo } from '../../../routes/role/path';

const AddContentPanelModal = (props) => {
    const { modalDispatch, modal } = useModalContext();
    const { treeState, treeDispatch } = useTreeViewContext();
    const [treeListData, setTreeListData] = useState(null);
    const [currentSelectedData, setCurrentSelectedData] = useState(null);
    const [contentData, setContentData] = useState();
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [data, setData] = useState([]);
    const [isParentTemplate, setIsParentTemplate] = useState(false);
    const [isSelectable, setIsSelectable] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { Toast } = useNotification();
    const { initialList } = useSelector((state) => ({
        initialList: state?.cpFolder?.getAllInitialFolder,
    }));

    useEffect(() => {
        if (previewModalOpen) {
            modalDispatch({
                type: modalActions.OPEN_MODAL,
                payload: {
                    modalAction: () => {
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        });
                    },
                    data: {
                        data: contentData,
                        jstreeData: data,
                        handleData: props?.data?.handleData,
                        info: props?.data?.info,
                        pkRecordsId: props?.data?.pkRecordsId,
                        slotInfo: props?.data?.slotInfo,
                        slotName: props?.data?.slotName,
                        page: props?.data?.page,
                        year: props?.data?.year,
                        isParentTemplate: isParentTemplate,
                    },
                    modalVariant: 'primary',
                    modalDataKey: ['common', 'contentPanelPreview'],
                },
            });
        }
    }, [previewModalOpen]);

    useEffect(() => {
        if (treeState?.treeData) {
            treeDispatch({
                type: treeViewActions.GET_TREE_DATA,
                payload: {
                    treeData: treeData,
                },
            });
        }
        if (treeState?.treeData) {
            setTreeListData(treeState);
        }
    }, [treeState?.treeData]);

    useEffect(() => {
        if (initialList?.data) {
            setLoading(false);
            async function test() {
                await manageTreeData(data);
                dispatch(cpFolderReset());
            }
            if (data.length > 0) {
                test(initialList?.data);
            } else {
                initialList?.data?.forEach((el) => {
                    data.push({
                        id: el.id,
                        parent_id: el.id,
                        text: el?.text || el?.name,
                        icon: el.icon,
                        children: el.child ? [] : [],
                        child: el.child,
                        adminId: el.adminId,
                        assetId: el.assetId,
                        brandId: el.brandId,
                        canBeDeleted: el.canBeDeleted,
                        fkRoleId: el.fkRoleId,
                        fkOrgId: el.fkOrgId,
                        fkPaletteId: el.fkPaletteId,
                        assetRootNodeId: el.assetRootNodeId,
                    });
                });
                setData([...data]);
            }
            dispatch(cpFolderReset());
        }
    }, [initialList]);

    const manageTreeData = (list) => {
        let folderViewId = [];
        const folderViewList = document.querySelectorAll('.slideSecClass [folder_view_id]');
        folderViewList?.forEach((folderView) => {
            folderViewId.push(folderView.getAttribute('folder_view_id'));
        });
        initialList?.data?.forEach((el) => {
            list?.forEach((d) => {
                if (el?.parentId === d?.id) {
                    let disableData = {};
                    if (folderViewId.find((f) => Number(f) === el.id)) {
                        disableData = {
                            state: {
                                disabled: true,
                            },
                        };
                    }
                    d.children.push({
                        id: el.id,
                        parent_id: el.parentId,
                        text: el?.text || el?.name,
                        icon: el.icon,
                        children: [],
                        child: el.child,
                        adminId: el.adminId,
                        assetId: el.assetId,
                        brandId: el.brandId,
                        canBeDeleted: el.canBeDeleted,
                        fkRoleId: el.fkRoleId,
                        fkOrgId: el.fkOrgId,
                        fkPaletteId: el.fkPaletteId,
                        assetRootNodeId: el.assetRootNodeId,
                        ...disableData,
                    });
                    const filterArry = d.children.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
                    d.children = [...filterArry];
                } else {
                    if (d.children.length > 0) {
                        manageTreeData(d.children);
                    }
                }
            });
        });
        setData([...list]);
    };

    useEffect(() => {
        if (props?.data?.jstreeData) {
            setData(props?.data?.jstreeData);
            getInitialFolderList(props?.data?.data?.parent_id);
        } else {
            localStorage.removeItem('activeJstreePath');
            setData([]);
            getInitialFolderList();
        }
    }, []);

    const getInitialFolderList = (parentId = '#') => {
        const ele = document.getElementById(parentId);
        if (ele) ele.getElementsByClassName('jstree-icon jstree-ocl')[0].classList.add('loading');
        dispatch(
            cpGetAllInitialFolder({
                processId:
                    props?.data?.page === 'multi-site' ||
                    props?.data?.page === 'multi-report' ||
                    props?.data?.page === 'site-report-editor'
                        ? 17
                        : 15,
                parentId,
            })
        );
    };

    function handleGetList(e, list) {
        const isEnable = list?.node?.original.icon === 'glyphicon glyphicon-text-background-cp';
        setIsParentTemplate(parentTemplate(list?.node?.parent));
        setIsSelectable(!isEnable);
        setCurrentSelectedData(list?.node?.original);
        setContentData({ ...list?.node?.original, parentRootId: list?.node?.parent });
        if (list.event?.type === 'dblclick' && list?.node?.original?.id && list?.node?.original?.child) {
            getInitialFolderList(list?.node?.original?.id);
        }
    }

    document.addEventListener('keydown', (e) => {
        if (
            (window.location.pathname.includes(RedirectTo('/site-edit')) ||
                window.location.pathname.includes(RedirectTo('/report-edit'))) &&
            (e.key == ' ' || e.code == 'Space' || e.keyCode == 32)
        ) {
            if (
                contentData?.icon === 'glyphicon glyphicon-image' ||
                contentData?.icon === 'glyphicon glyphicon-doc-file' ||
                contentData?.icon === 'glyphicon glyphicon-video' ||
                contentData?.icon === 'glyphicon glyphicon-pdf-file' ||
                contentData?.icon === 'glyphicon glyphicon-text-background-cp' ||
                contentData?.icon === 'fa-fa-folder-palette-closed' ||
                contentData?.icon === 'glyphicon glyphicon-text-background-cp-draft'
            ) {
                if (modal?.isOpen && previewModalOpen) {
                    setPreviewModalOpen(false);
                    return false;
                } else {
                    setPreviewModalOpen(true);
                    return false;
                }
            }
        }
    });
    const handleSelect = () => {
        modalDispatch({ type: modalActions.CLOSE_MODAL });
        props?.data?.slotName
            ? props?.data?.handleData(currentSelectedData, props?.data?.slotName, props?.data?.pkRecordsId)
            : props?.data?.handleData(currentSelectedData);
        if (
            window.location.pathname.includes(RedirectTo('/site-edit')) ||
            window.location.pathname.includes(RedirectTo('/report-edit'))
        ) {
            setEditorChanges(true);
            setTimeout(() => {
                Toast.success({ description: 'Panel added below.' });
            }, 200);
        }
    };
    return (
        <Row>
            {treeListData && (
                <div className="my-2">
                    {loading && (
                        <div className="jstree-default">
                            <i className="loading jstree-icon jstree-ocl" />
                            <span className="loading-text">Loading...</span>
                        </div>
                    )}
                    <TreeView data={data} handleChange={handleGetList} />
                </div>
            )}
            <p className="mb-0 mt-1">
                <span className="ms-2 w-auto">Select content panel and press spacebar for preview</span>
            </p>
            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Add"
                    variant="outline-primary"
                    type="button"
                    disabled={isSelectable}
                    onClick={() => handleSelect()}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </Row>
    );
};

export default AddContentPanelModal;
