// @flow
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useEffect } from 'react';

// component
import ButtonElement from '../../../../components/button/ButtonElement';
import { useModalContext } from '../../../../contexts/ModalContext';
import { modalActions } from '../../../../reducers/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { addSlickSlider } from '../../../../helpers/editor/slick/SlickSlider';
import { rgcpPanelPreviewList, rgcpPerformancePreviewList, rgcpHtmlReset } from '@ovrture/react-redux';
import {
    arrowInitHandler,
    carouselMigration,
    indicatorsSingleInitHandler,
    RdtLargestPanelHeight,
} from '../../../../helpers/editor/migration/TenantMigration';

const FundPreviewModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const [previewData, setpreviewData] = useState();
    const toggle = () => {
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
        });
        dispatch(rgcpHtmlReset());
    };

    const { previewList, details, performancePreview } = useSelector((state) => ({
        details: state?.auth?.me,
        previewList: state?.rgcpHtml?.panelPreview,
        performancePreview: state?.rgcpHtml?.performancePreview,
    }));
    useEffect(() => {
        if (props?.data?.id)
            dispatch(
                rgcpPanelPreviewList({
                    rdtProcess: 'RdtPanelPreview',
                    fundType: props?.data?.fromPage,
                    reportYear: props?.data?.reportYear,
                    pkFundId: props?.data?.id,
                })
            );
    }, []);
    useEffect(() => {
        if (previewList?.code === 200) {
            setpreviewData(previewList?.data);
            let doc = new DOMParser().parseFromString(previewList?.data, 'text/html');
            document.getElementById('previewOnly').innerHTML = doc.querySelector('.main-body-container').innerHTML;
            const photoGalleryCarousel = doc?.querySelectorAll('#photoGalleryCarousel .frame_adjuster');
            photoGalleryCarousel.length > 0 && setTimeout(() => addSlickSlider(photoGalleryCarousel), 1000);
        }
        if (previewList?.data === '') {
            setpreviewData('no-data');
        }
        setTimeout(() => {
            carouselMigration();
            arrowInitHandler();
            indicatorsSingleInitHandler(0);
            RdtLargestPanelHeight();
        }, 1000);
    }, [previewList, performancePreview]);
    return (
        <>
            <Card>
                <Helmet>
                    <link
                        rel="stylesheet"
                        href={`https://common-assets-relay.s3.us-west-2.amazonaws.com/demo/react-test/Org${details?.organaisationId}/assets/style/style.css`}></link>
                </Helmet>
                <Card.Body className="cp-preview-container previewOnly" id="previewOnly">
                    {previewList == null && previewData == 'no-data' && (
                        <div className="text-center no-preview text-muted">
                            <i className="mdi mdi-eye-off "></i>
                            <p className="font-16">Data not available for preview.</p>
                        </div>
                    )}
                </Card.Body>
            </Card>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement name="Back" variant="light" type="button" onClick={toggle} />
            </div>
        </>
    );
};

export default FundPreviewModal;
