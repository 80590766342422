//@flow
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
//components
import ButtonElement from '../../../components/button/ButtonElement';
import { FormInput } from '../../../components/form/input';
import service from '../../../constants/service.constant';
import { convertIntoBase64 } from '../../../utility';
import { accountReset, accountBulkReset } from '@ovrture/react-redux';

const VerifyIdentityModalForEnableAndDisable = (props) => {
    const [toggle, setToggle] = useState(false);
    const [toggle1, setToggle1] = useState(false);
    const [customErrorKey, setCustomErrorKey] = useState(null);
    const [customErrorPassword, setCustomErrorPassword] = useState(null);
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({});

    const { account, errorAccountBulk } = useSelector((state) => ({
        account: state?.account,
        errorAccountBulk: state?.accountBulk?.error,
    }));

    useEffect(() => {
        if (account?.disable?.code === service.notfound) setCustomErrorMsg(account?.disable?.data);
        if (account?.enable?.code === service.notfound) setCustomErrorMsg(account?.enable?.data);
        if (errorAccountBulk?.code === service.notfound) setCustomErrorMsg(errorAccountBulk?.data);
    }, [account?.disable, account?.enable, errorAccountBulk]);

    const setCustomErrorMsg = (lst) => {
        const err = {};
        if (lst === 'Verification Failed') {
            err['key'] = {
                key: {
                    message: 'Verification failed.',
                    ref: 'input#key.is-invalid.form-control',
                    type: 'required',
                },
            };
            err['password'] = {
                password: {
                    message: '',
                    ref: 'input#password.is-invalid.form-control',
                    type: 'required',
                },
            };
        } else if (lst[0].platformAdminSecretKey) {
            err['key'] = {
                key: {
                    message: 'Key does not match.',
                    ref: 'input#key.is-invalid.form-control',
                    type: 'required',
                },
            };
        } else if (lst[0].password) {
            err['password'] = {
                password: {
                    message: 'Password does not match.',
                    ref: 'input#password.is-invalid.form-control',
                    type: 'required',
                },
            };
        }
        setCustomErrorKey(err);
        setCustomErrorPassword(err);
    };

    const onSubmit = (data) => {
        props.data.original.password = convertIntoBase64(data.password || document.getElementById('password').value);
        props.data.original.platformAdminSecretKey = convertIntoBase64(
            data.key || document.getElementById('key').value
        );
        props.data.onPlatformKeyVerifed(true, props.data.operation, props.data.original);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <p className="pb-0">Enter your password:</p>
            <FormInput
                type="password"
                id="password"
                label="Password *"
                register={register}
                errors={errors?.password ? errors : customErrorPassword?.password ? customErrorPassword?.password : ''}
                className="mb-0"
                onChange={(e) => {
                    setToggle(e.target.value === '' ? false : true);
                    setCustomErrorPassword(null);
                }}
            />
            <p className="pb-0">Enter the Platform Administrator key:</p>
            <FormInput
                type="password"
                id="key"
                label="Key *"
                register={register}
                errors={errors?.key ? errors : customErrorKey?.key ? customErrorKey?.key : ''}
                onChange={(e) => {
                    setToggle1(e.target.value === '' ? false : true);
                    setCustomErrorKey(null);
                }}
            />
            <div className="modal-footer">
                {props.active !== 0 && (
                    <ButtonElement
                        name="Previous"
                        variant="outline-primary"
                        className="btn-prev"
                        type="button"
                        onClick={() => {
                            setCustomErrorPassword(null);
                            setCustomErrorKey(null);
                            dispatch(accountReset());
                            dispatch(accountBulkReset());
                            props.setActive(props.active - 1);
                        }}
                    />
                )}
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() => {
                        setCustomErrorPassword(null);
                        setCustomErrorKey(null);
                        dispatch(accountReset());
                        dispatch(accountBulkReset());
                        props.data.onPlatformKeyVerifed();
                    }}
                />
                <ButtonElement
                    name={props?.data?.status === 'Disable' ? 'Disable' : 'Enable'}
                    variant="primary"
                    className="btn-next"
                    type="submit"
                    disabled={toggle && toggle1 ? false : true}
                />
            </div>
        </form>
    );
};

export default VerifyIdentityModalForEnableAndDisable;
