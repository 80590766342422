//@flow
import React, { useState } from 'react';
import MultiStepForm from '../../../helpers/multiStep/Index';
//components
import BroadcastProceedlModal from './BroadcastProceedlModal';
import BroadcastSetTimerModal from './BroadcastSetTimerModal';
import SendNotificationModal from '../../commonpages/contentPanels/SendNotificationModal';

const BroadcastPublishMultiStepForm = (props) => {
    const [active, setActive] = useState(0);

    const steps = [
        {
            name: 'Proceed',
            component: (
                <BroadcastProceedlModal
                    active={active}
                    setActive={setActive}
                    data={props.data}
                    hideModal={props.hideModal}
                />
            ),
        },
        {
            name: 'Set Timer',
            component: (
                <BroadcastSetTimerModal
                    active={active}
                    setActive={setActive}
                    data={props.data}
                    hideModal={props.hideModal}
                />
            ),
        },
        {
            name: 'Send Notification',
            component: (
                <SendNotificationModal
                    active={active}
                    setActive={setActive}
                    data={props.data}
                    hideModal={props.hideModal}
                    name="broadcast"
                />
            ),
        },
    ];

    return (
        <>
            <MultiStepForm activeStep={active} showNavigation={false} steps={steps} />
        </>
    );
};

export default BroadcastPublishMultiStepForm;
