// @flow
import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
// components
import { NewImpactValidation } from '../../../../components/form/validation';
import { FormInput } from '../../../../components/form/input';
import ButtonElement from '../../../../components/button/ButtonElement';
import { modalActions } from '../../../../reducers/actions';
import { useModalContext } from '../../../../contexts/ModalContext';
import { impactTypeAdd, impactTypeGetList } from '@ovrture/react-redux';

const NewImpactModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const { add, paletteList, orgFetch } = useSelector((state) => ({
        add: state?.impactType?.add,
        paletteList: state?.palette?.list?.data,
        orgFetch: state?.organization?.fetch,
    }));
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({ resolver: NewImpactValidation, mode: 'onBlur' });

    const watchAll = watch();

    const [isValidated, setIsValidated] = useState(false);

    useEffect(() => {
        if (add) {
            dispatch(impactTypeGetList({ orgId: props?.data?.fkOrgId }));
            setTimeout(() => {
                modalDispatch({
                    type: modalActions.OPEN_MODAL,
                    payload: {
                        modalAction: (e) => props.hideModal(),
                        modalVariant: 'primary',
                        modalDataKey: ['platformAdmin', 'impactType'],
                        data: {
                            title: orgFetch?.data?.name,
                        },
                    },
                });
            }, 1000);
        }
        if (props?.data?.fkOrgId) setValue('fkOrgId', props.data.fkOrgId);
    }, [props?.data, add]);

    useEffect(() => {
        if (
            watchAll &&
            watchAll.colImpactKey !== '' &&
            watchAll.colImpactName !== '' &&
            watchAll.fkTblPaletteId !== 'Select Palette'
        ) {
            setIsValidated(true);
        } else {
            setIsValidated(false);
        }
    }, [watchAll]);

    const onSubmit = (data) => {
        if (props?.data?.fkOrgId) dispatch(impactTypeAdd(data));
    };

    const toggle = ({ row }) => {
        modalDispatch({
            type: modalActions.OPEN_MODAL,
            payload: {
                modalAction: (e) => props.hideModal(),
                modalVariant: 'primary',
                modalDataKey: ['platformAdmin', 'impactType'],
                data: {
                    title: orgFetch?.data?.name,
                    original: row?.original,
                },
            },
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Col md={6}>
                <FormInput type="hidden" id="fkOrgId" register={register} />
                <FormInput type="select" id="fkTblPaletteId" label="Palette *" register={register}>
                    <option value="">Select</option>
                    {paletteList?.map((item, index) => {
                        return (
                            <option key={index} value={item?.pkPaletteId}>
                                {item?.colPaletteName}
                            </option>
                        );
                    })}
                </FormInput>
            </Col>
            <FormInput
                type="text"
                id="colImpactName"
                label="Impact Type *"
                maxLength="75"
                errors={errors}
                register={register}
            />
            <FormInput
                type="text"
                id="colImpactKey"
                label="Uploader Impact Code *"
                errors={errors}
                register={register}
                maxLength="10"
            />
            <div className="button-list d-flex justify-content-center">
                <ButtonElement name="Save" variant="primary" type="submit" disabled={!isValidated} />
                <ButtonElement name="Cancel" variant="light" onClick={toggle} />
            </div>
        </form>
    );
};

export default NewImpactModal;
