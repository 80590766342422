// @flow
import { useNotification } from '@ovrture/react-redux/dist/helpers/toasterNotification/NotificationContext';
import React, { useEffect, useState } from 'react';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { RedirectTo } from '../../../routes/role/path';
import { arrowInitHandler, indicatorsInitHandler } from '../migration/TenantMigration';
import { setEditorChanges } from '../storage';
import { tinymceInlineInit, tinymceRemove } from '../tinymce/TinyMceInlineTextEditor';
import OperationalBar from './ui';

export const icons = {
    ADD: { icon: 'mdi-plus-thick', title: 'Add slides to the right', className: 'slideAdd' },
    LEFT: { icon: 'mdi-arrow-left-thick', title: 'Move slide left', className: 'slideLeft' },
    RIGHT: { icon: 'mdi-arrow-right-thick', title: 'Move slide right', className: 'slideRight' },
    REPLACE: { icon: 'mdi-collage', title: 'Change slide layout', className: 'slideReplace' },
    DELETE: { icon: 'mdi-delete', title: 'Delete slide', className: 'slideDelete' },
};

const indexs = {
    ADD: 0,
    LEFT: 1,
    RIGHT: 2,
    REPLACE: 3,
    DELETE: 4,
};

const Slide = (props) => {
    const { modalDispatch } = useModalContext();
    const [disableClass, setDisableClass] = useState(null);
    const [cpId, setCpId] = useState(null);
    const { Toast } = useNotification();

    useEffect(() => {
        if (window.location.pathname.includes(RedirectTo('/content-panel-edit'))) {
            if (document.querySelectorAll('.item').length >= 6) {
                setDisableClass([
                    { index: indexs.ADD, disable: 'disabled' },
                    { index: indexs.LEFT, disable: 'disabled' },
                ]);
            } else {
                if (document.querySelectorAll('.item').length === 1) {
                    setDisableClass([
                        { index: indexs.LEFT, disable: 'disabled' },
                        { index: indexs.RIGHT, disable: 'disabled' },
                        { index: indexs.DELETE, disable: 'disabled' },
                    ]);
                } else {
                    setDisableClass([{ index: indexs.LEFT, disable: 'disabled' }]);
                }
            }
        }
        try {
            document.getElementById('rootHtml').addEventListener('mousemove', (e) => {
                // return for text node
                if (e.target.nodeType === 3) return;
                let slideDiv = e?.target?.closest('[cpid]')?.querySelector('.slideDiv');
                let slideEditDiv = e?.target?.closest('[cpid]')?.querySelector('.carousel-cp');

                if (
                    window.location.pathname.includes(RedirectTo('/site-edit')) ||
                    window.location.pathname.includes(RedirectTo('/report-edit'))
                ) {
                    const slideOperationalBar = document?.querySelector('.slide-operational-bar');
                    if (slideOperationalBar && document?.querySelector('.slide-operational-bar')?.style) {
                        if (slideEditDiv && !e?.target?.closest('[cpid]')?.classList?.contains('report-cp-panel')) {
                            document.querySelector('.slide-operational-bar').style.cssText =
                                'display: flex;visibility: visible;top: ' +
                                slideEditDiv.parentNode.offsetTop +
                                'px;right: 0;position: absolute;z-index: 1';
                        } else {
                            document.querySelector('.slide-operational-bar').style.cssText += 'display: none;';
                        }
                    }
                    let cpid = e?.target?.closest('[cpid]');
                    setCpId(cpid?.getAttribute('cpid'));
                    let carouselDiv = cpid?.querySelector('[cpid] .carousel');
                    let carouselDivInner = carouselDiv?.querySelector('.carousel-inner');
                    let items = carouselDivInner?.querySelectorAll('.item');
                    if (items?.length > 1) {
                        const first = carouselDiv?.querySelector('.item.active:nth-child(1)');
                        const last = carouselDiv?.querySelector('.item.active:nth-child(' + items.length + ')');
                        if (first) {
                            let buttons = document.querySelectorAll('.slide-operational-bar.operational-bar button');
                            for (let index = 0; index < buttons.length; index++) {
                                const element = buttons[index];
                                element.classList.remove('disabled');
                                element.classList.remove('maximum-disabled');
                                element.title === 'Last slide in panel' && (element.title = 'Move slide right');
                                element.title === 'Can not exceeds six slides' &&
                                    (element.title = 'Add slides to the right');
                                element.title === 'Can not delete; must have at least one slide' &&
                                    (element.title = 'Delete slide');
                                if (element?.title === icons.LEFT.title || element?.title === 'First slide in panel') {
                                    element.classList.add('disabled');
                                    element.classList.add('maximum-disabled');
                                    element.title = 'First slide in panel';
                                }
                                if (
                                    items?.length > 5 &&
                                    (element?.title === icons.ADD.title ||
                                        element?.title === 'Can not exceeds six slides')
                                ) {
                                    element.classList.add('disabled');
                                    element.classList.add('maximum-disabled');
                                    element.title = 'Can not exceeds six slides';
                                }
                            }
                        } else if (last) {
                            let buttons = document.querySelectorAll('.slide-operational-bar.operational-bar button');
                            for (let index = 0; index < buttons.length; index++) {
                                const element = buttons[index];
                                element.classList.remove('disabled');
                                element.classList.remove('maximum-disabled');
                                element.title === 'First slide in panel' && (element.title = 'Move slide left');
                                element.title === 'Can not exceeds six slides' &&
                                    (element.title = 'Add slides to the right');
                                element.title === 'Can not delete; must have at least one slide' &&
                                    (element.title = 'Delete slide');
                                if (element?.title === icons.RIGHT.title || element?.title === 'Last slide in panel') {
                                    element.classList.add('disabled');
                                    element.classList.add('maximum-disabled');
                                    element.title = 'Last slide in panel';
                                }
                                if (
                                    items?.length > 5 &&
                                    (element?.title === icons.ADD.title ||
                                        element?.title === 'Can not exceeds six slides')
                                ) {
                                    element.classList.add('disabled');
                                    element.classList.add('maximum-disabled');
                                    element.title = 'Can not exceeds six slides';
                                }
                            }
                        } else {
                            let buttons = document.querySelectorAll('.slide-operational-bar.operational-bar button');
                            for (let index = 0; index < buttons.length; index++) {
                                const element = buttons[index];
                                element.classList.remove('disabled');
                                element.title === 'First slide in panel' && (element.title = 'Move slide left');
                                element.title === 'Last slide in panel' && (element.title = 'Move slide right');
                                element.title === 'Can not exceeds six slides' &&
                                    (element.title = 'Add slides to the right');
                                element.title === 'Can not delete; must have at least one slide' &&
                                    (element.title = 'Delete slide');
                                if (
                                    items?.length > 5 &&
                                    (element?.title === icons.ADD.title ||
                                        element?.title === 'Can not exceeds six slides')
                                ) {
                                    element.classList.add('disabled');
                                    element.classList.add('maximum-disabled');
                                    element.title = 'Can not exceeds six slides';
                                }
                            }
                        }
                    } else if (items?.length === 1) {
                        let buttons = document.querySelectorAll('.slide-operational-bar.operational-bar button');
                        for (let index = 0; index < buttons.length; index++) {
                            const element = buttons[index];
                            element.classList.remove('disabled');
                            element.classList.remove('maximum-disabled');
                            element.title === 'Can not exceeds six slides' &&
                                (element.title = 'Add slides to the right');
                            if (element?.title === icons.RIGHT.title || element?.title === 'Last slide in panel') {
                                element.classList.add('disabled');
                                element.classList.add('maximum-disabled');
                                element.title = 'Last slide in panel';
                            }
                            if (element?.title === icons.LEFT.title || element?.title === 'First slide in panel') {
                                element.classList.add('disabled');
                                element.classList.add('maximum-disabled');
                                element.title = 'First slide in panel';
                            }
                            if (
                                element?.title === icons.DELETE.title ||
                                element.title === 'Can not delete; must have at least one slide'
                            ) {
                                element.classList.add('disabled');
                                element.classList.add('maximum-disabled');
                                element.title = 'Can not delete; must have at least one slide';
                            }
                        }
                    }
                } else {
                    if (slideDiv && document?.querySelector('.slide-operational-bar')?.style) {
                        document.querySelector('.slide-operational-bar').style.cssText =
                            'display: flex;visibility: visible;top: ' +
                            slideDiv.parentNode.offsetTop +
                            ';right: 0;position: absolute;z-index: 1';
                    }
                }
            });
            document.getElementById('rootHtml').addEventListener('mouseleave', (e) => {
                if (
                    !(
                        e?.toElement?.className?.includes('slide-operational-bar') ||
                        e?.toElement?.className?.includes(
                            'bg-secondary text-white d-flex align-items-center ps-2 pe-1'
                        ) ||
                        e?.toElement?.className?.includes('asset-replacer') ||
                        e?.toElement?.className?.includes('action-icon') ||
                        e?.toElement?.className?.includes('panel-operational-bar') ||
                        e?.toElement?.className?.includes('mdi') ||
                        e?.toElement?.className?.includes('documentImg') ||
                        e?.toElement?.className === ''
                    )
                ) {
                    if (document?.querySelector('.slide-operational-bar')?.style)
                        document.querySelector('.slide-operational-bar').style.cssText += 'display: none;';
                }
                document?.querySelectorAll('div.slide-operational-bar').forEach((element) => {
                    element.addEventListener('mouseleave', (ev) => {
                        if (
                            !(
                                e?.toElement?.className?.includes('slide-operational-bar') ||
                                e?.toElement?.className?.includes(
                                    'bg-secondary text-white d-flex align-items-center ps-2 pe-1'
                                ) ||
                                e?.toElement?.className?.includes('asset-replacer') ||
                                e?.toElement?.className?.includes('action-icon') ||
                                e?.toElement?.className?.includes('panel-operational-bar') ||
                                e?.toElement?.className?.includes('mdi') ||
                                e?.toElement?.className?.includes('documentImg') ||
                                e?.toElement?.className === ''
                            )
                        ) {
                            document.querySelector('.slide-operational-bar').style.cssText += 'display: none;';
                        }
                    });
                });
            });
        } catch (ex) {}
    }, []);

    const Actions = (e, key) => {
        switch (key) {
            case icons.ADD.icon:
                openSlideLayout(props?.original);
                break;
            case icons.LEFT.icon:
                slideShift('carousel-control-prev');
                break;
            case icons.RIGHT.icon:
                slideShift('carousel-control-next');
                break;
            case icons.REPLACE.icon:
                openSlideLayout(props?.original, true);
                break;
            case icons.DELETE.icon:
                slideDelete();
                break;
        }
    };

    const openSlideLayout = (original, isReplace) => {
        modalDispatch({
            type: modalActions.OPEN_MODAL,
            payload: {
                modalVariant: 'primary',
                modalDataKey: ['editor', 'addOrChangeSlide'],
                data: {
                    original: original,
                    isReplace: isReplace,
                    slideModified: slideModified,
                    cpId:
                        window.location.pathname.includes(RedirectTo('/site-edit')) ||
                        window.location.pathname.includes(RedirectTo('/report-edit'))
                            ? cpId
                            : document?.querySelector('[cpid]')?.getAttribute('cpid'),
                },
            },
        });
    };

    const slideModified = (slide) => {
        if (slide === undefined) {
            setTimeout(() => {
                tinymceRemove();
                tinymceInlineInit();
            }, 200);
        }
    };

    const slideShift = (direction) => {
        const ele = document.getElementById('rootComponent');
        const replacerId = ele.getAttribute('slideSecClass-id');
        if (replacerId) {
            const ele1 = document.getElementById(replacerId);
            var activeSlide = ele1.querySelector('.standard-template .carousel-inner .active');
        } else {
            var activeSlide = document.querySelector('.standard-template .carousel-inner .active');
        }
        var currentObj = null;
        direction === 'carousel-control-next'
            ? (currentObj = activeSlide.nextElementSibling)
            : (currentObj = activeSlide.previousElementSibling);
        var cnvrtDomNext = new DOMParser()
            .parseFromString(currentObj.innerHTML, 'text/html')
            .querySelector('.standard-panel');
        var cnvrtDomActive = new DOMParser()
            .parseFromString(activeSlide.innerHTML, 'text/html')
            .querySelector('.standard-panel');
        activeSlide.children[0].remove();
        activeSlide.append(cnvrtDomNext);
        currentObj.children[0].remove();
        currentObj.append(cnvrtDomActive);
        let msg = '';
        if (direction === 'carousel-control-next') {
            msg = 'Slide moved right.';
        } else if (direction === 'carousel-control-prev') {
            msg = 'Slide moved left.';
        }
        setTimeout(() => {
            msg && Toast.success({ description: msg });
        }, 200);
        tinymceInlineInit();
        if (
            window.location.pathname.includes(RedirectTo('/site-edit')) ||
            window.location.pathname.includes(RedirectTo('/report-edit'))
        ) {
            setEditorChanges(true);
        }
    };

    const slideDelete = () => {
        modalDispatch({
            type: modalActions.OPEN_MODAL,
            payload: {
                modalAction: (e) => deleteSlide(),
                modalVariant: 'danger',
                modalDataKey: ['editor', 'delete'],
                data: {
                    name: 'slide',
                },
            },
        });
    };
    const deleteSlide = () => {
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
        });
        let items;
        if (
            window.location.pathname.includes(RedirectTo('/site-edit')) ||
            window.location.pathname.includes(RedirectTo('/report-edit'))
        ) {
            let slideId = document.querySelector('#rootComponent').getAttribute('slidesecclass-id');
            document?.querySelector('#' + slideId + ' .standard-template .item.active')?.remove();
            items = document?.querySelectorAll('#' + slideId + ' .standard-template .carousel-inner .item');
        } else {
            document?.querySelector('.standard-template .item.active')?.remove();
            items = document?.querySelectorAll('.standard-template .carousel-inner .item');
        }
        if (items.length > 0) items[0].classList.add('active');
        slideModified();
        indicatorsInitHandler(0);
        arrowInitHandler();
        setTimeout(() => {
            Toast.success({ description: 'Slide deleted.' });
        }, 200);
        if (
            window.location.pathname.includes(RedirectTo('/site-edit')) ||
            window.location.pathname.includes(RedirectTo('/report-edit'))
        ) {
            setEditorChanges(true);
        }
    };

    return (
        <OperationalBar
            name="Slide:"
            className="slide-operational-bar"
            icons={[icons.ADD, icons.LEFT, icons.RIGHT, icons.REPLACE, icons.DELETE]}
            disableClass={disableClass}
            actions={Actions}
        />
    );
};

export default Slide;
