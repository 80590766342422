import 'easymde/dist/easymde.min.css';
import React, { useState, useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TinyMceTextEditor = ({ label, id, value, setValue, maxLength, plugins, height, disableEnter }) => {
    const [count, setCount] = useState(0);
    const editorRef = useRef(null);
    const charCount = (editor) => editor.plugins.wordcount.body.getCharacterCount();
    const pluginsList = plugins ? plugins : [];

    const handleUpdate = (val, editor) => {
        const cCount = charCount(editor);
        if (maxLength) {
            if (cCount <= Number(maxLength)) {
                setValue(val);
                setCount(cCount);
            } else {
                setValue(value);
            }
        } else {
            setCount(cCount);
            setValue(val);
        }
    };

    useEffect(() => {
        handleAppendElement();
    }, [value]);

    useEffect(() => {
        if (editorRef?.current) {
            const cCount = charCount(editorRef?.current);
            setCount(cCount);
        }
    }, [editorRef?.current]);

    useEffect(()=>{
        setTimeout(()=>{
            addAreaLable()
        },1000)
    },[])

    const addAreaLable = () => {
        const ele = document?.querySelectorAll('.tox-toolbar__group');
        const resizeEle = document?.querySelectorAll('.tox-statusbar__resize-handle')
        if(ele?.length){
            for(let i=0; i<ele.length; i++){
                let buttons = ele[i]?.getElementsByTagName('button');
                if(buttons?.length){
                    for(let j=0; j<buttons.length; j++){
                        const ariaLabel = buttons[j]?.getAttribute('aria-label');
                        const svg = buttons[j]?.getElementsByTagName('svg')[0]
                        svg?.setAttribute('aria-label', ariaLabel)
                        svg?.setAttribute('role', 'img')
                    }
                }
            }
        }
        if(resizeEle?.length){
            for(let i=0; i<resizeEle.length; i++){
                const ariaLabel = resizeEle[i]?.getAttribute('title');
                const svg = resizeEle[i]?.getElementsByTagName('svg')[0]
                svg?.setAttribute('aria-label', ariaLabel)
                svg?.setAttribute('role', 'img')
            }
        }
    }

    const handleAppendElement = () => {
        setTimeout(() => {
            if (maxLength) {
                let list = document?.getElementsByClassName('tox-statusbar__text-container');
                for (let i = 0; i < list.length; i++) {
                    list[i]?.appendChild(document?.getElementsByClassName('text-limit')[i]);
                }
            }
        }, 500);
    };

    return (
        <>
            {label && <label>{label}</label>}
            <Editor
                id={id}
                apiKey={process.env.REACT_APP_TINY_TOKEN}
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={value}
                onEditorChange={handleUpdate}
                init={{
                    height: height ? height : 300,
                    icons: 'material',
                    menubar: false,
                    plugins: [...pluginsList, 'lists', 'wordcount', 'autolink', 'link'],
                    toolbar: 'bold italic underline bullist numlist link unlink',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    setup: disableEnter
                        ? function (ed) {
                              ed.on('keydown', function (e) {
                                  if (e.keyCode == 13) {
                                      e.preventDefault();
                                  }
                              });
                          }
                        : function (ed) {
                              ed.on('keydown', function (e) {
                                  const cCount = charCount(editorRef?.current);
                                  setCount(cCount);
                              });
                          },
                }}
            />
            <p className="text-limit ms-2">{`${maxLength - count >= 0 ? maxLength - count : 0} characters`}</p>
        </>
    );
};

export default TinyMceTextEditor;
