// @flow
import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
    fundPrintBridgeExportReport,
    userList,
    reportDataExportReset,
    reportFundtDataYearList,
} from '@ovrture/react-redux';

const ExportFyReportsPrintBridgeModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [isForPrintFundsAsSinglePDF, setIsForPrintFundsAsSinglePDF] = useState(1);
    const [reportPreferenceId, setReportPreferenceId] = useState(1);
    const [fkUserIdList, setFkUserIdList] = useState('');
    const [isForNarrativePDFDocument, setisForNarrativePDFDocument] = useState(false);
    const [isForMostRecentUpdates, setisForMostRecentUpdates] = useState(2);
    const [isForImpactPDFDocument, setisForImpactPDFDocument] = useState(false);
    const [toggle1, setToggle1] = useState(true);
    const [organized, setOrganized] = useState('One continuous listing (i.e. one folder)');

    const dispatchAction = useDispatch();
    const [reportYear, setReportYear] = useState(null);

    const { recordUserActivity, users, yearList, recordUserActivityError } = useSelector((state) => ({
        recordUserActivity: state?.reportDataExport?.fundPrintBridgeExport,
        recordUserActivityError: state?.reportDataExport?.error,
        users: state?.user?.userList,
        yearList: state?.reportDataFund?.yearList,
    }));

    const organizedList = ['One continuous listing (i.e. one folder)', 'By record reference number'];

    useEffect(() => {
        dispatchAction(
            userList({
                isAnd: true,
                isUnpaged: true,
            })
        );
        dispatchAction(reportFundtDataYearList());
    }, []);

    useEffect(() => {
        if (recordUserActivity || recordUserActivityError) {
            modalDispatch({ type: modalActions.CLOSE_MODAL });
            dispatchAction(reportDataExportReset());
        }
    }, [recordUserActivity, recordUserActivityError]);

    const downloadFyReportPrintBridge = () => {
        const payload = {
            isForOrganizeByReferenceNumber: organized !== 'One continuous listing (i.e. one folder)' ? true : false,
            reportYear: reportYear || yearList[0],
            isForPrintFundsAsSinglePDF: isForPrintFundsAsSinglePDF == 1 ? true : false,
            reportPreferenceId: reportPreferenceId,
            forNarrativePDFDocument: isForNarrativePDFDocument,
            forImpactPDFDocument: isForImpactPDFDocument,
            forMostRecentUpdates: isForMostRecentUpdates == 1 ? true : false,
        };
        fkUserIdList && (payload.fkUserIdList = fkUserIdList);

        dispatchAction(fundPrintBridgeExportReport(payload));
    };

    return (
        <>
            <div className="custom-padding export-report-modal">
                <p>Generate a zipped gallery of all PDF/printable fund reports for a specific fiscal year.</p>
                <Row className="mt-2">
                    <Col md={6}>
                        <FormInput
                            type="select"
                            className="custom-select"
                            onChange={(e) => setFkUserIdList(e.target.value)}
                            label="User(s)">
                            <option>All Users</option>
                            {users?.content.map((usr) => {
                                return (
                                    <option key={usr.id} value={usr.id}>
                                        {usr.colFullName}
                                    </option>
                                );
                            })}
                        </FormInput>
                    </Col>
                    <Col md={6}>
                        <FormInput
                            type="select"
                            className="custom-select"
                            onChange={(e) => setReportYear(e.target.value)}
                            label="Fiscal Year">
                            {yearList &&
                                yearList.map((y) => {
                                    return (
                                        <option key={y} value={y}>
                                            {y}
                                        </option>
                                    );
                                })}
                        </FormInput>
                    </Col>
                </Row>
                <Card className="mb-2 mt-3">
                    <Card.Body>
                        <Row className="mt-0">
                            <Col md={6}>
                                <b className="mt-0">PDF Options:</b>

                                <FormInput
                                    type="radio"
                                    id="printAllSinglePdf"
                                    name="printType"
                                    className="mt-1"
                                    value={1}
                                    label="Print all funds as a single PDF."
                                    onChange={(e) => setIsForPrintFundsAsSinglePDF(e.target.value)}
                                    checked={isForPrintFundsAsSinglePDF == 1 ? true : false}
                                />

                                <FormInput
                                    type="radio"
                                    id="printAllSeparatePdf"
                                    name="printType"
                                    value={2}
                                    label="Print all funds as separate PDFs."
                                    className="mt-1"
                                    onChange={(e) => setIsForPrintFundsAsSinglePDF(e.target.value)}
                                    checked={isForPrintFundsAsSinglePDF == 2 ? true : false}
                                />

                                <b className="mt-2 d-block">Report Preference:</b>
                                <FormInput
                                    type="radio"
                                    id="digital"
                                    name="reportPreferences"
                                    label="Digital"
                                    value={1}
                                    className="pe-5 mt-1"
                                    onChange={(e) => setReportPreferenceId(e.target.value)}
                                    checked={reportPreferenceId == 1 ? true : false}
                                />

                                <FormInput
                                    type="radio"
                                    id="print"
                                    name="reportPreferences"
                                    label="Print"
                                    value={2}
                                    className="pe-5 mt-1"
                                    onChange={(e) => setReportPreferenceId(e.target.value)}
                                    checked={reportPreferenceId == 2 ? true : false}
                                />
                                <FormInput
                                    type="radio"
                                    id="digitalAndPrint"
                                    name="reportPreferences"
                                    label="Both"
                                    value={3}
                                    className="pe-5 mt-1"
                                    onChange={(e) => setReportPreferenceId(e.target.value)}
                                    checked={reportPreferenceId == 3 ? true : false}
                                />
                            </Col>
                            <Col md={6} className="mt-0">
                                <div className="mt-0 alert alert-info">
                                    <FormInput
                                        type="checkbox"
                                        id="printSupplementDocument"
                                        className="mt-0"
                                        label="Include supplemental documents."
                                        onChange={(e) => setisForImpactPDFDocument(e.target.checked)}
                                    />
                                    <FormInput
                                        type="checkbox"
                                        id="printNarrative"
                                        className="mt-1"
                                        label="Include narrative report content."
                                        onChange={(e) =>
                                            // setToggle(e.target.checked)
                                            setisForNarrativePDFDocument(e.target.checked)
                                        }
                                    />
                                    <FormInput
                                        type="radio"
                                        id="mostRecentUpdate"
                                        name="reportUpdates"
                                        value={1}
                                        label="Most Recent Update"
                                        className="mt-1 ms-2"
                                        // onChange={(e) => setisForNarrativePDFDocument(e.target.value)}
                                        // checked={isForNarrativePDFDocument == 1 ? true : false}
                                        // disabled={toggle ? false : true}
                                        onChange={(e) => setisForMostRecentUpdates(e.target.value)}
                                        checked={isForMostRecentUpdates == 1 ? true : false}
                                        disabled={!isForNarrativePDFDocument}
                                    />
                                    <FormInput
                                        type="radio"
                                        id="allUpdates"
                                        value={2}
                                        name="reportUpdates"
                                        label="All Updates"
                                        className="ms-2 mt-1"
                                        // onChange={(e) => setisForNarrativePDFDocument(e.target.value)}
                                        // checked={isForNarrativePDFDocument == 2 ? true : false}
                                        // disabled={toggle ? false : true}
                                        onChange={(e) => setisForMostRecentUpdates(e.target.value)}
                                        checked={isForMostRecentUpdates == 2 ? true : false}
                                        disabled={!isForNarrativePDFDocument}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <FormInput
                                    type="select"
                                    className="mt-3"
                                    onChange={(e) => setOrganized(e.target.value)}
                                    label="How would you like the gallery of PDF's organized?">
                                    {organizedList &&
                                        organizedList.map((y) => {
                                            return (
                                                <option key={y} value={y}>
                                                    {y}
                                                </option>
                                            );
                                        })}
                                </FormInput>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
            <div className="d-flex justify-content-center modal-footer">
                <ButtonElement
                    name="Generate"
                    variant="primary"
                    type="button"
                    onClick={() => {
                        if (toggle1) {
                            setToggle1(false);
                            downloadFyReportPrintBridge();
                        }
                    }}
                />

                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default ExportFyReportsPrintBridgeModal;
