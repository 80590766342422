import { useEffect } from 'react';

const HandleCache = (props) => {
    useEffect(() => {
        fetchMetaData();
    }, []);

    const fetchMetaData = () => {
        return new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_ROUTE_PREFIX + '/meta.json')
                .then((response) => response.json())
                .then(async (meta) => {
                    const metaVersion = process.env.REACT_APP_META_VERSION + '.' + window.location.hostname;
                    const latestVersion = meta.version;
                    const localVersion = localStorage.getItem(metaVersion);
                    const shouldForceRefresh = checkVersionMismatch(latestVersion, localVersion);
                    if (shouldForceRefresh) {
                        // Empty cache and hard reload the site
                        let url = window.location.href;
                        await fetch(url, {
                            headers: {
                                Pragma: 'no-cache',
                                Expires: '-1',
                                'Cache-Control': 'no-cache',
                            },
                        });
                        window.history.forward(1);
                        localStorage.clear();
                        sessionStorage.clear();
                        window.location.href = url;
                        window.location.reload(true);
                        console.log('Empty cache and hard reload the site', latestVersion, localVersion);
                    }
                    localStorage.setItem(metaVersion, latestVersion);
                })
                .catch(function (error) {});
        });
    };

    const checkVersionMismatch = (lv, cv) => {
        if (cv == null) return true;
        const letestVersion = lv.split(/\./g);
        const currentVersion = cv.split(/\./g);
        while (letestVersion.length || currentVersion.length) {
            const a = Number(letestVersion.shift());
            const b = Number(currentVersion.shift());
            // eslint-disable-next-line no-continue
            if (a === b) continue;
            // eslint-disable-next-line no-restricted-globals
            return a > b || isNaN(b);
        }
        return false;
    };

    return props.children;
};

export default HandleCache;
