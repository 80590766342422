import moment from 'moment';
import { ROLE_ENUM } from './constants/enumConstant';

export const dateTimeFormat = (date, format) => {
    return moment(date).format(format ? format : 'MMM D YYYY, h:mm a');
};

export const getRoleType = (id, name) => {
    var role = Object.keys(ROLE_ENUM).find((key) => ROLE_ENUM[key] === id);
    if (name) {
        role = role?.replaceAll('_', ' ') || 'USER';
    }
    return role;
};

export const convertIntoBase64 = (data) => {
    return new Buffer(data).toString('base64');
};

export const countDown = (countDownTime) => {
    const difference = +new Date(countDownTime) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            days:
                Math.floor(difference / (1000 * 60 * 60 * 24)).toString().length > 1
                    ? Math.floor(difference / (1000 * 60 * 60 * 24))
                    : '0' + Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours:
                Math.floor((difference / (1000 * 60 * 60)) % 24)?.toString()?.length > 1
                    ? Math.floor((difference / (1000 * 60 * 60)) % 24)
                    : '0' + Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes:
                Math.floor((difference / 1000 / 60) % 60)?.toString()?.length > 1
                    ? Math.floor((difference / 1000 / 60) % 60)
                    : '0' + Math.floor((difference / 1000 / 60) % 60),
            seconds:
                Math.floor((difference / 1000) % 60)?.toString()?.length > 1
                    ? Math.floor((difference / 1000) % 60)
                    : '0' + Math.floor((difference / 1000) % 60),
        };
    }
    return timeLeft;
};

export const ObjToArrayConvert = (data, length) => {
    let dataList = [];
    let dataKeys = Object.keys(data);
    const fieldName = (string) => {
        return string
            .split(/(?=[A-Z])/)
            .join('_')
            .toLowerCase();
    };
    for (let i in dataKeys) {
        const filterArray = dataKeys.filter((a) => {
            return a.includes(dataKeys[i]);
        });
        if (filterArray.length === length) {
            let createObj = {
                fieldName: fieldName(filterArray[0]),
                key: filterArray[0],
                replacer: data.fieldReplacerValueMap[filterArray[0]],
            };
            for (let j in filterArray) {
                createObj[filterArray[j]] = data[filterArray[j]];
            }
            dataList.push(createObj);
        }
    }
    return dataList;
};

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export const convertMsToTime = (milliseconds) => {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    hours = hours % 24;

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
};

export const handleCopyTextFromArea = (id) => {
    var copyText = document.getElementById(id);

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);
    // return copyText;
};

export const checkPromptValues = (initialVal, updatedVal) => {
    let isObjMisMatch = false;
    if (initialVal && updatedVal) {
        Object.keys(initialVal).forEach((item) => {
            if (initialVal[item] !== updatedVal[item]) {
                if (
                    (initialVal[item] !== null && updatedVal[item] !== null) ||
                    (initialVal[item] !== '' && updatedVal[item] !== '')
                )
                    isObjMisMatch = true;
            }
        });
    }
    return isObjMisMatch;
};

export const getFileExtension = (fileExtensionName) => {
    
    var basename = fileExtensionName?.text?.split(/[\\/]/)?.pop(),
        pos = basename?.lastIndexOf('.');
    if (basename === '' || pos < 1) return '';
    return (basename?.slice(pos + 1));
};

export const covertFileUrlToByte = async (blobUrl, setVal, name = '') => {
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';

    xhr.onload = await function () {
        var recoveredBlob = xhr.response;
        var reader = new FileReader();

        reader.onload = function () {
            const val = {
                base64Image: reader.result,
                filename: name,
            };
            setVal(val);
            return reader.result;
        };
        reader.readAsDataURL(recoveredBlob);
    };

    xhr.open('GET', blobUrl);
    xhr.send();
};

export const arrayRange = (start, end) => {
    return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
};
