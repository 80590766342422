// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import { SitePrintBridgeValidation } from '../../../../components/form/validation';
import { FormInput } from '../../../../components/form/input';
import ButtonElement from '../../../../components/button/ButtonElement';
import { standardPrintBridgeFetch, standardPrintBridgeUpdate, standardPrintBridgeReset } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import service from '../../../../constants/service.constant';

const SitePrintBridgeModal = (props) => {
    const dispatch = useDispatch();
    const { paletteList, update, fetch } = useSelector((state) => ({
        paletteList: state?.palette?.list?.data,
        update: state?.standardPrintBridge?.update,
        fetch: state?.standardPrintBridge?.fetch,
    }));

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({ resolver: SitePrintBridgeValidation, mode: 'onBlur' });
    const watchPalette = watch();
    const watchAll = watch();
    const [isValidated, setIsValidated] = useState(false);

    const onSubmit = (data) => {
        if (data?.id) {
            sessionStorage.setItem(
                process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY,
                'Content Panel printbridge updated.'
            );
        }
        dispatch(
            standardPrintBridgeUpdate({
                fkTemplateId: props?.data?.original?.id,
                fkPaletteId: data?.paletteId,
                orgId: props?.data?.original?.fkOrgId,
                template: data?.template,
            })
        );
    };

    useEffect(() => {
        if (watchPalette?.paletteId && watchPalette?.paletteId !== '0') {
            dispatch(
                standardPrintBridgeFetch({
                    templatesId: props?.data?.original?.id,
                    paletteId: watchPalette?.paletteId,
                    orgId: props?.data?.original?.fkOrgId,
                })
            );
        }
    }, [watchPalette?.paletteId]);

    useEffect(() => {
        if (update) {
            dispatch(standardPrintBridgeReset());
            props.hideModal();
        }
        if (fetch && fetch?.code === service.success) {
            setValue('template', fetch.data.template);
            setValue('id', fetch.data.id);
        } else {
            setValue('template', null);
            setValue('id', null);
        }
    }, [update, fetch]);

    useEffect(() => {
        if (watchAll && watchAll.paletteId !== '0' && watchAll.template !== '') {
            setIsValidated(true);
        } else {
            setIsValidated(false);
        }
    }, [watchAll]);

    const toggle = () => {
        props.hideModal();
        dispatch(standardPrintBridgeReset());
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={6}>
                    <FormInput
                        type="select"
                        id="paletteId"
                        label="Palette *"
                        className="custom-select"
                        register={register}
                        errors={errors}>
                        <option value="0">Select</option>
                        {paletteList?.map((item, index) => {
                            return (
                                <option key={index} value={item?.pkPaletteId}>
                                    {item?.colPaletteName}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
                <Col lg={12}>
                    <FormInput className="custom-textarea mb-3 mt-3" type="hidden" id="id" register={register} />
                    <FormInput
                        className="custom-textarea mb-3 mt-3"
                        type="textarea"
                        as="textarea"
                        id="template"
                        label="Html *"
                        errors={errors}
                        register={register}
                        height="true"
                    />
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement name="Update" variant="primary" type="submit" disabled={!isValidated} />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggle} />
            </div>
        </form>
    );
};

export default SitePrintBridgeModal;
