import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Switch, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as layoutConstants from '../constants/layout';

// Elastic APM
import { init as initApm } from '@elastic/apm-rum';
import { ApmRoute } from '@elastic/apm-rum-react';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import {
    CommonRoute,
    publicProtectedFlattenRoutes,
    PlatformAdminRoute,
    SystemAdminRoute,
    ApplicationAdminRoute,
    ReportAdminRoute,
    BrandAdminRoute,
    ContentAdminRoute,
    DataAdminRoute,
    UserRoute,
} from './index';
import { changeSidebarType, authLogout } from '@ovrture/react-redux';
import PrivateRoute from './PrivateRoute';
import { useAuth } from '../contexts/AuthContext';
import { useModalContext } from '../contexts/ModalContext';
import { modalActions, notificationActions } from '../reducers/actions';
import { useNotificationContex } from '../contexts/NotificationContext';
import ButtonElement from '../components/button/ButtonElement';
import { COMMON_PATH, PLATFORM_ADMIN_PATH, PUBLIC_PATH, RedirectTo, SYSTEM_ADMIN_PATH } from './role/path';
const Routes = (props) => {
    const [authProtectedRoutes, setAuthProtectedRoutes] = useState([{}]);
    const { notificationDispatch } = useNotificationContex();
    const { user, isOnline, error, refreshToken, setDefaultLayout, defaultLayout } = useAuth();
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const history = useHistory();
    const [confirm, setConfirm] = useState(false);
    const [confirmCallback, setConfirmCallback] = useState(null);
    const pathName = window.location.pathname;

    function getConfirmation(message, callback) {
        // if (message?.split('/')[1] === 'account') {
        //     callback(COMMON_PATH.ACCOUNT);
        //     return null;
        // }
        setConfirmCallback(() => callback);
        setConfirm(message);

        // const allowTransition = window.confirm(message);
        // callback(allowTransition);
    }

    if (process.env.REACT_APP_APM_ENABLE === 'true') {
        const apm = initApm({
            serviceName: process.env.REACT_APP_APM_SERVICE_NAME,
            serverUrl: process.env.REACT_APP_APM_URL,
            serviceVersion: process.env.REACT_APP_APM_SERVICE_VERSION,
        });
    }

    useEffect(() => {
        window.location.pathname !== RedirectTo(PUBLIC_PATH.LOGIN) &&
            modalDispatch({
                type: !isOnline ? modalActions.OPEN_MODAL : modalActions.CLOSE_MODAL,
                payload: {
                    modalDataKey: ['systemNotification', 'networkError'],
                    data: {
                        title: 'Connection Error!',
                        content: 'Please check your internet connection or change your network and try again.',
                        saveBtnDisabled: true,
                        cancelBtnDisabled: true,
                        closeBtnDisabled: true,
                    },
                    modalVariant: 'danger',
                },
            });
        setTimeout(() => {
            return notificationDispatch({
                type:
                    user?.token && error === 'Invalid credentials.'
                        ? notificationActions.OPEN_NOTIFICATION
                        : notificationActions.CLOSE_NOTIFICATION,
                payload: {
                    role: 'danger',
                    title: 'Expired',
                    iconClass: 'dripicons-checkmark h1',
                    className: 'icon',
                    message: 'Your token has beed expired',
                },
            });
        }, 1000);
        if (
            (user?.token && error === 'Invalid credentials.') ||
            (!user?.token && error === 'Authentication token is missing.')
        ) {
            setTimeout(() => {
                dispatch(authLogout());
                window.location.href = RedirectTo(PUBLIC_PATH.LOGIN);
            }, 3000);
        }
    }, [user, error, isOnline]);

    const hanldeSessionExpired = () => {
        dispatch(authLogout());
    };
    const { logedinUser } = useSelector((state) => ({
        logedinUser: state?.auth?.user,
    }));

    useEffect(() => {
        modalDispatch({
            type:
                refreshToken === 'expired' || error === 'Invalid token.'
                    ? modalActions.OPEN_MODAL
                    : modalActions.CLOSE_MODAL,
            payload: {
                modalAction: (e) => console.log(11, e),
                modalDataKey: ['systemNotification', 'sessionExpired'],
                data: {
                    title: 'Session Expired',
                    content: 'Your session has expired due to inactivity. Please sign in again.',
                    saveBtnDisabled: true,
                    closeBtnDisabled: true,
                    cancelBtnDisabled: true,
                    element: [
                        {
                            pos: 'center',
                            elm: <ButtonElement variant="light" name="Ok" onClick={hanldeSessionExpired} />,
                        },
                    ],
                },
                modalVariant: 'danger',
            },
        });
    }, [refreshToken]);

    useEffect(() => {
        if (user) {
            let rootRoute = {
                path: '/',
                exact: true,
                component: () =>
                    user?.roleId === 'PLATFORM_ADMIN' ? (
                        <Redirect to={PLATFORM_ADMIN_PATH.TENANT_SYSTEMS} />
                    ) : user?.roleId === 'SYSTEM_ADMIN' || user?.roleId === 'APPLICATION_ADMIN' ? (
                        <Redirect to={SYSTEM_ADMIN_PATH.USERS} />
                    ) : user?.roleId === 'USER' || user?.roleId === 'SUPER_USER' || user?.roleId === 'DATA_ADMIN' ? (
                        <Redirect to={COMMON_PATH.RECORD_PORTFOLIO} />
                    ) : user?.roleId === 'REPORT_ADMIN' ? (
                        <Redirect to={COMMON_PATH.REVIEW_AND_APPROVAL} />
                    ) : user?.roleId === 'BRAND_ADMIN' ? (
                        <Redirect to={COMMON_PATH.REVIEW_AND_APPROVAL} />
                    ) : user?.roleId === 'CONTENT_ADMIN' ? (
                        <Redirect to={COMMON_PATH.REVIEW_AND_APPROVAL} />
                    ) : (
                        ''
                    ),
                route: PrivateRoute,
            };
            setAuthProtectedRoutes(
                user?.roleId === 'PLATFORM_ADMIN'
                    ? [rootRoute, ...PlatformAdminRoute, ...CommonRoute]
                    : user?.roleId === 'SYSTEM_ADMIN'
                    ? [rootRoute, ...SystemAdminRoute, ...CommonRoute]
                    : user?.roleId === 'REPORT_ADMIN'
                    ? [rootRoute, ...ReportAdminRoute, ...CommonRoute]
                    : user?.roleId === 'BRAND_ADMIN'
                    ? [rootRoute, ...BrandAdminRoute, ...CommonRoute]
                    : user?.roleId === 'CONTENT_ADMIN'
                    ? [rootRoute, ...ContentAdminRoute, ...CommonRoute]
                    : user?.roleId === 'APPLICATION_ADMIN'
                    ? [rootRoute, ...ApplicationAdminRoute, ...CommonRoute]
                    : user?.roleId === 'DATA_ADMIN'
                    ? [rootRoute, ...DataAdminRoute, ...CommonRoute]
                    : [rootRoute, ...UserRoute, ...CommonRoute]
            );
        }
    }, [user]);

    useEffect(() => {
        if (defaultLayout && document.title !== '404') {
            setDefaultLayout(false);
        }
    }, [pathName]);

    useEffect(() => {
        dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED));
    }, [dispatch]);
    useEffect(() => {
        if (!logedinUser && history.location.pathname === '/') {
            window.location.href = RedirectTo(PUBLIC_PATH.LOGIN);
        } else if (!logedinUser && !checkPassedPathIsValid(history.location.pathname)) {
            window.location.href = RedirectTo(PUBLIC_PATH.LOGIN);
        }
    }, [logedinUser]);

    const checkPassedPathIsValid = (pathname) => {
        var flag = false;
        publicProtectedFlattenRoutes.forEach((item) => {
            if (pathname === item.pathExact) {
                flag = true;
            }
        });
        return flag;
    };

    function allowTransition() {
        if (confirm.includes('/account/logout')) {
            dispatch(authLogout());
            return null;
        }
        setConfirm(false);
        confirmCallback(true);
        modalDispatch({ type: modalActions.CLOSE_MODAL });
    }

    function blockTransition() {
        setConfirm(false);
        confirmCallback(false);
        modalDispatch({ type: modalActions.CLOSE_MODAL });
    }

    useEffect(() => {
        if (confirm) {
            modalDispatch({
                type: modalActions.OPEN_MODAL,
                payload: {
                    modalAction: (e) => modalDispatch({ type: modalActions.CLOSE_MODAL }),
                    modalVariant: 'primary',
                    modalDataKey: ['common', 'leavingRouteMsg'],
                    data: {
                        allowTransition,
                        blockTransition,
                        title: false,
                    },
                },
            });
        }
        if (pathName === RedirectTo('/account/logout') && !confirm) {
            dispatch(authLogout());
        }
    }, [confirm, pathName]);

    return (
        <BrowserRouter getUserConfirmation={getConfirmation}>
            <Switch>
                {!user && (
                    <>
                        <ApmRoute path={publicProtectedFlattenRoutes.map((r) => r['path'])}>
                            <DefaultLayout {...props} layout={VerticalLayout}>
                                <Switch>
                                    {publicProtectedFlattenRoutes.map((route, index) => {
                                        return (
                                            route.path && (
                                                <route.route
                                                    key={index}
                                                    path={route.path}
                                                    roles={route.roles}
                                                    exact={route.exact}
                                                    component={route.component}
                                                />
                                            )
                                        );
                                    })}
                                </Switch>
                            </DefaultLayout>
                        </ApmRoute>
                    </>
                )}

                {authProtectedRoutes && (
                    <ApmRoute path={authProtectedRoutes.map((r) => r['path'])}>
                        <VerticalLayout {...props} layout={VerticalLayout}>
                            <Switch>
                                {authProtectedRoutes.map((route, index) => {
                                    return (
                                        route.path && (
                                            <route.route
                                                key={index}
                                                path={route.path}
                                                roles={route.roles}
                                                exact={route.exact}
                                                component={route.component}
                                                bredcrumbs={route.bredcrumbs}
                                            />
                                        )
                                    );
                                })}
                            </Switch>
                        </VerticalLayout>
                    </ApmRoute>
                )}
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
