// @flow
import React, { useState, useEffect } from 'react';
// components
import { currentEditors as data } from '../../../constants/data';
import Table from '../../../components/table/Table';
import ButtonElement from '../../../components/button/ButtonElement';
import { table as TableColumn } from '../../../constants/table';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { siteEditorFind, reportEditorList } from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { dateTimeFormat } from '../../../utility';

const CurrentEditorsModal = (props) => {
    const { isSite, id } = props?.data;
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const [editorList, seteditorList] = useState([]);
    const [loading, setloading] = useState(false);

    const { siteEditorList, reportEditorItems } = useSelector((state) => ({
        siteEditorList: state?.siteEditor?.find,
        reportEditorItems: state?.reportEditor?.list,
    }));

    useEffect(() => {
        const data = siteEditorList || reportEditorItems || [];
        if (data && data?.length > 0) {
            data.forEach((d) => {
                d['userName'] = d.user.colFullName;
                d['lastEdited'] = d.colLastUpdatedAt ? dateTimeFormat(d.colLastUpdatedAt) : '-';
                d['action'] = d.colEditorActionName;
            });
            seteditorList(data);
            setloading(false);
        } else {
            seteditorList([]);
            setloading(false);
        }
    }, [siteEditorList, reportEditorItems]);

    useEffect(() => {
        if (id) {
            setloading(true);
            isSite
                ? dispatch(siteEditorFind({ pkSiteId: id, sort: 'colLastUpdatedAt,DESC' }))
                : dispatch(reportEditorList({ pkReportId: id, sort: 'colLastUpdatedAt,DESC' }));
        }
    }, [id]);
    return (
        <>
            {props?.data?.isSite === true ? (
                <p className="mb-0">The following changes have been made to this site:</p>
            ) : (
                <p className="mb-0">The following changes have been made to this report:</p>
            )}
            <Table
                columns={TableColumn.systemAdmin.currentEditors()}
                data={editorList}
                pageSize={TableColumn.pageSize}
                sizePerPageList={TableColumn.sizePerPageList(data)}
                isSortable={true}
                pagination={true}
                isSearchable={false}
                isFilter={false}
                theadClass={TableColumn.table_light}
                tableClass="current-editors"
                loading={loading}
                tableParentClass="height-fixed"
            />
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Close"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default CurrentEditorsModal;
