let editorStorageKey = 'editorStorageKey';
const editorSavedChanges = 'editorSavedChanges';
export const setEditorStorage = (key, value) => {
    let existingObj = JSON.parse(localStorage.getItem(editorStorageKey)) || {};
    existingObj[key] = value;
    localStorage.setItem(editorStorageKey, JSON.stringify(existingObj));
};

// getEditorStorage('coverImgInfo')
export const getEditorStorage = (key) => {
    let obj = JSON.parse(localStorage.getItem(editorStorageKey));
    return obj && obj[key];
};

// removeEditorStorage('coverImgInfo')
export const removeEditorStorage = (key) => {
    let existingObj = JSON.parse(localStorage.getItem(editorStorageKey)) || {};
    delete existingObj[key];
    localStorage.setItem(editorStorageKey, JSON.stringify(existingObj));
};

//setEditorChanges(true)
export const setEditorChanges = (value) => {
    localStorage.setItem(editorSavedChanges, JSON.stringify(value));
};

// getEditorChanges()
export const getEditorChanges = () => {
    return JSON.parse(localStorage.getItem(editorSavedChanges));
};

// removesetEditorChanges()
export const removeEditorChanges = () => {
    localStorage.removeItem(editorSavedChanges);
};
