//@flow
import React from 'react';
//components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

const ApprovalConfimation = (props) => {
    const { modalDispatch } = useModalContext();
    return (
        <>
            <p>
                Are you sure you want to approve this
                {props?.data?.data?.name === 'Site' ? ' this/these site(s)' : ' this/these report(s)'}?
            </p>
            <div className="modal-footer">
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
                <ButtonElement
                    name="Next"
                    variant="primary"
                    className="btn-next"
                    type="button"
                    onClick={() => props.setActive(props.active + 1)}
                />
            </div>
        </>
    );
};

export default ApprovalConfimation;
