// @flow
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

// component
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { cpPreviewImage } from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { addSlickSliderTarget } from '../../../helpers/editor/slick/SlickSlider';
import _ from 'underscore';
import { Helmet } from 'react-helmet';
import { carouselMigration } from '../../../helpers/editor/migration/TenantMigration';
import { CustomLoader } from '../../../helpers/customLoader';
import { RedirectTo } from '../../../routes/role/path';

const ContentPanelPreviewModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const { cpPreview, details } = useSelector(
        (state) => ({
            cpPreview: state?.cpFolder?.cpPreview,
            details: state?.auth?.me,
        }),
        _.isEqual
    );

    useEffect(() => {
        props?.data?.data?.id && dispatch(cpPreviewImage({ cpFolderViewId: props?.data?.data?.id }));
    }, []);

    useEffect(() => {
        if (cpPreview?.data?.htmlCode) {
            document.getElementById('previewOnly').innerHTML = cpPreview?.data?.htmlCode;
            let doc = new DOMParser().parseFromString(cpPreview?.data?.htmlCode, 'text/html');
            const photoGalleryCarousel = doc?.querySelectorAll('#photoGalleryCarousel .frame_adjuster');
            photoGalleryCarousel.length > 0 &&
                setTimeout(
                    () =>
                        addSlickSliderTarget(
                            document.querySelector('#previewOnly #photoGalleryCarousel'),
                            photoGalleryCarousel
                        ),
                    100
                );
            carouselMigration();
            const previewOnly = document?.querySelector('.previewOnly .standard-template');
            if (props?.data?.isParentTemplate && previewOnly) {
                previewOnly.querySelector('.carousel-indicators').style.display = 'none';
                previewOnly.querySelectorAll('.carousel-control')[1].style.display = 'none';
                let span = previewOnly?.querySelectorAll('.carousel-control')[1]?.querySelector('span');
                if (span?.style?.display.trim() === 'block') {
                    span.style.display = 'none';
                }
                previewOnly.querySelector('.learn-more-wrapper').style.pointerEvents = 'none';
            }
            setTimeout(() => {
                setLoading(false);
                setTimeout(() => sessionStorage.removeItem('SKIP_LOADER_ALL'), 100);
            }, 800);
        }
    }, [cpPreview?.data?.htmlCode]);

    useEffect(() => {
        if (previewModalOpen) {
            modalDispatch({
                type: modalActions.OPEN_MODAL,
                payload: {
                    modalAction: () => {
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        });
                    },
                    data: {
                        data: props?.data?.data,
                        jstreeData: props?.data?.jstreeData,
                        handleData: props?.data?.handleData,
                        info: props?.data?.info,
                        pkRecordsId: props?.data?.pkRecordsId,
                        slotInfo: props?.data?.slotInfo,
                        slotName: props?.data?.slotName,
                        page: props?.data?.page,
                        year: props?.data?.year,
                    },
                    modalVariant: 'primary',
                    modalDataKey: [
                        props?.data?.page === 'site-report-editor' ? 'editor' : 'common',
                        props?.data?.page === 'site-report-editor' ? 'addContentPanel' : 'selectContentPanel',
                    ],
                },
            });
        }
    }, [previewModalOpen]);

    document.addEventListener('keydown', (e) => {
        if (
            (window.location.pathname === RedirectTo('/sites-create') ||
                window.location.pathname === RedirectTo('/reports-create') ||
                window.location.pathname.includes(RedirectTo('/site-edit')) ||
                window.location.pathname.includes(RedirectTo('/report-edit')) ||
                window.location.pathname === RedirectTo('/systemsettings-btd')) &&
            (e.key === ' ' || e.code === 'Space' || e.keyCode === 32)
        ) {
            if (
                props?.data?.data?.icon === 'glyphicon glyphicon-image' ||
                props?.data?.data?.icon === 'glyphicon glyphicon-doc-file' ||
                props?.data?.data?.icon === 'glyphicon glyphicon-video' ||
                props?.data?.data?.icon === 'glyphicon glyphicon-pdf-file' ||
                props?.data?.data?.icon === 'glyphicon glyphicon-text-background-cp' ||
                props?.data?.data?.icon === 'fa-fa-folder-palette-closed' ||
                props?.data?.data?.icon === 'glyphicon glyphicon-text-background-cp-draft'
            ) {
                setPreviewModalOpen(true);
                return false;
            }
        }
    });

    return (
        <>
            <CustomLoader loader={loading}></CustomLoader>
            <Helmet>
                <link
                    rel="stylesheet"
                    href={`https://common-assets-relay.s3.us-west-2.amazonaws.com/demo/react-test/Org${details?.organaisationId}/assets/style/style.css`}></link>
            </Helmet>
            <Card>
                <Card.Body className={loading ? 'previewOnly hide' : 'previewOnly'} id="previewOnly"></Card.Body>
            </Card>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Back"
                    variant="outline-primary"
                    type="button"
                    onClick={() => {
                        props?.data?.setPreviewModalOpen && props?.data?.setPreviewModalOpen(false);
                        if (props?.data?.jstreeData) {
                            modalDispatch({
                                type: modalActions.OPEN_MODAL,
                                payload: {
                                    modalAction: (e) =>
                                        modalDispatch({
                                            type: modalActions.CLOSE_MODAL,
                                        }),
                                    modalVariant: 'primary',
                                    modalDataKey: [
                                        props?.data?.page === 'site-report-editor' ? 'editor' : 'common',
                                        props?.data?.page === 'site-report-editor'
                                            ? 'addContentPanel'
                                            : 'selectContentPanel',
                                    ],
                                    data: {
                                        info: props?.data?.info,
                                        handleData: props?.data?.handleData,
                                        jstreeData: props?.data?.jstreeData,
                                        data: props?.data?.data,
                                        page: props?.data?.page,
                                        pkRecordsId: props?.data?.pkRecordsId,
                                        slotName: props?.data?.slotName,
                                    },
                                },
                            });
                        } else {
                            props.hideModal();
                        }
                    }}
                />
            </div>
        </>
    );
};

export default ContentPanelPreviewModal;
