// @flow
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, ListGroup } from 'react-bootstrap';
// components
import { table as TableColumn } from '../../../constants/table';
import Table from '../../../components/table/Table';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { FormInput } from '../../../components/form/input';
import { useDispatch, useSelector } from 'react-redux';
import { useNotification, siteBuildList, siteBulkClone } from '@ovrture/react-redux';
import ListItem from '../../../components/listItem';

const DuplicateModal = (props) => {
    const { Toast } = useNotification();
    const { modalDispatch } = useModalContext();
    const [enteredRecordVal, setEnteredRecordVal] = useState(null);
    const [dataList, setdataList] = useState([]);
    const [itemSelected, setItemSelected] = useState(false);
    const [recordData, setrecordData] = useState(null);
    const [deleted, setdeleted] = useState(null);

    const dispatch = useDispatch();
    const { buildList } = useSelector((state) => ({
        buildList: state?.site?.buildList,
    }));
    useEffect(() => {
        if (enteredRecordVal === null || enteredRecordVal === '') {
            resetModal();
        } else if (enteredRecordVal && matchRecordList(enteredRecordVal)) {
            dispatch(
                siteBuildList({
                    isAnd: true,
                    // isUnpaged: true,
                    searchText: enteredRecordVal,
                    size: 100,
                })
            );
        }
    }, [enteredRecordVal]);

    const resetModal = () => {
        setItemSelected(false);
    };
    const matchRecordList = (val) => {
        let matchItems = dataList.find((f) => f.Name === val);
        if (matchItems) return false;
        else return true;
    };
    const handleAddRecord = () => {
        const lst = [...dataList];
        if (dataList?.filter((f) => f.fkRecordId === recordData?.pkRecordsId)?.length === 0) {
            const { pkRecordsId, colRecordReferenceNo } = recordData;
            lst.push({ fkRecordId: pkRecordsId, name: enteredRecordVal, recordReference: colRecordReferenceNo });
            setdataList(lst);
            setEnteredRecordVal([]);
            setItemSelected(null);
            setrecordData(null);
        }
    };

    const removeItem = (indx) => {
        const lst = [...dataList];
        lst.splice(indx, 1);
        setdataList(lst);
    };
    const DeleteAction = ({ row }) => {
        return (
            <>
                <ButtonElement
                    className={deleted ? 'action-icon pe-none' : 'action-icon hover-danger'}
                    icon={<i className="mdi mdi-delete" />}
                    tooltip="Delete"
                    onClick={() => {
                        removeItem(row.index);
                    }}
                />
            </>
        );
    };

    const handleDuplicate = () => {
        const siteId = props?.data?.original?.pkSiteId;
        const payload = [];
        dataList.length > 0 && dataList.map((d) => payload.push({ fkRecordId: d.fkRecordId, siteId: siteId }));
        payload?.length > 0 && dispatch(siteBulkClone(payload));
    };

    document.onkeydown = function (e) {
        try {
            if (e.code === 'Enter' || e.keyCode === 13) {
                const modalActionBtn = document.querySelector(
                    '.modal.show button[type*="button"]:not([disabled]).action-btn'
                );
                if (modalActionBtn !== null && !modalActionBtn?.attributes?.disabled) {
                    modalActionBtn.click();
                }
            } else if (e.code === 'Escape' || e.keyCode === 27) {
                console.log('esc key pressed');
            }
        } catch (e) {}
    };

    return (
        <>
            <p>
                This function allows you to make an exact duplicate of an existing site for records that do not
                currently have a site.
            </p>
            <p>A duplicate will be made for the following records:</p>
            <div className="duplicate-site mb-3">
                <Row>
                    <Col md={10}>
                        <FormInput
                            type="text"
                            id="recordNumber"
                            className="mb-0"
                            label="Enter Record Number or Name"
                            maxLength="120"
                            feedback="hide"
                            onChange={(e) => {
                                setEnteredRecordVal(e.target.value);
                                setTimeout(() => {
                                    setItemSelected(true);
                                }, 2000);
                            }}
                            value={enteredRecordVal}
                        />
                        {buildList?.content && itemSelected && enteredRecordVal && (
                            <>
                                <ListGroup
                                    className={
                                        enteredRecordVal
                                            ? 'listheader custom-list-group-ellipsis custom-listgroup height-auto'
                                            : ''
                                    }>
                                    {buildList?.content.map((rcd) => {
                                        return (
                                            <ListItem
                                                key={rcd.pkRecordsId}
                                                onClick={() => {
                                                    setEnteredRecordVal(
                                                        rcd?.colRecordFullName ||
                                                            rcd?.colRecordFirstName ||
                                                            rcd?.colRecordLastName
                                                    );
                                                    setItemSelected(false);
                                                    setrecordData(rcd);
                                                }}>
                                                {rcd?.colRecordFullName ||
                                                    rcd?.colRecordFirstName ||
                                                    rcd?.colRecordLastName}
                                            </ListItem>
                                        );
                                    })}
                                    {buildList?.content?.length === 0 && <b className="p-2">{'No Record Found.'}</b>}
                                </ListGroup>
                            </>
                        )}
                    </Col>
                    <Col md={2} className="px-1">
                        <ButtonElement
                            name="Add"
                            className="mt-10"
                            variant="primary"
                            type="button"
                            disabled={enteredRecordVal ? false : true}
                            onClick={(e) => handleAddRecord()}
                        />
                    </Col>
                </Row>
            </div>
            <Card>
                <Table
                    columns={TableColumn.contentPanel.duplicate([DeleteAction])}
                    data={dataList || []}
                    sizePerPageList={TableColumn.sizePerPageList(dataList)}
                    isSortable={true}
                    pagination={false}
                    isSearchable={false}
                    isFilter={false}
                    isSelectable={false}
                    theadClass={TableColumn.table_light}
                    tableClass="duplicate"
                    paginationDisplayDrowpdownHide={true}
                />
            </Card>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Duplicate"
                    variant="primary"
                    type="button"
                    className="action-btn"
                    disabled={dataList.length > 0 ? false : true}
                    onClick={(e) => {
                        handleDuplicate();
                    }}
                />
                <ButtonElement
                    name="Close"
                    variant="light"
                    type="button"
                    onClick={(e) => {
                        modalDispatch({ type: modalActions.CLOSE_MODAL });
                    }}
                />
            </div>
        </>
    );
};

export default DuplicateModal;
