// @flow
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Row, Col, Card } from 'react-bootstrap';
// components
import { FormInput } from '../../../../components/form/input';
import Table from '../../../../components/table/Table';
import ButtonElement from '../../../../components/button/ButtonElement';
import { table as TableColumn } from '../../../../constants/table';
import {
    paletteList,
    accessCriteriaForReportDataReset,
    accessCriteriaForReportDataFetchDonor,
    accessCriteriaForReportDataAddDonor,
    accessCriteriaForReportDataUpdateDonor,
} from '@ovrture/react-redux';
import { ObjToArrayConvert } from '../../../../utility';
import { useDispatch, useSelector } from 'react-redux';
import { dateTimeFormat } from '../../../../utility';

const FundDonorModal = (props) => {
    const {
        register,
        handleSubmit,
        setValue,
        control,
        setError,
        setFocus,
        reset,
        formState: { errors },
    } = useForm({});
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const [fundTypeId, setFundTypeId] = useState(null);
    const [paletteId, setPaletteId] = useState(null);
    const [updatedAt, setUpdatedAt] = useState();

    const { list, fundTypeList, paletteDataList, add, update, orgFetch, loading } = useSelector((state) => ({
        paletteDataList: state?.palette?.list?.data,
        fundTypeList: state?.fundType?.list?.data,
        list: state?.accessCriteriaForReportData?.fetchDonor,
        add: state?.accessCriteriaForReportData?.addDonor,
        update: state?.accessCriteriaForReportData?.updateDonor,
        loading: state?.accessCriteriaForReportData?.loading,
        orgFetch: state?.organization?.fetch?.data,
    }));

    useEffect(() => {
        if (fundTypeId) dispatch(paletteList({ orgId: orgFetch.id }));
    }, [fundTypeId]);
    useEffect(() => {
        if (list) setUpdatedAt(dateTimeFormat(list?.colUpdatedAt));
    }, [list]);
    useEffect(() => {
        if (paletteId && fundTypeId) {
            setValue('paletteId', paletteId);
            dispatch(
                accessCriteriaForReportDataFetchDonor({
                    orgId: orgFetch.id,
                    paletteId: paletteId,
                    fundTypeId: fundTypeId,
                })
            );
        }
    }, [paletteId, fundTypeId]);

    useEffect(() => {
        if (list) {
            reset();
            setValue('fundTypeId', list?.fkFundTypeId);
            setValue('paletteId', list?.fkPaletteId);
            setData(ObjToArrayConvert(list, 4));
        }
        if (add) {
            dispatch(accessCriteriaForReportDataReset());
            props?.hideModal();
        }
        if (update) {
            dispatch(accessCriteriaForReportDataReset());
            props?.hideModal();
        }
    }, [list, add, update]);

    const onSubmit = (data) => {
        if (data.fundTypeId) data.fundTypeId = parseInt(data.fundTypeId);
        if (data?.paletteId) data.paletteId = parseInt(data.paletteId);
        list?.pkAccessCriteriaForFundDonorId
            ? dispatch(
                  accessCriteriaForReportDataUpdateDonor({
                      orgId: orgFetch.id,
                      pkAccessCriteriaForFundDonorId: list?.pkAccessCriteriaForFundDonorId,
                      ...data,
                  })
              )
            : dispatch(accessCriteriaForReportDataAddDonor({ orgId: orgFetch.id, ...data }));
    };

    const toggle = () => {
        dispatch(accessCriteriaForReportDataReset());
        props?.hideModal();
    };

    const FieldName = ({ row }) => {
        return <ButtonElement as="text" name={row.original.fieldName} tooltip={row.original.replacer}></ButtonElement>;
    };

    const handleMandatory = (name, row) => {
        const check = !data[row.index][name];
        data[row.index][name] = check;
        setData(data);
        setError(name, row.original[name]);
        setValue(name, check);
    };

    const MandatoryColumn = ({ row }) => {
        return (
            <FormInput
                type="switch"
                className="mb-0 col-md-0"
                id={row.original.key + 'IsMandatory'}
                register={register}
                onChange={(e) => handleMandatory(row.original.key + 'IsMandatory', row)}
                checked={row.original[row.original.key + 'IsMandatory']}
            />
        );
    };
    const RenderColumn = ({ row }) => {
        return (
            <FormInput
                type="switch"
                className="mb-0 col-md-0"
                id={row.original.key}
                register={register}
                checked={row.original[row.original.key]}
                onChange={(e) => handleMandatory(row.original.key, row)}
            />
        );
    };
    const DefaultValue = ({ row }) => {
        return (
            <Controller
                name={row.original.key + 'DefaultValue'}
                control={control}
                rules={{ required: row.original[row.original.key + 'IsMandatory'] === true ? true : false }}
                defaultValue={row.original[row.original.key + 'DefaultValue']}
                render={({ field }) => (
                    <>
                        <FormInput
                            type="text"
                            id={row.original.key + 'DefaultValue'}
                            className="mb-0"
                            register={register}
                            errors={errors}
                            onChange={(e) => {
                                delete errors[e?.target?.name];
                                e?.target?.classList?.remove('is-invalid');
                            }}
                            feedback="hide"
                            floatinglabel="false"
                        />
                    </>
                )}
            />
        );
    };

    const PreprocessingValue = ({ row }) => {
        return (
            <FormInput
                type="text"
                id={row.original.key + 'PreProcessingValue'}
                className="mb-0"
                register={register}
                defaultValue={row.original[row.original.key + 'PreProcessingValue']}
                feedback="hide"
                floatinglabel="false"
            />
        );
    };

    const setFocusValue = () => {
        setTimeout(() => {
            let errorsArr = [];
            Object.keys(errors).forEach((k) => {
                errorsArr.push({ key: k, value: errors[k] });
            });
            let error = errorsArr.find((f) => f?.value?.type === 'required');
            setFocus(error?.key);
        }, 500);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {list?.colUpdatedAt && <p className="text-end custom-font mb-1">Last Edited: {updatedAt}</p>}
            <Card>
                <Card.Body>
                    <Row>
                        <Col md={6}>
                            <FormInput
                                type="select"
                                id="fundTypeId"
                                className="mb-0"
                                label="Fund *"
                                register={register}
                                onChange={(e) => setFundTypeId(e.target.value)}>
                                <option value="">Select</option>
                                {fundTypeList?.map((item, index) => {
                                    return (
                                        <option key={index} value={item?.pkFundTypeId}>
                                            {item?.colFundTypeName}
                                        </option>
                                    );
                                })}
                            </FormInput>
                        </Col>

                        <Col md={6} className="custom-spacing">
                            <FormInput
                                type="select"
                                id="paletteId"
                                label="Palette *"
                                className={fundTypeId ? 'mb-0' : 'custom-disabled'}
                                register={register}
                                onChange={(e) => setPaletteId(e.target.value)}>
                                <option value="">Select</option>
                                {paletteDataList?.map((item, index) => {
                                    return (
                                        <option key={index} value={item?.pkPaletteId}>
                                            {item?.colPaletteName}
                                        </option>
                                    );
                                })}
                            </FormInput>
                        </Col>
                    </Row>
                </Card.Body>
                {paletteId && (
                    <Table
                        columns={TableColumn.editElent.reportData.fundDonor([
                            FieldName,
                            RenderColumn,
                            MandatoryColumn,
                            DefaultValue,
                            PreprocessingValue,
                        ])}
                        data={data}
                        pageSizes={list?.data?.size}
                        pageNumber={list?.data?.number}
                        totalElements={list?.data?.totalElements}
                        totalPages={list?.data?.totalPages}
                        paginationDispatch={accessCriteriaForReportDataFetchDonor}
                        paginationDispatchParam={{ orgId: orgFetch?.data?.id }}
                        sizePerPageList={TableColumn.sizePerPageList(data)}
                        isSortable={false}
                        pagination={false}
                        isSearchable={false}
                        isFilter={false}
                        isSelectable={false}
                        loading={loading}
                        theadClass={TableColumn.table_light}
                        tableClass="report-data-fund-donor"
                        tableParentClass="height-fixed"
                    />
                )}
            </Card>

            <div className="button-list d-flex justify-content-center">
                {fundTypeId && paletteId && (
                    <ButtonElement name="Update" variant="primary" type="submit" onClick={() => setFocusValue()} />
                )}
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggle} />
            </div>
        </form>
    );
};

export default FundDonorModal;
