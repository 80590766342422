// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
    templateContentsFetch,
    templateContentsUpdate,
    templateContentsReset,
    templateReportContentsFetch,
    templateContentsAdd,
} from '@ovrture/react-redux';
// components
import { SiteEditElementValidation } from '../../../../components/form/validation';
import { FormInput } from '../../../../components/form/input';
import ButtonElement from '../../../../components/button/ButtonElement';
import service from '../../../../constants/service.constant';

const EditBrandDetails = (props) => {
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        reset,
        formState: { errors },
    } = useForm({ resolver: SiteEditElementValidation, mode: 'onBlur' });
    const watchPalette = watch('paletteId');
    const watchAll = watch();
    const [isValidated, setIsValidated] = useState(false);

    const { paletteList, fetch, error, update, add } = useSelector((state) => ({
        paletteList: state?.palette?.list?.data,
        error: state?.palette?.error,
        fetch: state?.templateContents?.fetch || state?.templateContents?.reportFetch,
        update: state?.templateContents?.update,
        add: state?.templateContents?.add,
    }));
    const onSubmit = (data) => {
        if (props?.data?.isForSite) {
            if (fetch?.data?.id) {
                dispatch(
                    templateContentsUpdate({
                        colCampaign: data?.colCampaign,
                        colContact: data?.colContact,
                        colFooter: data?.colFooter,
                        colGallery: data?.colGallery,
                        messageMaxCount: data?.messageMaxCount,
                        colHeader: data?.colHeader,
                        colIndex: data?.colIndex,
                        paletteId: Number(data?.paletteId),
                        templateId: props?.data?.original?.id,
                        orgId: props?.data?.original?.fkOrgId,
                        isForSite: true,
                        emailBugPath: data?.emailBugPath,
                        bugTextDetails: data?.bugTextDetails,
                        reportUpdateCompatibilityAccessible: false,
                    })
                );
            } else {
                dispatch(
                    templateContentsAdd({
                        colCampaign: data?.colCampaign,
                        colContact: data?.colContact,
                        colFooter: data?.colFooter,
                        colGallery: data?.colGallery,
                        messageMaxCount: data?.messageMaxCount,
                        colHeader: data?.colHeader,
                        colIndex: data?.colIndex,
                        paletteId: Number(data?.paletteId),
                        templateId: props?.data?.original?.id,
                        orgId: props?.data?.original?.fkOrgId,
                        isForSite: true,
                        emailBugPath: data?.emailBugPath,
                        bugTextDetails: data?.bugTextDetails,
                        reportUpdateCompatibilityAccessible: false,
                    })
                );
            }
        } else {
            if (fetch?.data?.pkReportTemplateContentsId) {
                dispatch(
                    templateContentsUpdate({
                        colCampaign: data?.colCampaign,
                        colContact: data?.colContact,
                        colFooter: data?.colFooter,
                        colGallery: data?.colGallery,
                        messageMaxCount: data?.messageMaxCount,
                        colHeader: data?.colHeader,
                        colIndex: data?.colIndex,
                        paletteId: Number(data?.paletteId),
                        templateId: props?.data?.original?.id,
                        orgId: props?.data?.original?.fkOrgId,
                        isForSite: false,
                        emailBugPath: data?.emailBugPath,
                        bugTextDetails: data?.bugTextDetails,
                        reportUpdateCompatibilityAccessible: data?.reportUpdateCompatibilityAccessible,
                    })
                );
            } else {
                dispatch(
                    templateContentsAdd({
                        colCampaign: data?.colCampaign,
                        colContact: data?.colContact,
                        colFooter: data?.colFooter,
                        colGallery: data?.colGallery,
                        messageMaxCount: data?.messageMaxCount,
                        colHeader: data?.colHeader,
                        colIndex: data?.colIndex,
                        paletteId: Number(data?.paletteId),
                        templateId: props?.data?.original?.id,
                        orgId: props?.data?.original?.fkOrgId,
                        isForSite: false,
                        emailBugPath: data?.emailBugPath,
                        bugTextDetails: data?.bugTextDetails,
                        reportUpdateCompatibilityAccessible: data?.reportUpdateCompatibilityAccessible,
                    })
                );
            }
        }
    };

    useEffect(() => {
        if (watchPalette && watchPalette !== '0' && fetch === null) {
            if (props?.data?.isForSite) {
                dispatch(
                    templateContentsFetch({
                        templateId: props?.data?.original?.id,
                        paletteId: watchPalette,
                        orgId: props?.data?.original?.fkOrgId,
                    })
                );
            } else {
                dispatch(
                    templateReportContentsFetch({
                        templateId: props?.data?.original?.id,
                        paletteId: watchPalette,
                        orgId: props?.data?.original?.fkOrgId,
                    })
                );
            }
        }
    }, [watchPalette]);

    useEffect(() => {
        if (
            watchAll &&
            watchAll.bugTextDetails !== '' &&
            watchAll.colContact !== '' &&
            watchAll.colIndex !== '' &&
            watchAll.emailBugPath !== '' &&
            watchAll.messageMaxCount !== '' &&
            watchAll.paletteId !== '0'
        ) {
            setIsValidated(true);
        } else {
            setIsValidated(false);
        }
    }, [watchAll]);
    useEffect(() => {
        if (fetch && fetch?.code === service.success) {
            setValue('paletteId', fetch?.data.fkPalletId);
            setValue('colCampaign', fetch?.data.colCampaign);
            setValue('colHeader', fetch?.data.colHeader);
            setValue('colFooter', fetch?.data.colFooter);
            setValue('isForSite', props?.data.isForSite);
            setValue('colGallery', fetch?.data.colGallery);
            setValue('templateId', fetch?.data.templateId || fetch?.data.fkTemplateId);
            setValue('orgId', fetch?.data.orgId || fetch?.data.fkOrgId);
            setValue('colIndex', fetch?.data?.colIndex);
            setValue('colContact', fetch?.data?.colContact);
            setValue('messageMaxCount', fetch?.data?.colGreetiingMsgMaxCount);
            setValue('emailBugPath', fetch?.data?.emailBugPath);
            setValue('bugTextDetails', fetch?.data?.bugTextDetails);
            setValue('reportUpdateCompatibilityAccessible', fetch?.data?.colReportUpdateCompatibilityAccessible);
        }

        if (update) {
            reset();
            props.hideModal();
            dispatch(templateContentsReset());
        }

        if (add) {
            reset();
            props.hideModal();
            dispatch(templateContentsReset());
        }
    }, [error, fetch, update, add]);

    const toggle = () => {
        props.hideModal();
        dispatch(templateContentsReset());
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={6}>
                    <FormInput id="templateId" register={register} />
                    <FormInput id="orgId" register={register} />
                    <Col md={6}>
                        <FormInput type="select" id="paletteId" label="Palette *" register={register} errors={errors}>
                            <option value="0">Select</option>
                            {paletteList?.map((item, index) => {
                                return (
                                    <option key={index} value={item?.pkPaletteId}>
                                        {item?.colPaletteName}
                                    </option>
                                );
                            })}
                        </FormInput>
                    </Col>
                </Col>
                {!props?.data?.isForSite && (
                    <Row>
                        <Col lg={12}>
                            <span className="me-2">Report Update Compatibility Access:</span>
                            <FormInput
                                className="me-4 mb-3"
                                type="switch"
                                id="reportUpdateCompatibilityAccessible"
                                label={watchAll?.reportUpdateCompatibilityAccessible === true ? 'ON' : 'OFF'}
                                register={register}
                            />
                        </Col>
                    </Row>
                )}
                <Col md={12}>
                    <FormInput
                        className="custom-textarea mb-3"
                        type="textarea"
                        as="textarea"
                        id="colIndex"
                        label="Index Html *"
                        errors={errors}
                        register={register}
                        height="true"
                    />
                    <FormInput
                        className="custom-textarea mb-3"
                        type="textarea"
                        as="textarea"
                        id="colContact"
                        label="Contact Html *"
                        errors={errors}
                        register={register}
                        height="true"
                    />
                    <FormInput
                        type="text"
                        id="messageMaxCount"
                        label="Greeting Max Limit *"
                        errors={errors}
                        register={register}
                    />
                    <FormInput
                        type="text"
                        id="emailBugPath"
                        label="Email Bug Path *"
                        maxLength="300"
                        errors={errors}
                        register={register}
                    />
                    <FormInput
                        className="custom-textarea"
                        type="textarea"
                        as="textarea"
                        id="bugTextDetails"
                        label="Email Bug Details *"
                        errors={errors}
                        register={register}
                        height="true"
                    />
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement name="Update" variant="primary" type="submit" disabled={!isValidated} />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggle} />
            </div>
        </form>
    );
};

export default EditBrandDetails;
