// @flow
import React from 'react';
//components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

const SessionRecordingModal = (props) => {
    const { modalDispatch } = useModalContext();
    return (
        <>
            {props?.data?.isBlock ? (
                <div className="session-blocked mb-3">
                    <h3>Session Recording Blocked</h3>
                    <p className="mt-3">
                        <span className="text-danger">NOTE:</span> While we can confirm a session took place, the
                        visitor blocked the session recording via browser settings or direct opt out.
                    </p>
                </div>
            ) : (
                <iframe src={props?.url} className="recording-iframe mb-2" />
            )}
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Close"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default SessionRecordingModal;
