// @flow
import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import ButtonElement from '../../../components/button/ButtonElement';

const OperationalBar = ({ name, icons, actions, className, ...otherProps }) => {
    return (
        <ButtonGroup className={className + ' operational-bar'}>
            {name && (
                <div className="bg-secondary text-white d-flex align-items-center ps-2 pe-1">
                    <strong>{name} </strong>
                </div>
            )}
            {icons.map((iconItem, index) => {
                return (
                    <ButtonElement
                        key={index}
                        title={
                            otherProps?.disableClass?.find((f) => f?.index === index)
                                ? otherProps?.disableClass?.find((f) => f?.index === index)?.disableTitle
                                : iconItem.title
                        }
                        icon={<i className={iconItem.icon + ' mdi font-18'}></i>}
                        className={`action-icon ${otherProps?.disableClass?.find((f) => f?.index === index)?.disable} ${
                            iconItem.className && iconItem.className
                        }`}
                        variant="secondary"
                        onClick={(e) => {
                            !e.target.closest('button').classList.contains('maximum-disabled') &&
                                actions(e, iconItem.icon);
                        }}
                    />
                );
            })}
        </ButtonGroup>
    );
};

export default OperationalBar;
