export const ScriptHandler = (scriptsArray) => {
    //Add js links
    scriptsArray.forEach((item, index) => {
        if (item) {
            // append script on container
            const script = document.createElement('script');
            script.src = item;
            script.key = index;
            script.async = true;
            document.getElementById('rootHtml').appendChild(script);
        }
    });
};

export const RemoveOldScriptAndLink = () => {
    let commonJsFile = document.querySelectorAll(`script[src*="/ui/js/"]`);
    let commonCssFile = document.querySelectorAll(`link[href*="/ui/css/"]`);
    for (let i = 0; i < commonJsFile.length; i++) {
        commonJsFile[i].remove();
    }
    for (let i = 0; i < commonCssFile.length; i++) {
        commonCssFile[i].remove();
    }
};
