// @flow
import { Card } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
// components
import { FormInput } from '../../../../components/form/input';
import Table from '../../../../components/table/Table';
import ButtonElement from '../../../../components/button/ButtonElement';
import { table as TableColumn } from '../../../../constants/table';
import { useModalContext } from '../../../../contexts/ModalContext';
import { modalActions } from '../../../../reducers/actions';
import { useDispatch, useSelector } from 'react-redux';
import service from '../../../../constants/service.constant';
import { dateTimeFormat } from '../../../../utility';

import {
    fundTypeList,
    fundTypeDelete,
    fundTypeUpdate,
    fundTypeExportDownload,
    fundTypeImport,
    fundTypeImportReset,
    fundTypeReset,
    fundTypeExportDownloadReset,
    templateFileDownload,
    templateFileReset,
} from '@ovrture/react-redux';
import { downloadFile } from '../../../../helpers/downloadFile/downloadFile';

const FundTypeModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [toggle, setToggle] = useState(false);
    const [data, setData] = useState([]);
    const [updatedAt, setUpdatedAt] = useState();
    const dispatch = useDispatch();
    const { list, deleted, update, imported, exported, orgFetch, loading, downloaded } = useSelector((state) => ({
        list: state?.fundType?.list,
        deleted: state?.fundType?.delete,
        update: state?.fundType?.update,
        orgFetch: state?.organization?.fetch,
        loading: state?.fundType?.loading,
        imported: state?.fundTypeImport?.import,
        exported: state?.reportDataExport?.fundTypeExportDownload,
        downloaded: state?.templateFile?.download,
    }));

    useEffect(() => {
        if (list?.data) {
            list?.data?.forEach((item) => {
                setUpdatedAt(dateTimeFormat(item?.colUpdatedAt));
            });
        }
    }, [list]);

    useEffect(() => {
        if (list?.data) setData(list.data);
        if (deleted) {
            deleted.code === service.accepted &&
                dispatch(fundTypeList({ orgId: orgFetch?.data?.id, sort: ['colFundTypeName'] }));
        }
        if (update) {
            dispatch(fundTypeReset());
            dispatch(fundTypeList({ orgId: orgFetch?.data?.id, sort: ['colFundTypeName'] }));
            //props?.hideModal();
        }
        if (imported?.code === service.bulkUpdated) {
            modalDispatch({ type: modalActions.CLOSE_MODAL });
            reset();
        }
        if (exported) {
            downloadFile(
                exported?.data,
                'application/zip',
                exported?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(fundTypeExportDownloadReset());
        }
        if (downloaded) {
            downloadFile(
                downloaded?.data,
                'application/zip',
                downloaded?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(templateFileReset());
        }
    }, [list, deleted, update, imported, exported, downloaded]);

    const { register, handleSubmit, reset } = useForm();

    const onUpload = (data) => {
        dispatch(
            fundTypeImport(
                { orgId: orgFetch?.data?.id },
                {
                    requestFile: data?.chooseFile[0],
                }
            )
        );
    };

    const FundTypes = ({ row }) => {
        return (
            <FormInput
                type="text"
                label="Type"
                className="mb-0"
                maxLength="75"
                id={'fundType' + row.id}
                defaultValue={row.original.colFundTypeName}
                onKeyUp={(e) => (row.original.colFundTypeName = e.target.value)}
                feedback="hide"
                floatinglabel="false"
            />
        );
    };
    const UploaderFundCode = ({ row }) => {
        return (
            <FormInput
                type="text"
                label="Code"
                className="mb-0"
                maxLength="10"
                id={'uploaderFundCode' + row.id}
                defaultValue={row.original.colFundKey}
                onKeyUp={(e) => (row.original.colFundKey = e.target.value)}
                feedback="hide"
                floatinglabel="false"
            />
        );
    };

    const Action = ({ row }) => {
        return (
            <ButtonElement
                icon={<i className="mdi mdi-delete"></i>}
                tooltip="Delete"
                className="action-icon hover-danger"
                onClick={() =>
                    dispatch(
                        fundTypeDelete({
                            orgId: row.original.fkOrgId,
                            pkFundTypeId: row.original.pkFundTypeId,
                        })
                    )
                }
            />
        );
    };

    return (
        <>
            {list?.data?.length > 0 ? (
                <p className="text-end custom-font mb-1">Last Edited: {updatedAt}</p>
            ) : (
                <p className="mb-3"></p>
            )}
            <Card>
                <Table
                    columns={TableColumn.editElent.reportData.fundType([FundTypes, UploaderFundCode, Action])}
                    data={data}
                    pageSizes={list?.data?.size}
                    pageNumber={list?.data?.number}
                    totalElements={list?.data?.totalElements}
                    totalPages={list?.data?.totalPages}
                    paginationDispatch={fundTypeList}
                    sizePerPageList={TableColumn.sizePerPageList(data)}
                    isSortable={true}
                    pagination={false}
                    isSearchable={false}
                    isFilter={false}
                    isSelectable={false}
                    loading={loading}
                    theadClass={TableColumn.table_light}
                    tableClass="fund-type-modal"
                    tableParentClass="height-fixed"
                    customPaginationProps={{
                        orgId: orgFetch?.data?.id,
                        sort: ['colFundTypeName'],
                    }}
                />
                <Card.Body className="pt-0">
                    <div className="button-list d-flex justify-content-center">
                        <ButtonElement
                            name="Add New"
                            variant="outline-primary"
                            onClick={() =>
                                modalDispatch({
                                    type: modalActions.OPEN_MODAL,
                                    payload: {
                                        modalAction: (e) =>
                                            modalDispatch({
                                                type: modalActions.CLOSE_MODAL,
                                            }),
                                        modalVariant: 'primary',
                                        modalDataKey: ['platformAdmin', 'newFundType'],
                                        data: {
                                            title: orgFetch?.data?.name + ' > Report Data > Fund Type > Add New',
                                            orgId: orgFetch?.data?.id,
                                        },
                                    },
                                })
                            }
                        />
                        <ButtonElement
                            name="Update"
                            variant="outline-primary"
                            disabled={data?.length <= 0 ? true : false}
                            onClick={() => {
                                let payload = [];
                                data.forEach((item) => {
                                    payload.push({
                                        colFundKey: item.colFundKey,
                                        colFundTypeName: item.colFundTypeName,
                                        pkFundTypeId: item.pkFundTypeId,
                                        orgId: item.fkOrgId,
                                    });
                                });
                                dispatch(fundTypeUpdate(payload));
                            }}
                        />
                        <ButtonElement name="Cancel" variant="light" type="button" onClick={() => props?.hideModal()} />
                    </div>
                </Card.Body>
            </Card>
            <form onSubmit={handleSubmit(onUpload)}>
                <Card>
                    <Card.Header>
                        <h4>Fund Type Uploader</h4>
                    </Card.Header>
                    <Card.Body>
                        <p>
                            <b className="text-primary">STEP 1:</b> Download template:
                        </p>
                        <div className="button-list mt-2 mb-2">
                            <ButtonElement
                                name="Download Excel Template"
                                variant="outline-primary"
                                type="button"
                                onClick={() => dispatch(templateFileDownload({ key: 'fund-type' }))}
                            />
                        </div>
                        <p>
                            <b className="text-primary">STEP 2:</b> Populate all required and relevant fields.
                        </p>
                        <p>
                            <b className="text-primary">STEP 3:</b> Locate, select and upload the completed spreadsheet
                            file:
                        </p>
                        <FormInput
                            type="file"
                            id="chooseFile"
                            register={register}
                            required={true}
                            onChange={(e) => setToggle(e.target.value === '' ? false : true)}
                        />
                        <div className="button-list mt-3">
                            <ButtonElement
                                name="Upload"
                                variant="outline-primary"
                                type="submit"
                                disabled={toggle ? false : true}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </form>
        </>
    );
};

export default FundTypeModal;
