// @flow
import React, { useEffect, useState } from 'react';
// components
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { useSelector, useDispatch } from 'react-redux';
import { assetFolderViewUpdate, cpUpdateFolder } from '@ovrture/react-redux';
import { folderRenameValidation } from '../../../components/form/validation';

const RenameFolderModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [toggle, setToggle] = useState(false);
    const {
        handleSubmit,
        register,
        watch,
        setValue,
        formState: { errors },
    } = useForm({ resolver: folderRenameValidation, mode: 'onBlur' });
    const values = watch();
    const dispatch = useDispatch();
    const { authInfo } = useSelector((state) => ({
        authInfo: state?.auth?.me,
    }));
    const onSubmit = (data) => {
        setToggle(false);
        const formData = {
            id: props?.data?.data?.id,
            fkPaletteId: props?.data?.data?.fkPaletteId,
            fkRoleId: props?.data?.data?.fkRoleId || authInfo?.role?.id,
            icon: 'mdi mdi-folder',
            name: data.renameFolder,
            parentId: props?.data?.data?.parent_id,
            permissionbyId: props?.data?.data?.permissionbyId,
            userId: props?.data?.data?.userId || authInfo?.id,
            brandId: props?.data?.data?.brandId,
            canBeDeleted: props?.data?.data?.canBeDeleted,
        };

        if (props?.data?.page === 'digital-asset') {
            formData.adminId = props?.data?.data?.adminId;
            formData.assetId = null;
            formData.assetRootNodeId = props?.data?.data?.assetRootNodeId;
            formData.fkOrgId = props?.data?.data?.fkOrgId;
            dispatch(assetFolderViewUpdate(formData));
        } else if (props?.data?.page === 'content') {
            formData.cpContentId = props?.data?.data?.cpContentId;
            formData.parentId = props?.data?.data?.parent_id;
            formData.icon = props?.data?.data?.icon;
            formData.fkCpRootNodeId = props?.data?.data?.fkCpRootNodeId || props?.data?.data?.id;
            dispatch(cpUpdateFolder(formData));
        }
        sessionStorage.setItem(process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY, 'Folder renamed.');
    };

    useEffect(() => {
        if (props.data.data) {
            setValue('renameFolder', props?.data?.data.text);
        }
    }, [props.data.data]);
    const textChangeHandle = (e) => {
        if (values?.renameFolder === e.target.value) {
            setToggle(false);
        } else {
            setValue('renameFolder', e.target.value);
            setToggle(true);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col lg={12}>
                    <FormInput
                        type="text"
                        id="renameFolder"
                        label="Folder Name *"
                        maxLength="30"
                        errors={errors}
                        register={register}
                        onChange={(e) => textChangeHandle(e)}
                    />
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement name="Rename" variant="primary" type="submit" disabled={!toggle ? true : false} />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </form>
    );
};
export default RenameFolderModal;
