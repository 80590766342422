// @flow
import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
// component
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { assePreview, assetReset } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';

const AssetPanelPreviewModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [assetUrl, setAssetUrl] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(assePreview({ assetFolderViewId: props?.data?.data?.id }));
    }, []);

    const { asset } = useSelector((state) => ({
        asset: state?.asset?.preview,
    }));

    useEffect(() => {
        if (asset?.data) {
            setAssetUrl(asset?.data);
            dispatch(assetReset());
        }
    }, [asset]);

    document.addEventListener('keydown', (e) => {
        if (
            (window?.location?.pathname === '/content-panel-edit' ||
                window?.location?.pathname?.includes('/site-edit') ||
                window?.location?.pathname?.includes('/report-edit')) &&
            (e.key == ' ' || e.code == 'Space' || e.keyCode == 32)
        ) {
            if (
                props?.data?.data?.icon === 'glyphicon glyphicon-image' ||
                props?.data?.data?.icon === 'glyphicon glyphicon-video'
            ) {
                let isModalOpen = document?.querySelector('.modal .modal-title')?.innerText;
                if (
                    window?.location?.pathname?.includes('/site-edit') ||
                    window?.location?.pathname?.includes('/report-edit')
                )
                    isModalOpen = document?.querySelector('.modal .asset-preview .modal-title')?.innerText;
                if (
                    (window?.location?.pathname === '/content-panel-edit' ||
                        window?.location?.pathname?.includes('/site-edit') ||
                        window?.location?.pathname?.includes('/report-edit')) &&
                    isModalOpen?.includes('Asset Preview')
                ) {
                    modalDispatch({
                        type: modalActions.OPEN_MODAL,
                        payload: {
                            modalVariant: 'primary',
                            modalDataKey: ['editor', 'replaceAssetEditor'],
                            data: {
                                ...props?.data?.assetPreviewProps,
                                contentData: props?.data?.contentData,
                                activeJstreePath: props?.data?.activeJstreePath,
                            },
                        },
                    });
                }
            }
        }
    });

    return (
        <>
            <Card>
                <Card.Body>
                    <div className="preview-box">
                        {assetUrl && (
                            <>
                                {props?.data?.data?.icon === 'glyphicon glyphicon-video' ? (
                                    <iframe
                                        className={assetUrl ? 'video-block' : ''}
                                        src={assetUrl}
                                        alt="preview"
                                        style={{
                                            display: assetUrl ? 'block' : 'none',
                                        }}></iframe>
                                ) : (
                                    <img src={assetUrl} alt="preview image" />
                                )}
                            </>
                        )}
                    </div>
                </Card.Body>
            </Card>
            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Back"
                    variant="outline-primary"
                    type="button"
                    onClick={() => {
                        props?.data?.setPreviewModalOpen && props.data.setPreviewModalOpen(false);
                        let isModalOpen = document?.querySelector('.modal .modal-title')?.innerText;
                        if (
                            window?.location?.pathname?.includes('/site-edit') ||
                            window?.location?.pathname?.includes('/report-edit')
                        )
                            isModalOpen = document?.querySelector('.modal .asset-preview .modal-title')?.innerText;
                        if (
                            (window?.location?.pathname === '/content-panel-edit' ||
                                window?.location?.pathname?.includes('/site-edit') ||
                                window?.location?.pathname?.includes('/report-edit')) &&
                            (props?.data?.data?.icon === 'glyphicon glyphicon-image' ||
                                props?.data?.data?.icon === 'glyphicon glyphicon-video') &&
                            isModalOpen?.includes('Asset Preview')
                        ) {
                            modalDispatch({
                                type: modalActions.OPEN_MODAL,
                                payload: {
                                    modalVariant: 'primary',
                                    modalDataKey: ['editor', 'replaceAssetEditor'],
                                    data: {
                                        ...props?.data?.assetPreviewProps,
                                        contentData: props?.data?.contentData,
                                        activeJstreePath: props?.data?.activeJstreePath,
                                    },
                                },
                            });
                        } else props.hideModal();
                    }}
                />
            </div>
        </>
    );
};

export default AssetPanelPreviewModal;
