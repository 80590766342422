import React from 'react';
import Slider from 'react-slick';
import ReactDOM from 'react-dom';

var settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    infinite: false,
    dots: false,
    //centerMode: true,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const SlickSlider = (props) => {
    const arr = [];
    for (const element of props?.html) {
        arr.push(element);
    }
    return props?.flag ? (
        <Slider {...settings}>
            {arr.map((a, i) => {
                return <div key={i} dangerouslySetInnerHTML={{ __html: a.outerHTML }}></div>;
            })}
        </Slider>
    ) : (
        <>
            {arr.map((a, i) => {
                return <div key={i} dangerouslySetInnerHTML={{ __html: a.outerHTML }}></div>;
            })}
        </>
    );
};

export const addSlickSliderTarget = (target, sliderReplacer) => {
    sliderReplacer?.length > 0 && ReactDOM.render(<SlickSlider html={sliderReplacer} flag={true} />, target);
};

export const addSlickSlider = (sliderReplacer) => {
    sliderReplacer?.length > 0 &&
        ReactDOM.render(
            <SlickSlider html={sliderReplacer} flag={true} />,
            document.querySelector('#photoGalleryCarousel')
        );
};

export const removeSlickSlider = () => {
    let sliderReplacer = document.querySelectorAll(
        '#photoGalleryCarousel .slick-slide:not(.slick-cloned) .frame_adjuster'
    );
    sliderReplacer?.length > 0 &&
        ReactDOM.render(<SlickSlider html={sliderReplacer} />, document.getElementById('photoGalleryCarousel'));
};

export const addSlickSliderMultiPanel = (photoGalleryList) => {
    for (let index = 0; index < photoGalleryList.length; index++) {
        const photoGallery = photoGalleryList[index];
        const frames = photoGallery?.querySelectorAll('.frame_adjuster');
        if (frames?.length > 0)
            ReactDOM.render(
                <SlickSlider html={frames} flag={true} />,
                document.querySelectorAll('#photoGalleryCarousel')[index]
            );
    }
};
