// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import ListItem from '../../../components/listItem';
import CheckboxSelect from '../../../components/listItem/CheckboxSelect';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { useSelector, useDispatch } from 'react-redux';
import { brandList } from '@ovrture/react-redux';
import { FormInput } from '../../../components/form/input';

const FilterByBrandModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [isSelectAll, setIsSelectAll] = useState();
    const [isSelectNone, setIsSelectNone] = useState();

    const dispatch = useDispatch();
    const { authInfo, brands, loading } = useSelector((state) => ({
        authInfo: state?.auth?.me,
        brands: state?.brand?.list,
        loading: state?.brand?.loading,
    }));

    useEffect(() => {
        dispatch(brandList({ isAnd: true, orgId: authInfo?.organaisationId, isUnpaged: true, sort: 'name,ASC' }));
    }, [authInfo]);

    const [list, setList] = useState([]);

    useEffect(() => {
        const selectedBrandIds =
            props?.data?.filterBrands && props?.data?.filterBrands?.length > 0
                ? props?.data?.filterBrands.map((d) => d.id)
                : props?.data?.data?.accessPermissionList.map((d) => d.brandId);

        const selectedItems = props?.data?.filterSelectedBrands
            ? props?.data?.filterSelectedBrands?.map((fs) => fs.id)
            : [];

        if (brands?.data?.content) {
            selectedBrandIds?.length === brands?.data?.content?.length && SelectAll();
            brands?.data?.content.forEach((brnd) => {
                brnd['title'] = brnd.name;
                if (
                    props?.data?.data?.accessPermissionList ||
                    (props?.data?.filterBrands && props?.data?.filterBrands?.length > 0)
                ) {
                    brnd['checked'] = selectedBrandIds?.includes(brnd.id) ? false : true;
                } else if (selectedItems?.length > 0) {
                    brnd['checked'] = selectedItems?.includes(brnd.id) ? true : false;
                }
            });
            setList(brands?.data?.content);
        }
    }, [brands]);
    const SelectAll = () => {
        setList(list.map((item) => ({ ...item, checked: true })));
        setIsSelectAll(true);
        setIsSelectNone(false);
    };
    const DeselectAll = () => {
        setList(list.map((item) => ({ ...item, checked: false })));
        setIsSelectAll(false);
        setIsSelectNone(true);
    };

    const handleSetFilter = () => {
        const selctedList = list.filter((f) => f.checked !== true);
        const selctedItems = list.filter((f) => f.checked === true);
        props?.data?.setFilterBrands(selctedList);
        props?.data?.setFilterSelectedBrands(selctedItems);
        props?.data?.handleFilterUpdate && props?.data?.handleFilterUpdate(selctedList);
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
        });
        sessionStorage.setItem(process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY, 'Broadcast filter set.');
    };
    return (
        <>
            <ListGroup className="listheader custom-list-header-color">
                <ListGroup.Item action className="active">
                    <Row>
                        <Col md={12}>
                            <FormInput
                                type="checkbox"
                                id="selectAccess"
                                label="Brand(s):"
                                className="mb-0"
                                checked={
                                    loading
                                        ? false
                                        : brands?.data?.content?.length === list.filter((f) => f.checked === true)?.length
                                }
                                onChange={(e) => (e.target.checked ? SelectAll() : DeselectAll())}
                            />
                        </Col>
                    </Row>
                </ListGroup.Item>
            </ListGroup>
            <ListGroup className="custom-listgroup filter-brand-list">
                {!loading &&
                    list.map((item, index) => {
                        return (
                            <ListItem key={index}>
                                <CheckboxSelect list={list} setList={setList} item={item} />
                                {item.title}
                            </ListItem>
                        );
                    })}
            </ListGroup>
            <div className="button-list mt-3 d-flex justify-content-center">
                <ButtonElement
                    name="Set Filter"
                    variant="primary"
                    type="button"
                    disabled={list.filter((item) => item.checked === true).length > 0 ? false : true}
                    onClick={() => {
                        handleSetFilter();
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default FilterByBrandModal;
