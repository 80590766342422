import $ from 'jquery';
import moment from 'moment';
import { RedirectTo } from '../../../routes/role/path';
import { icons } from '../operationalBar/slide';
import { ScriptHandler } from '../script/ScriptHandler';
import { getEditorStorage, removeEditorStorage } from '../storage';
import { removeTox } from '../tinymce/TinyMceInlineTextEditor';
// import ScriptHandler from '../script/ScriptHandler';
var type = window.location.pathname.includes(RedirectTo('/report-edit')) ? 'report' : 'site';

export const carouselMigration = () => {
    let carousels = document.querySelectorAll('.carousel');
    for (let i = 0; i < carousels.length; i++) {
        const carousel = carousels[i];
        addAttribute(carousel, 'data-ride', 'data-bs-ride');
        addAttribute(carousel, 'data-interval', 'data-bs-interval');
        const items = carousel.querySelectorAll('.carousel-inner .item');
        for (let j = 0; j < items.length; j++) {
            const item = items[j];
            item.classList.add('carousel-item');
        }
    }

    var carousel = document.getElementsByClassName('carousel');
    for (var i = 0; i < carousel.length; i++) {
        //Parent
        carousel[i].setAttribute('data-bs-keyboard', 'false');
        carousel[i].removeAttribute('data-ride');
        carousel[i].removeAttribute('data-interval');
        if (carousel[i].hasAttribute('data-bs-ride') === false) {
            carousel[i].setAttribute('data-bs-ride', 'carousel');
        }
        if (carousel[i].hasAttribute('data-bs-interval') === false) {
            carousel[i].setAttribute('data-bs-interval', 'false');
        }
        if (carousel[i].hasAttribute('data-bs-touch') === false) {
            carousel[i].setAttribute('data-bs-touch', 'false');
        }

        //Inner Item
        var items = carousel[i].querySelectorAll('.item');
        for (var j = 0; j < items.length; j++) {
            items[j].classList.remove('active');
            if (j === 0) {
                items[j].classList.add('carousel-item');
                items[j].classList.add('active');
            } else {
                items[j].classList.add('carousel-item');
            }
        }

        //Carousel-Indicators
        var indicators = carousel[i].querySelectorAll('.carousel-indicators li');

        if (indicators?.length < 2 && carousel[i]?.querySelectorAll('.select-fund')?.length > 0) {
            carousel[i].querySelectorAll('.select-fund')[0].style.display = 'none';
        }

        for (var l = 0; l < indicators.length; l++) {
            if (indicators[l].hasAttribute('data-bs-target') === false) {
                indicators[l].setAttribute('data-bs-target', indicators[l].getAttribute('data-target'));
            }
            if (indicators[l].hasAttribute('data-bs-slide-to') === false) {
                indicators[l].setAttribute('data-bs-slide-to', indicators[l].getAttribute('data-slide-to'));
            }
            indicators[l].removeAttribute('data-target');
            indicators[l].removeAttribute('data-slide-to');
        }

        //Carousel - Control;
        var carouselControlArr = new Array('.carousel-control.right', '.carousel-control.left');
        carousel[i]?.querySelector(carouselControlArr[0])?.classList.add('carousel-control-next');
        carousel[i]?.querySelector(carouselControlArr[1])?.classList.add('carousel-control-prev');
        if (carousel[i]?.querySelectorAll('.carousel-control')?.length === 2) {
            carousel[i].querySelector(carouselControlArr[0]).removeAttribute('href');
            carousel[i].querySelector(carouselControlArr[1]).removeAttribute('href');
        }
        resetArrow(carousel[i], 0);
    }
    indicatorsInitHandler(0);
    arrowInitHandler('init');
};

const addAttribute = (element, oldAtt, newAtt, flag, carouselId) => {
    if (element?.hasAttribute(oldAtt)) {
        const newVal = element.getAttribute(oldAtt);
        if (flag) {
            element.setAttribute(oldAtt, `#` + carouselId.id);
            element.setAttribute(newAtt, `#` + carouselId.id);
        } else {
            element.setAttribute(newAtt, newVal);
            element.removeAttribute(oldAtt);
        }
    }
};

export const broadcastMigration = () => {
    const broadcast = document.querySelector('.broadCastTemplpatesWrapper');
    addAttribute(broadcast?.querySelector('.broadcastBtnWrapper'), 'data-toggle', 'data-bs-toggle');
    addAttribute(broadcast?.querySelector('.broadcastBtnWrapper'), 'data-target', 'data-bs-target');
    broadcast?.querySelector('.broadCastCollapsible')?.classList.add('row');
    let openCloseIcon = broadcast.querySelector('.learn-more-text .close-banner');
    // removing the close icon in publish time
    if (openCloseIcon) {
        let removeCloseIcon = broadcast?.querySelector('.learn-more-text');
        removeCloseIcon.firstElementChild?.setAttribute('class', 'open-banner');
        removeCloseIcon.setAttribute('aria-label', 'Press enter to open the alert');
        broadcast?.querySelector('.broadCastCollapsible')?.classList.remove('show');
    }
    //Removing class show to keep it clase initially
    broadcast?.querySelector('.learn-more-text').addEventListener('click', iconHandler);
};

function iconHandler() {
    let classNm = this.firstElementChild.classList;
    if (classNm.contains('close-banner')) {
        this.setAttribute('aria-label', 'Press enter to open the alert');
        this.firstElementChild?.setAttribute('class', 'open-banner');
    } else {
        this.setAttribute('aria-label', 'Press enter to close the alert');
        this.firstElementChild?.setAttribute('class', 'close-banner');
    }
}

export const containerFluidFixes = (orgId) => {
    if (orgId === 7 || orgId === 17 || orgId === 1) {
    } else {
        var panel = document.getElementsByClassName('contentpanelname');
        for (var i = 0; i < panel.length; i++) {
            var parent = panel[i].parentElement;
            //top
            parent.classList.remove('container');
            parent.classList.add('container-fluid');
            //contentpanelname
            panel[i].classList.add('container');
            panel[i].classList.add('no-padding');
        }
    }
};
export const indicatorsInitHandler = (flag) => {
    var carousels = document.getElementsByClassName('carousel');
    for (var i = 0; i < carousels.length; i++) {
        let carousel = carousels[i];
        let items = carousel?.querySelectorAll('.carousel-inner .item');
        let carouselIndicator = carousel.querySelector('.carousel-indicators');
        if (items.length > 1) {
            //Hide learn more on single slide
            carouselIndicator.style.display = 'block';
            if (carousel?.querySelector('.learn-more-wrapper'))
                carousel.querySelector('.learn-more-wrapper').style.display = 'block';
            let indicators = '';
            for (let index = 0; index < items.length; index++) {
                let active = '';
                if (flag === 0) {
                    if (index === flag) active += 'active';
                    //if (items[index]?.classList?.contains('active')) active += 'active';
                } else if (flag) {
                    if (index === flag) active += 'active';
                } else {
                    if (index === items.length - 1) active += 'active';
                }
                indicators +=
                    '<li class="' +
                    active +
                    '" data-bs-target="#' +
                    carousel?.id +
                    '" data-bs-slide-to="' +
                    index +
                    '"> </li>';
            }
            carouselIndicator.innerHTML = indicators;
        } else {
            carouselIndicator.style.display = 'none';
            //Hide learn more on single slide
            if (carousel?.querySelector('.learn-more-wrapper'))
                carousel.querySelector('.learn-more-wrapper').style.display = 'none';
            carouselIndicator.innerHTML = '';
            if (carousel?.querySelector('.right-arrow')?.style?.display)
                carousel.querySelector('.right-arrow').style.display = 'none';
        }
    }
};

export const indicatorsSingleInitHandler = (flag) => {
    var carousels = document.getElementsByClassName('carousel');
    if (
        window.location.pathname.includes(RedirectTo('/site-edit')) ||
        window.location.pathname.includes(RedirectTo('/report-edit'))
    ) {
        let slideId = document.querySelector('#rootComponent').getAttribute('slidesecclass-id');
        carousels = document.querySelectorAll('#' + slideId + ' .carousel');
    }
    for (var i = 0; i < carousels.length; i++) {
        let carousel = carousels[i];
        let items = carousel?.querySelectorAll('.carousel-inner .item');
        let carouselIndicator = carousel.querySelector('.carousel-indicators');
        if (items.length > 1) {
            //Hide learn more on single slide
            carouselIndicator.style.display = 'block';
            if (carousel?.querySelector('.learn-more-wrapper'))
                carousel.querySelector('.learn-more-wrapper').style.display = 'block';
            let indicators = '';
            for (let index = 0; index < items.length; index++) {
                let active = '';
                if (flag === 0) {
                    if (index === flag) active += 'active';
                } else if (flag) {
                    if (index === flag) active += 'active';
                } else {
                    if (index === items.length - 1) active += 'active';
                }
                indicators +=
                    '<li class="' +
                    active +
                    '" data-bs-target="#' +
                    carousel?.id +
                    '" data-bs-slide-to="' +
                    index +
                    '"> </li>';
            }
            carouselIndicator.innerHTML = indicators;
        } else {
            carouselIndicator.style.display = 'none';
            //Hide learn more on single slide
            if (carousel?.querySelector('.learn-more-wrapper'))
                carousel.querySelector('.learn-more-wrapper').style.display = 'none';
            carouselIndicator.innerHTML = '';
            if (carousel?.querySelector('.right-arrow')?.style?.display)
                carousel.querySelector('.right-arrow').style.display = 'none';
        }
    }
};

export const arrowInitHandler = (init) => {
    var carousels = document.getElementsByClassName('carousel');
    for (var i = 0; i < carousels.length; i++) {
        let carousel = carousels[i];
        let prev = carousel.querySelector('.carousel-control.carousel-control-prev span');
        let next = carousel.querySelector('.carousel-control.carousel-control-next span');
        let items = carousel?.querySelectorAll('.carousel-inner .item');
        if (init === 'init') {
            for (let index = 0; index < items.length; index++) {
                const element = items[index];
                element.classList.remove('active');
                if (index === 0) {
                    element.classList.add('active');
                }
            }
        }
        if (items?.length > 1) {
            //Hide learn more on single slide
            if (carousel?.querySelector('.learn-more-wrapper'))
                carousel.querySelector('.learn-more-wrapper').style.display = 'block';
            const first = carousel.querySelector('.carousel-inner .item.active:nth-child(1)');
            const last = carousel.querySelector('.carousel-inner .item.active:nth-child(' + items.length + ')');
            if (first) {
                if (prev?.style?.display) prev.style.display = 'none';
                if (next?.style?.display) next.style.display = 'block';
                let buttons = document.querySelectorAll('.slide-operational-bar.operational-bar button');
                for (let index = 0; index < buttons.length; index++) {
                    const element = buttons[index];
                    element.classList.remove('disabled');
                    if (element?.title === icons.LEFT.title) {
                        element.classList.add('disabled');
                    }
                    if (items?.length > 5 && element?.title === icons.ADD.title) {
                        element.classList.add('disabled');
                    }
                }
            } else if (last) {
                if (prev?.style?.display) prev.style.display = 'block';
                if (next?.style?.display) next.style.display = 'none';
                let buttons = document.querySelectorAll('.slide-operational-bar.operational-bar button');
                for (let index = 0; index < buttons.length; index++) {
                    const element = buttons[index];
                    element.classList.remove('disabled');
                    if (element?.title === icons.RIGHT.title) {
                        element.classList.add('disabled');
                    }
                    if (items?.length > 5 && element?.title === icons.ADD.title) {
                        element.classList.add('disabled');
                    }
                }
            } else {
                if (prev?.style?.display) prev.style.display = 'block';
                if (next?.style?.display) next.style.display = 'block';
                let buttons = document.querySelectorAll('.slide-operational-bar.operational-bar button');
                for (let index = 0; index < buttons.length; index++) {
                    const element = buttons[index];
                    element.classList.remove('disabled');
                    if (items?.length > 5 && element?.title === icons.ADD.title) {
                        element.classList.add('disabled');
                    }
                }
            }
        } else {
            if (prev?.style?.display) prev.style.display = 'none';
            if (next?.style?.display) next.style.display = 'none';
            let buttons = document.querySelectorAll('.slide-operational-bar.operational-bar button');
            for (let index = 0; index < buttons.length; index++) {
                const element = buttons[index];
                element.classList.remove('disabled');
                if (element?.title === icons.RIGHT.title) {
                    element.classList.add('disabled');
                }
                if (element?.title === icons.LEFT.title) {
                    element.classList.add('disabled');
                }
                if (element?.title === icons.DELETE.title) {
                    element.classList.add('disabled');
                }
            }
        }
    }
};

(window.location.pathname === RedirectTo('/content-panel-edit') ||
    window.location.pathname.includes(RedirectTo('/site-edit')) ||
    window.location.pathname.includes(RedirectTo('/report-edit')) ||
    window.location.pathname.includes(RedirectTo('/reportdata-edit-impact')) ||
    window.location.pathname === RedirectTo('/content')) &&
    document?.addEventListener('click', (e) => {
        if (e.target.closest('.inline-textEditor')) {
            $('#' + e?.target?.closest('.carousel')?.id).off('keydown.bs.carousel');
        }
        let obj = e?.target?.closest('.carousel-control');
        if (obj?.classList?.contains('carousel-control-prev')) {
            //left
            let num = obj?.parentElement
                ?.querySelector('.carousel-indicators .active')
                .getAttribute('data-bs-slide-to');
            obj?.parentElement
                ?.querySelector('.carousel-indicators [data-bs-slide-to="' + (Number(num) - 1) + '"]')
                ?.click();
            resetArrow(obj?.parentElement, Number(num) - 1);
        } else if (obj?.classList?.contains('carousel-control-next')) {
            //right
            let num = obj?.parentElement
                ?.querySelector('.carousel-indicators .active')
                .getAttribute('data-bs-slide-to');
            obj?.parentElement
                ?.querySelector('.carousel-indicators [data-bs-slide-to="' + (Number(num) + 1) + '"]')
                ?.click();
            resetArrow(obj?.parentElement, Number(num) + 1);
        }
        if (e?.target?.tagName === 'LI') {
            resetArrow(
                e?.target?.closest('.carousel-indicators').parentElement,
                Number(e?.target.getAttribute('data-bs-slide-to'))
            );
        }
        if (e?.target?.innerText === 'Learn More') {
            let objParentElement = e?.target?.closest('.carousel');
            objParentElement.querySelector('.carousel-control-next').click();
        }
    });

const resetArrow = (carousel, num) => {
    if (
        carousel?.querySelector('.carousel-control-prev span') &&
        carousel.querySelector('.carousel-control-next span')
    ) {
        removeTox();
        carousel.querySelector('.carousel-control-prev span').style.display = 'block';
        carousel.querySelector('.carousel-control-next span').style.display = 'block';
        let items = carousel?.querySelectorAll('.carousel-inner .item');
        if (num === 0) {
            carousel.querySelector('.carousel-control-prev span').style.display = 'none';
            let buttons = document.querySelectorAll('.slide-operational-bar.operational-bar button');
            for (let index = 0; index < buttons.length; index++) {
                const element = buttons[index];
                element.classList.remove('disabled');
                if (element?.title === icons.LEFT.title) {
                    element.classList.add('disabled');
                }
                if (items?.length > 5 && element?.title === icons.ADD.title) {
                    element.classList.add('disabled');
                }
            }
        } else if (num === carousel?.querySelectorAll('.carousel-indicators li').length - 1) {
            carousel.querySelector('.carousel-control-next span').style.display = 'none';
            let buttons = document.querySelectorAll('.slide-operational-bar.operational-bar button');
            for (let index = 0; index < buttons.length; index++) {
                const element = buttons[index];
                element.classList.remove('disabled');
                if (element?.title === icons.RIGHT.title) {
                    element.classList.add('disabled');
                }
                if (items?.length > 5 && element?.title === icons.ADD.title) {
                    element.classList.add('disabled');
                }
            }
        } else {
            let buttons = document.querySelectorAll('.slide-operational-bar.operational-bar button');
            for (let index = 0; index < buttons.length; index++) {
                const element = buttons[index];
                element.classList.remove('disabled');
                if (items?.length > 5 && element?.title === icons.ADD.title) {
                    element.classList.add('disabled');
                }
            }
        }
    }
};

export const updateCarouselId = () => {
    let carouselId = '';
    let carousel = document?.querySelectorAll('.slideSecClass .carousel');
    for (let index = 0; index < carousel.length; index++) {
        const element = carousel[index];
        let array = document.querySelectorAll('#' + element?.id);
        for (let i = 0; i < array.length; i++) {
            const item = array[i];
            if (carouselId === item?.id) {
                let newCarouselId = 'myCarousel' + Number(item?.id.replace('myCarousel', '')) + index + i;
                item.id = newCarouselId;
                //carousel-indicators
                item?.querySelectorAll('.carousel-indicators li')?.forEach((indicator) => {
                    indicator.setAttribute('data-bs-target', '#' + newCarouselId);
                });
                carouselId = newCarouselId;
            } else {
                carouselId = item?.id;
            }
        }
    }
};

export const menuActionHandler = () => {
    var ele = document.querySelector('#IdHeader .dropdown');
    if (!ele.classList.contains('open')) {
        ele.classList.add('open');
    } else {
        ele.classList.remove('open');
    }
    $('.menu-list').slideToggle('fast', function () {
        if (!ele.classList.contains('open')) {
            $('#IdHeader .dropdown .glyphicon').remove('glyphicon-remove');
            $('#IdHeader .dropdown .glyphicon').add('glyphicon-menu-hamburger');
        } else {
            $('#IdHeader .dropdown .glyphicon').add('glyphicon-remove');
            $('#IdHeader .dropdown .glyphicon').remove('glyphicon-menu-hamburger');
        }
    });
};

export const setupContactInfo = (data, systemAdminInfo) => {
    setElementValue(
        document?.querySelector('.CC-contact-image'),
        data?.tblRecords?.user?.contactDatas[0]?.profileImage,
        'src'
    );
    setElementValue(document?.querySelector('[sectionid="contact2"]'), data?.tblRecords?.user?.colFullName);
    setElementValue(
        document?.querySelector('#bioUpClassYear'),
        data?.tblRecords?.user?.contactDatas[0]?.classYear
            ? ",'" + data?.tblRecords?.user?.contactDatas[0]?.classYear.slice(-2)
            : ''
    );
    setElementValue(
        document?.querySelector('[sectionid="contact4"]'),
        data?.tblRecords?.user?.contactDatas[0]?.vcfPath,
        'href'
    );
    setElementValue(
        document?.querySelector('[sectionid="contact3"]'),
        data?.tblRecords?.user?.contactDatas[0]?.bioTitle
    );
    setElementValue(
        document?.querySelector('[sectionid="contact9"] span'),
        data?.tblRecords?.user?.contactDatas[0]?.bioDescrip,
        'innerHTML'
    );
    setElementValue(
        document?.querySelector('[sectionid="contact8"] .c-info'),
        data?.tblRecords?.user?.contactDatas[0]?.mobNum,
        null,
        null,
        !data?.tblRecords?.user?.contactDatas[0]?.mobNum
    );
    setElementValue(
        document?.querySelector('[sectionid="contact7"] .c-info'),
        data?.tblRecords?.user?.contactDatas[0]?.faxNum,
        null,
        null,
        !data?.tblRecords?.user?.contactDatas[0]?.faxNum
    );
    setElementValue(
        document?.querySelector('.contact-page-wrapper .email .c-info a'),
        'mailto:' + data?.tblRecords?.user?.contactDatas[0]?.bioEmail,
        'href'
    );
    setElementValue(
        document?.querySelector('.contact-page-wrapper .email .c-info a'),
        data?.tblRecords?.user?.contactDatas[0]?.bioEmail
    );
    // Media
    setElementValue(
        document?.querySelector('.contact-page-wrapper #bioUpfacUrl'),
        data?.tblRecords?.user?.contactDatas[0]?.facUrl
            ? data?.tblRecords?.user?.contactDatas[0]?.facUrl
            : systemAdminInfo?.colFacebookUrl,
        'media'
    );
    setElementValue(
        document?.querySelector('.contact-page-wrapper #bioUpwebUrl'),
        data?.tblRecords?.user?.contactDatas[0]?.webUrl
            ? data?.tblRecords?.user?.contactDatas[0]?.webUrl
            : systemAdminInfo?.colWebAddress,
        'media'
    );
    setElementValue(
        document?.querySelector('.contact-page-wrapper #bioUptwtUrl'),
        data?.tblRecords?.user?.contactDatas[0]?.twtUrl
            ? data?.tblRecords?.user?.contactDatas[0]?.twtUrl
            : systemAdminInfo?.colTwitterUrl,
        'media',
        true
    );
    setElementValue(
        document?.querySelector('.contact-page-wrapper #bioUpinstaUrl'),
        data?.tblRecords?.user?.contactDatas[0]?.colInstagramUrl
            ? data?.tblRecords?.user?.contactDatas[0]?.colInstagramUrl
            : systemAdminInfo?.colInstagramUrl,
        'media'
    );
};

export const setupFooterInfo = (data, systemAdminInfo) => {
    //Address info details
    if (
        !data?.tblRecords?.user?.contactDatas[0]?.colAddressName &&
        !data?.tblRecords?.user?.contactDatas[0]?.colAddress1 &&
        !data?.tblRecords?.user?.contactDatas[0]?.colAddress2 &&
        !data?.tblRecords?.user?.contactDatas[0]?.colAddress3 &&
        !data?.tblRecords?.user?.contactDatas[0]?.colAddress4 &&
        !data?.tblRecords?.user?.contactDatas[0]?.colCity &&
        !data?.tblRecords?.user?.contactDatas[0]?.colStateProvince &&
        !data?.tblRecords?.user?.contactDatas[0]?.colZipPostalCode &&
        !data?.tblRecords?.user?.contactDatas[0]?.colCountry
    ) {
        setElementValue(
            document?.querySelector('footer [sectionid="ccAddressName"]'),
            systemAdminInfo?.colAddress1,
            'replaceOrHide'
        );
        setElementValue(
            document?.querySelector('footer [sectionid="ccAddress1"]'),
            systemAdminInfo?.colAddress2,
            'replaceOrHide'
        );
        setElementValue(
            document?.querySelector('footer [sectionid="ccAddress2"]'),
            systemAdminInfo?.colAddress3,
            'replaceOrHide'
        );
        setElementValue(
            document?.querySelector('footer [sectionid="ccAddress3"]'),
            systemAdminInfo?.colAddress4,
            'replaceOrHide'
        );
        setElementValue(
            document?.querySelector('footer [sectionid="ccCity"]'),
            systemAdminInfo?.colCity,
            'replaceOrHide'
        );
        setElementValue(
            document?.querySelector('footer [sectionid="ccState"]'),
            systemAdminInfo?.colStateProvince,
            'replaceOrHide'
        );
        setElementValue(
            document?.querySelector('footer [sectionid="ccZipCode"]'),
            systemAdminInfo?.colZipPostalCode,
            'replaceOrHide'
        );
    } else {
        setElementValue(
            document?.querySelector('footer [sectionid="ccAddressName"]'),
            data?.tblRecords?.user?.contactDatas[0]?.colAddressName,
            'replaceOrHide'
        );
        setElementValue(
            document?.querySelector('footer [sectionid="ccAddress1"]'),
            data?.tblRecords?.user?.contactDatas[0]?.colAddress1,
            'replaceOrHide'
        );
        setElementValue(
            document?.querySelector('footer [sectionid="ccAddress2"]'),
            data?.tblRecords?.user?.contactDatas[0]?.colAddress2,
            'replaceOrHide'
        );
        setElementValue(
            document?.querySelector('footer [sectionid="ccAddress3"]'),
            data?.tblRecords?.user?.contactDatas[0]?.colAddress3,
            'replaceOrHide'
        );
        setElementValue(
            document?.querySelector('footer [sectionid="ccAddress4"]'),
            data?.tblRecords?.user?.contactDatas[0]?.colAddress4,
            'replaceOrHide'
        );
        setElementValue(
            document?.querySelector('footer [sectionid="ccCity"]'),
            data?.tblRecords?.user?.contactDatas[0]?.colCity,
            'replaceOrHide'
        );
        setElementValue(
            document?.querySelector('footer [sectionid="ccState"]'),
            data?.tblRecords?.user?.contactDatas[0]?.colStateProvince,
            'replaceOrHide'
        );
        setElementValue(
            document?.querySelector('footer [sectionid="ccZipCode"]'),
            data?.tblRecords?.user?.contactDatas[0]?.colZipPostalCode,
            'replaceOrHide'
        );
    }

    // Contact number details
    setElementValue(
        document?.querySelector('footer [sectionid="contact8"]'),
        data?.tblRecords?.user?.contactDatas[0]?.mobNum
            ? data?.tblRecords?.user?.contactDatas[0]?.mobNum
            : systemAdminInfo?.colContactNo,
        'replaceOrHide'
    );
    // Social Media icons
    setElementValue(
        document?.querySelector('footer #bioUpfacUrl'),
        data?.tblRecords?.user?.contactDatas[0]?.facUrl
            ? data?.tblRecords?.user?.contactDatas[0]?.facUrl
            : systemAdminInfo?.colFacebookUrl,
        'media'
    );
    setElementValue(
        document?.querySelector('footer #bioUplinkUrl'),
        data?.tblRecords?.user?.contactDatas[0]?.linkUrl
            ? data?.tblRecords?.user?.contactDatas[0]?.linkUrl
            : systemAdminInfo?.colLinkedinUrl,
        'media'
    );
    setElementValue(
        document?.querySelector('footer #bioUptwtUrl'),
        data?.tblRecords?.user?.contactDatas[0]?.twtUrl
            ? data?.tblRecords?.user?.contactDatas[0]?.twtUrl
            : systemAdminInfo?.colTwitterUrl,
        'media',
        true
    );
    setElementValue(
        document?.querySelector('footer #bioUpinstaUrl'),
        data?.tblRecords?.user?.contactDatas[0]?.colInstagramUrl
            ? data?.tblRecords?.user?.contactDatas[0]?.colInstagramUrl
            : systemAdminInfo?.colInstagramUrl,
        'media'
    );
    setElementValue(
        document?.querySelector('footer #bioUpwebUrl'),
        data?.tblRecords?.user?.contactDatas[0]?.webUrl
            ? data?.tblRecords?.user?.contactDatas[0]?.webUrl
            : systemAdminInfo?.colWebAddress,
        'media'
    );
};

export const setElementValue = (element, value, flag, isTwitter, hideParent) => {
    if (flag && element) {
        if (flag === 'src') value ? element.setAttribute('src', value) : (element.style.display = 'none');
        if (flag === 'href') value ? element.setAttribute('href', value) : (element.style.display = 'none');
        if (flag === 'media') {
            element.removeAttribute('onclick', value);
            if (value) {
                element.setAttribute('target_href', isTwitter ? 'https://twitter.com/' + value : value);
                // element.setAttribute(
                //     'target_href',
                //     checkUrl === 'twtUrl' && !value.includes('https')
                //         ? 'https://twitter.com/' + value
                //         : value
                //         ? checkUrl === 'fbUrl' && !value.includes('https')
                //             ? 'https://www.facebook.com/' + value
                //             : value
                //         : value
                // );
                element?.addEventListener('click', (e) => {
                    window.open(element.getAttribute('target_href'), '_blank');
                });
                setTimeout(() => (element.style.display = 'block'), 1000);
            } else element.style.display = 'none';
        }
        if (flag === 'replaceOrHide') value ? (element.innerHTML = value) : (element.style.display = 'none');
        if (flag === 'innerHTML') element.innerHTML = value;
        if (flag === 'style') element.setAttribute('style', value);
    } else if (element) {
        element.innerText = value;
        if (hideParent) element.parentElement.style.display = 'none';
    }
};

export const setupCommonInfo = (systemAdminInfo, recordInfo) => {
    setElementValue(
        document?.querySelector('span.copyrightYearReplacer'),
        systemAdminInfo?.colCopyrightYear,
        'innerHTML'
    );
    setElementValue(
        document?.querySelector('div.greetingLine6.GS_6.cc-title'),
        recordInfo?.tblRecords?.user?.contactDatas[0]?.bioTitle,
        'innerHTML'
    );
    setElementValue(
        document?.querySelector('div.GS_4.cc-name'),
        recordInfo?.tblRecords?.user?.colFullName,
        'innerHTML'
    );
};

export const scriptTagHandler = (orgId) => {
    let scriptArray = [
        `${process?.env?.REACT_APP_S3_URL}/Org${orgId}/assets/js/common.js`,
        `${process?.env?.REACT_APP_S3_URL}/Org${orgId}/assets/js/editor.js`,
        // `https://common-assets-relay.s3.us-west-2.amazonaws.com/demo/react-test/dev-test/shared/js/dev-test/common.js`,
        // `https://common-assets-relay.s3.us-west-2.amazonaws.com/demo/react-test/dev-test/shared/js/dev-test/editor.js`,
    ];
    ScriptHandler(scriptArray);
};

export const getHtmlString = (htmlData) => {
    let doc = new DOMParser().parseFromString(htmlData, 'text/html');
    let newDoc = document.querySelector('#rootHtml #page-container');
    doc.getElementById('page-container')?.replaceWith(newDoc);
    return doc.querySelector('html')?.outerHTML;
    //return JSON.stringify(document.querySelector('#rootHtml').innerHTML);
};
export const disable_ele_items = (ele, left_cond, right_cond, del_cond) => {
    left_cond
        ? $(ele).find('.move-update-left').addClass('pwdon0').parent().addClass('disabledBackground')
        : $(ele).find('.move-update-left').removeClass('pwdon0').parent().removeClass('disabledBackground');

    right_cond
        ? $(ele).find('.move-update-right').addClass('pwdon0').parent().addClass('disabledBackground')
        : $(ele).find('.move-update-right').removeClass('pwdon0').parent().removeClass('disabledBackground');

    del_cond
        ? $(ele).find('.delete-report-update').addClass('pwdon0').parent().addClass('disabledBackground')
        : $(ele).find('.delete-report-update').removeClass('pwdon0').parent().removeClass('disabledBackground');
};
export const updateOperationBarButtonED = () => {
    if ($('.report-updates-tab').length > 0) {
        var total_updates = $('.report-updates-tab .panelDiv').length;
        $('.report-updates-tab .panelDiv')
            .find('.move-update-left')
            .removeClass('pwdon0')
            .parent()
            .removeClass('disabledBackground');
        $('.report-updates-tab .panelDiv')
            .find('.move-update-right')
            .removeClass('pwdon0')
            .parent()
            .removeClass('disabledBackground');
        $('.report-updates-tab .panelDiv')
            .find('.delete-report-update')
            .removeClass('pwdon0')
            .parent()
            .removeClass('disabledBackground');
        var first_ele = $('.report-updates-tab .panelDiv')[0];
        //switch case based on no of updates
        switch (total_updates) {
            case 1:
                disable_ele_items(first_ele, true, true, true);
                break;
            case 2:
                console.log(2);
                disable_ele_items(first_ele, true, false, false);
                var last_ele = $('.report-updates-tab .panelDiv')[total_updates - 1];
                disable_ele_items(last_ele, false, true, false);
                break;
            default:
                console.log(3);
                disable_ele_items(first_ele, true, false, false);
                var last_ele = $('.report-updates-tab .panelDiv')[total_updates - 1];
                disable_ele_items(last_ele, false, true, false);
        }
        $('.report-updates-tab ul li.active .panelDiv')
            .find('.delete-report-update')
            .addClass('pwdon0')
            .parent()
            .addClass('disabledBackground');
    }
};

// fire event if click is outside of menu & close the menu list
window.onclick = function (event) {
    if (event.code !== 'Enter' || event.keyCode !== 13) {
        if (
            !event.target.classList.contains('dropdown-toggle') &&
            !event.target.classList.contains('menu_text') &&
            !event.target.classList.contains('bars')
        ) {
            $('.menu-list').slideUp('fast');
            if (document.querySelector('#IdHeader .dropdown')?.classList.length > 0)
                document.querySelector('#IdHeader .dropdown').classList.remove('open');
        } else {
            document.querySelector('.dropdown-menu').style.display = 'block';
        }
    }
};

/* Purpose: To hide select fund option in case of single fund [common js] */
//Example: hideSelectFundLib(["#FUND_DESCRIPTION","#FUND_SOA","#FUND_PERFORMANCE","#FUND_IMPACT","#FUND_PERFORMANCE_DISTRIBUTION","#FUND_PERFORMANCE_ENDOWMENT"])
export const hideSelectFundLib = (panelArr) => {
    try {
        if (panelArr.length >= 0) {
            var i = 0;
            $.each(panelArr, function () {
                if ($(panelArr[i] + ' .carousel .carousel-inner .item').length === 1) {
                    $(panelArr[i] + ' .carousel .report-carousel-indicators .fund-footer-wrapper').hide();

                    /*For Rice University : Adding a specific class to Panel Header for css implementation*/
                    // if (orgId === 'Org_72') {
                    //     $(panelArr[i] + ' .carousel .carousel-inner .panelHeader').addClass(
                    //         'changePanelHeaderPosition'
                    //     );
                    // }
                }
                i++;
            });
        }
    } catch (err) {
        console.log(err);
    }
};

export const swapNode = (nodeA, nodeB) => {
    const parentA = nodeA.parentNode;
    const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;

    // Move `nodeA` to before the `nodeB`
    nodeB.parentNode.insertBefore(nodeA, nodeB);

    // Move `nodeB` to before the sibling of `nodeA`
    parentA.insertBefore(nodeB, siblingA);
};

export const editorNavUpdate = () => {
    const path = window.location.pathname;
    return !(path.includes('/site-edit') || path.includes('/report-edit'));
};

/******* FUNCTIONS CALLED IN MULTIPLE ACTIONS ON EDITORS/OUTPUT *******/
// Functions which is called in multiple actions --> editor, output, add panel, shift panels
export const addContentPanelJSInitilization = () => {
    util.year_rendering();
    StanadrdPanel.StdAutoAlteringBackgroudColor();
    util.amount_conversion('.LIFETIME_GIVING .lifeTimeGivingAmountOfRecord');
    util.amount_conversion('.FY_GIVING .fyGivingAmountOfRecord');
    util.amount_conversion('.FY_GIFT .fyLastGiftAmountOfRecord');
    util.amount_conversion('.FY_CURRENT_USE .fyCurrentUseAmountOfRecord');
    util.removeInitialCharacter('.LIFETIME_GIVING .lifeTimeGivingDateOfRecord', 6);
    util.removeInitialCharacter('.FY_GIVING .fyGivingDateOfRecord', 6);
    util.removeInitialCharacter('.FY_GIFT .fyLastGiftDateOfRecord', 6);
    util.removeInitialCharacter('.FY_CURRENT_USE .fyCurrentUseDateOfRecord', 6);
};

export const deleteContentPanelJSInitilization = () => {
    StanadrdPanel.StdAutoAlteringBackgroudColor();
};

export const upDownContentPanelJSInitilization = () => {
    StanadrdPanel.StdAutoAlteringBackgroudColor();
};

var util = {
    // Report Year rendering
    year_rendering: function () {
        if (type === 'report') {
            var fY = $('#fiscalYear').val();
            if ($('#FY_GIVING').length > 0 || $('#FY_CURRENT_USE').length > 0) {
                $('.year').html(fY);
            }
        } else {
            $('.year').html('FY');
        }
    },
    // Description : For Lifetime and Fy Giving Panel - if value not exist place
    amount_conversion: function (selected_class) {
        if ($(selected_class)[0]) {
            /* EXISTS (not undefined) */
            var element = $(selected_class).html().trim();
            if (element != '$X,XXX,XXX') {
                var actual_value = element.replace(/[$,]/g, '');
                var value = parseFloat(actual_value).toFixed(2);
                if (isNaN(value)) {
                    var value = 'X,XXX,XXX';
                }
                var n = value.toString().split('.');
                n[0] = n[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                var final_value = n.join('.');
                $(selected_class).html('$' + final_value);
            }
        }
    },
    // Description : For Lifetime and Fy Giving Panel - remove intial character
    removeInitialCharacter: function (stringClass, removeCharacterCount) {
        if ($(stringClass)[0]) {
            /* EXISTS (not undefined) */
            var element = $(stringClass).html().trim();
            if ('Total' === element.substring(0, 5)) {
                element = element.substring(removeCharacterCount);
                $(stringClass).html(element);
            }
        } else {
        }
    },
};

var StanadrdPanel = {
    // REMOVE ALL CLASSES RELATED TO bgColor FROM ALL STANDARD PANEL
    removeAllBbColor: function () {
        $('.standard-template').each(function (i) {
            var match = $(this)
                .attr('class')
                .match(/\bbg\S+/g);
            if (match != null) {
                var className = match;
                $(this).removeClass(className.toString());
            }
        });
    },
    //ADD bgColor CLASS IN ALL STANDARD PANEL
    StdAutoAlteringBackgroudColor: function () {
        //CALL FUNCTION TO REMOVE bgColor CLASS
        StanadrdPanel.removeAllBbColor();
        $('.standard-template').each(function (i) {
            $(this).addClass('bgColor' + i);
        });
    },
};

export const fundPanelSequenceDetail = (skipRemoveRDTPanel) => {
    const activeTab = document?.querySelector('.report-update-data.active');
    const fundPanelSequenceDetails = activeTab
        ? activeTab?.getAttribute('data-fund_panel_seq')
        : document?.querySelectorAll('#fundPanelSequenceDetail');

    if (!activeTab && fundPanelSequenceDetails?.length > 1)
        for (let index = 1; index < fundPanelSequenceDetails.length; index++) fundPanelSequenceDetails[index].remove();

    //let fundPanelSequenceDetail = JSON.parse(activeTab ? fundPanelSequenceDetails : fundPanelSequenceDetails[0].value);
    //if (Object?.keys(fundPanelSequenceDetail)?.length === 0) {
    let fundPanelSequenceDetail = {
        fund_description: '',
        fund_performance_distribution: '',
        fund_performance_endowment: '',
        fund_soa: '',
        fund_performance: '',
        fund_impact: '',
    };
    //}
    const list = document.querySelectorAll('.slideSecClass');
    let FUND_DESCRIPTION = null;
    let FUND_SOA = null;
    let FUND_PERFORMANCE = null;
    let FUND_PERFORMANCE_DISTRIBUTION = null;
    let FUND_PERFORMANCE_ENDOWMENT = null;
    let FUND_IMPACT = null;
    for (let index = 0; index < list.length; index++) {
        let indexdata = findIndexDom(list[index], index, '#FUND_DESCRIPTION');
        if (indexdata !== null && indexdata !== undefined && FUND_DESCRIPTION === null) {
            FUND_DESCRIPTION = indexdata;
        }
        indexdata = findIndexDom(list[index], index, '#FUND_SOA');
        if (indexdata !== null && indexdata !== undefined && FUND_SOA === null) {
            FUND_SOA = indexdata;
        }
        indexdata = findIndexDom(list[index], index, '#FUND_PERFORMANCE');
        if (indexdata !== null && indexdata !== undefined && FUND_PERFORMANCE === null) {
            FUND_PERFORMANCE = indexdata;
        }
        indexdata = findIndexDom(list[index], index, '#FUND_PERFORMANCE_DISTRIBUTION');
        if (indexdata !== null && indexdata !== undefined && FUND_PERFORMANCE_DISTRIBUTION === null) {
            FUND_PERFORMANCE_DISTRIBUTION = indexdata;
        }
        indexdata = findIndexDom(list[index], index, '#FUND_PERFORMANCE_ENDOWMENT');
        if (indexdata !== null && indexdata !== undefined && FUND_PERFORMANCE_ENDOWMENT === null) {
            FUND_PERFORMANCE_ENDOWMENT = indexdata;
        }
        indexdata = findIndexDom(list[index], index, '#FUND_IMPACT');
        if (indexdata !== null && indexdata !== undefined && FUND_IMPACT === null) {
            FUND_IMPACT = indexdata;
        }
    }
    setFundPanelSequence(FUND_DESCRIPTION, fundPanelSequenceDetail, 'fund_description');
    setFundPanelSequence(FUND_PERFORMANCE_DISTRIBUTION, fundPanelSequenceDetail, 'fund_performance_distribution');
    setFundPanelSequence(FUND_PERFORMANCE_ENDOWMENT, fundPanelSequenceDetail, 'fund_performance_endowment');
    setFundPanelSequence(FUND_SOA, fundPanelSequenceDetail, 'fund_soa');
    setFundPanelSequence(FUND_PERFORMANCE, fundPanelSequenceDetail, 'fund_performance');
    setFundPanelSequence(FUND_IMPACT, fundPanelSequenceDetail, 'fund_impact');
    if (activeTab) {
        activeTab.setAttribute('data-fund_panel_seq', JSON.stringify(fundPanelSequenceDetail));
    } else {
        fundPanelSequenceDetails[0].textContent = JSON.stringify(fundPanelSequenceDetail);
    }
    if (!skipRemoveRDTPanel) {
        document?.querySelectorAll('.slideSecClass')?.forEach((element) => {
            if (element?.querySelectorAll('.report-cp-panel')?.length > 0 || element.querySelector('div') === null) {
                element?.remove();
            }
        });
    }
    //Update slide id
    let array = document?.querySelectorAll('.slideSecClass');
    for (let index = 0; index < array.length; index++) array[index].id = 'slides_' + (index + 1);
};

const setFundPanelSequence = (panel, obj, fund) => {
    if (panel !== null && panel !== undefined) obj[fund] = panel;
    else delete obj[fund];
};

const findIndexDom = (element, index, selector) => {
    if (element.querySelectorAll(selector)?.length > 0) return index;
    else return null;
};

export const removeStdLastItemSlide = () => {
    let items = document.querySelectorAll('.item.lastItem');
    for (let index = 0; index < items.length; index++) {
        items[index].remove();
    }
};

export const chartgraphs = (idName, divClassName) => {
    try {
        window.google.charts.load('45', {
            packages: ['corechart'],
        });
        // color Set array
        var axisColorV = getEditorStorage('chartGraphDetails')?.axisColorV;
        var areaColorV = getEditorStorage('chartGraphDetails')?.areaColorV;
        if (idName == 'chartsDetails') {
            var colorSetArray = getEditorStorage('chartGraphDetails')?.performanceChartColorArray;
            var vaxisFormat = getEditorStorage('chartGraphDetails')?.performanceChartAxisFormat;
        } else if (idName == 'endowmentChartsDetails') {
            var colorSetArray = getEditorStorage('chartGraphDetails')?.endowmentChartColorArray;
            var vaxisFormat = getEditorStorage('chartGraphDetails')?.endowmentChartAxisFormat;
        } else {
            var colorSetArray = getEditorStorage('chartGraphDetails')?.distributionChartColorArray;
            var vaxisFormat = getEditorStorage('chartGraphDetails')?.distributionChartAxisFormat;
        }
        // Get value for iterating charts
        //This if condition is applied for Barnard - to remove the $ symbold from the tooltip on graph points
        if (idName == 'chartsDetails') {
            var temp_arr = JSON.parse(document.getElementById(idName).value);
            var result = [];
            for (var i in temp_arr) {
                result.push([i, temp_arr[i]]);
                var temp = result[i][1];
                for (var k = 0; k < temp.length; k++) {
                    var target = temp[k][2];
                    var splited_str = target.split(' ');
                    var temp2 = splited_str[0].replace('$', '');
                    var new_str = temp2 + ' ' + splited_str[1] + ' ' + splited_str[2];
                    temp[k][2] = new_str;
                }
                var final_val = temp_arr[i];
            }
            var jsonObj_string = temp_arr;
        } else {
            var jsonObj_string = JSON.parse(document.getElementById(idName).value);
        }
        var recordID = 0;
        for (recordID in jsonObj_string) {
            window.google.charts.setOnLoadCallback(drawCurveTypes);
            recordID = recordID + 1;
        }
        recordID = 0;

        function drawCurveTypes() {
            try {
                if (!document.body.contains(document.getElementsByClassName(divClassName)[recordID])) return;
                var data = new window.google.visualization.DataTable();
                data.addColumn('string', 'X');
                data.addColumn('number');
                data.addColumn({
                    type: 'string',
                    role: 'tooltip',
                    p: {
                        html: true,
                    },
                });
                data.addRows(jsonObj_string[recordID]);
                var colorSetIndex =
                    document
                        .getElementsByClassName(divClassName)
                        [recordID].closest('div.colorSet')
                        .getAttribute('data-colorset') - 1 || 0;
                var options = {
                    series: {
                        0: {
                            color: colorSetArray[colorSetIndex][0],
                            areaOpacity: 0.8,
                            axisColor: axisColorV,
                            areaColor: areaColorV,
                        },
                    },
                    width: getEditorStorage('chartGraphDetails')?.chartWidth, //chart_width,
                    height: getEditorStorage('chartGraphDetails')?.chartHeight, //chart_height,
                    chartArea: {
                        width: getEditorStorage('chartGraphDetails')?.chartAreaWidth,
                        height: getEditorStorage('chartGraphDetails')?.chartAreaHeight,
                    }, //chartArea,
                    legend: {
                        position: 'none',
                    },
                    pointsVisible: true,
                    enableTooltip: true,
                    backgroundColor: 'transparent',
                    // line of the area
                    lineSize: 5,
                    lineColor: colorSetArray[colorSetIndex][0],
                    // point size of value provided
                    pointSize: 8,
                    dataPointWidth: 50,
                    hAxis: {
                        gridlines: {
                            color: 'transparent',
                        },
                        textStyle: {
                            color: getEditorStorage('chartGraphDetails')?.hAxisTextColor,
                            fontName: getEditorStorage('chartGraphDetails')?.hAxisFontName,
                            bold: true,
                        },
                    },
                    vAxis: {
                        format: vaxisFormat,
                        minValue: 0,
                        textStyle: {
                            color: getEditorStorage('chartGraphDetails')?.vAxisTextColor,
                            fontName: getEditorStorage('chartGraphDetails')?.vAxisFontName,
                            bold: false,
                        },
                    },
                };
                var chart = new window.google.visualization.AreaChart(
                    document.getElementsByClassName(divClassName)[recordID]
                );
                if (getEditorStorage('chartGraphDetails')?.chartType === 'ColumnChart')
                    chart = new window.google.visualization.ColumnChart(
                        document.getElementsByClassName(divClassName)[recordID]
                    );

                // var distribution_charts = document.getElementsByClassName(divClassName)[recordID];
                console.log('JSON data==>', JSON.stringify(data));
                chart.draw(data, options);
                recordID = recordID + 1;
            } catch (err) {
                console.log(err.message);
            }
        }
    } catch (err) {
        console.log(err.message);
    }
};

export const RdtLargestPanelHeight = () => {
    // Fund Impact Height
    if ($('#FUND_IMPACT').length > 0) {
        var impactItems = $('#FUND_IMPACT .carousel-inner .item'), //grab all slides
            heights = [], //create empty array to store height values
            tallest; //create variable to make note of the tallest slide

        var impactElement = $('#FUND_IMPACT .carousel-inner .fund-impact-elements > div:nth-child(2)');
        $('#FUND_IMPACT .carousel-inner .fund-impact-elements').css('height', 'auto');
        if ($(window).width() > 1199) {
            $('#FUND_IMPACT .contentpanelname').css('padding-bottom', '50px');
        } else {
            $('#FUND_IMPACT .contentpanelname').css('padding-bottom', '41.3px');
        }
        impactElement.each(function () {
            $(this).css('height', 'auto');
            $(this).children().css('height', 'auto');
        });
        if (impactItems.length) {
            $(impactItems[0]).removeClass('active');
            impactItems.each(function () {
                //add heights to array
                heights.push($(this).height());
            });
            tallest = Math.max.apply(null, heights); //cache largest value

            $('#FUND_IMPACT .carousel-inner').css('height', tallest + 'px');
            if ($('.NCSU_IMPACT ').length > 0) {
                $('#FUND_IMPACT .fund-outer-Wrapper').css('height', tallest + 'px');
            }
            $(impactItems[0]).addClass('active');
        } else {
            $('#FUND_IMPACT .contentpanelname').css('padding-bottom', '0px');
            $('#FUND_IMPACT .carousel-inner').css('height', 'auto');
        }
    }
    // Fund Details Height
    if ($('#FUND_DESCRIPTION').length > 0) {
        var detailItems = $('#FUND_DESCRIPTION .carousel-inner .item'), //grab all slides
            heights = [], //create empty array to store height values
            tallest; //create variable to make note of the tallest slide

        var detailElement = $('#FUND_DESCRIPTION .carousel-inner .fundDescriptionElement > div:nth-child(2)');
        $('#FUND_DESCRIPTION .carousel-inner .fundDescriptionElement').css('height', 'auto');
        if ($(window).width() > 1199) {
            $('#FUND_DESCRIPTION .contentpanelname').css('padding-bottom', '50px');
        } else {
            $('#FUND_DESCRIPTION .contentpanelname').css('padding-bottom', '41.3px');
        }
        detailElement.each(function () {
            $(this).css('height', 'auto');
            $(this).children().css('height', 'auto');
        });
        if (detailItems.length) {
            $(detailItems[0]).removeClass('active');
            detailItems.each(function () {
                //add heights to array
                heights.push($(this).height());
            });
            tallest = Math.max.apply(null, heights); //cache largest value

            $('#FUND_DESCRIPTION .carousel-inner').css('height', tallest + 'px');
            $(detailItems[0]).addClass('active');
        } else {
            $('#FUND_DESCRIPTION .carousel-inner').css('height', 'auto');
            $('#FUND_DESCRIPTION .contentpanelname').css('padding-bottom', '0px');
        }
    }

    // Fund SOA Height
    if ($('#FUND_SOA').length > 0) {
        var soaItems = $('#FUND_SOA .carousel-inner .item'), //grab all slides
            heights = [], //create empty array to store height values
            tallest; //create variable to make note of the tallest slide
        var soaElement = $('#FUND_SOA .carousel-inner .fundSoaElement > div:nth-child(2)');
        $('#FUND_SOA .carousel-inner .fundSoaElement').css('height', 'auto');
        if ($('#FUND_SOA .carousel-inner .fundSOAElement').length > 0) {
            soaElement = $('#FUND_SOA .carousel-inner .fundSOAElement > div:nth-child(2)');
            $('#FUND_SOA .carousel-inner .fundSOAElement').css('height', 'auto');
        }
        if ($(window).width() > 1199) {
            $('#FUND_SOA .contentpanelname').css('padding-bottom', '50px');
        } else {
            $('#FUND_SOA .contentpanelname').css('padding-bottom', '41.3px');
        }
        soaElement.each(function () {
            $(this).css('height', 'auto');
            $(this).children().css('height', 'auto');
        });
        if (soaItems.length) {
            $(soaItems[0]).removeClass('active');
            soaItems.each(function () {
                //add heights to array
                heights.push($(this).height());
            });
            tallest = Math.max.apply(null, heights); //cache largest value

            $('#FUND_SOA .carousel-inner').css('height', tallest + 'px');
            $(soaItems[0]).addClass('active');
        } else {
            $('#FUND_SOA .carousel-inner').css('height', 'auto');
            $('#FUND_SOA .contentpanelname').css('padding-bottom', '0px');
        }
    }

    // Max-height setting on preview modal
    var previewModal = document.querySelector('.preview .previewOnly');
    if (previewModal) {
        $('#FUND_IMPACT').css({ 'max-height': previewModal.clientHeight, 'overflow-y': 'scroll' });
    }
};

export const reportUpdateSetTitle = () => {
    var reportUpdateTabLenght = $('a.report-update-name').length;
    if (reportUpdateTabLenght > 1) {
        for (var i = 0; i < reportUpdateTabLenght; i++) {
            var reportUpdatesLI = $('a.report-update-name')[i];
            var reportUpdatesLIText = reportUpdatesLI.text.trim();
            reportUpdatesLI.parentElement.setAttribute('title', reportUpdatesLIText);
            reportUpdatesLI.classList.add('ellipses');
        }
    } else {
        return;
    }
};

export const InlineSafeSecurity = () => {
    try {
        const urlRegex = /(https?:\/\/[^ &]*)/;
        const backgroundGreetingImage = document
            ?.querySelector('.texture-overlay-wrapper')
            ?.outerHTML?.match(urlRegex)[1];
        document.querySelector('.texture-overlay-wrapper').style.backgroundImage = `url(${backgroundGreetingImage})`;
    } catch (ex) {}
};
