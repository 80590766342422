// @flow
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import HyperDatepicker from '../../../components/datepicker/Datepicker';
import { useModalContext } from '../../../contexts/ModalContext';
import Moment from 'moment';
import { modalActions } from '../../../reducers/actions';
import { reportDataExportReset, fundDistributionList, reportFundtDataYearList } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';

const ExportFyDistributionListModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatchAction = useDispatch();
    const [toggle, setToggle] = useState(true);
    const [isDate, setIsDate] = useState('all');
    const [reportYear, setReportYear] = useState('');
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);

    const { userInfo, distributionExported, yearList } = useSelector((state) => ({
        userInfo: state?.auth?.me,
        distributionExported: state?.reportDataExport?.fundDistributionList,
        yearList: state?.reportDataFund?.yearList,
    }));

    useEffect(() => {
        if (distributionExported) {
            modalDispatch({ type: modalActions.CLOSE_MODAL });
            dispatchAction(reportDataExportReset());
        }
    }, [distributionExported]);

    useEffect(() => {
        dispatchAction(reportFundtDataYearList());
    }, []);

    const onSelectStartDate = (date) => {
        setSelectedStartDate(date);
    };
    const onSelectEndDate = (date) => {
        setSelectedEndDate(date);
    };

    const downloadFyDistribution = () => {
        const payload = {
            reportYear: reportYear,
        };
        if (isDate == 'date') {
            selectedStartDate && (payload.startDate = Moment(selectedStartDate).format('MM/DD/YYYY'));
            selectedEndDate && (payload.endDate = Moment(selectedEndDate).format('MM/DD/YYYY'));
        }
        dispatchAction(fundDistributionList(payload));
    };
    return (
        <>
            <div className="custom-padding">
                <p>Download report distribution list for a specific fiscal year or over a specific date range.</p>
                <Col md={6} className="mt-3">
                    <FormInput
                        type="select"
                        className="custom-select"
                        onChange={(e) => setReportYear(e.target.value)}
                        label="Fiscal Year">
                        <option>All Fiscal Years</option>
                        {yearList &&
                            yearList.map((y) => {
                                return (
                                    <option key={y} value={y}>
                                        {y}
                                    </option>
                                );
                            })}
                    </FormInput>
                </Col>
                <Col className="mt-3">
                    <p>
                        <b>Date Range:</b>
                    </p>
                    <FormInput
                        type="radio"
                        id="selectedTimeBitForAll"
                        name="selectedTimeBit"
                        value="all"
                        label="All"
                        className="ms-1 mt-1"
                        onChange={(e) => setIsDate(e.target.value)}
                        checked={isDate == 'all' ? true : false}
                    />
                    <Row className="mt-1">
                        <Col md={1}>
                            <FormInput
                                type="radio"
                                id="selectedTimeBitForSelectedDates"
                                name="selectedTimeBit"
                                label=" "
                                className="ms-1 mt-1"
                                value="date"
                                onChange={(e) => setIsDate(e.target.value)}
                                checked={isDate == 'date' ? true : false}
                            />
                        </Col>
                        <Col md={5} className="mt-2 mt-md-0 ps-md-0 ps-2 ">
                            <HyperDatepicker
                                value={selectedStartDate}
                                inputClass="form-control-light"
                                onChange={(date) => {
                                    onSelectStartDate(date);
                                }}
                                disabled={isDate == 'all' ? true : false}
                            />
                        </Col>
                        <Col md={1} className="m-auto">
                            to
                        </Col>
                        <Col md={5} className="ps-md-1 ps-2">
                            <HyperDatepicker
                                value={selectedEndDate}
                                inputClass="form-control-light"
                                onChange={(date) => {
                                    onSelectEndDate(date);
                                }}
                                disabled={isDate == 'all' ? true : false}
                            />
                        </Col>
                    </Row>
                </Col>
            </div>
            <div className="d-flex justify-content-center modal-footer">
                <ButtonElement
                    name="Export"
                    variant="primary"
                    type="button"
                    onClick={() => {
                        if (toggle) {
                            setToggle(false);
                            downloadFyDistribution();
                        }
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default ExportFyDistributionListModal;
