// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import { FormInput } from '../../../../components/form/input';
import ButtonElement from '../../../../components/button/ButtonElement';
import { ReportPanelValidation } from '../../../../components/form/validation';
import { rgcpHtmlAdd, rgcpHtmlUpdate, rgcpHtmlList, brandAllList } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../../../config';

const ReportPanelHtml = (props) => {
    const [toggle, setToggle] = useState(false);
    const dispatch = useDispatch();
    const { brandlist, paletteList, typesList, add, update, list } = useSelector((state) => ({
        brandlist: state?.brand?.allList?.data?.content,
        paletteList: state?.palette?.list?.data,
        add: state?.rgcpHtml?.add,
        typesList: state?.rgcpHtml?.types?.data,
        update: state?.rgcpHtml?.update,
        list: state?.rgcpHtml?.list,
    }));

    useEffect(() => {
        if (add) {
            reset();
            props.hideModal();
            window.scrollTo(0, 0);
            dispatch(
                rgcpHtmlList({
                    orgId: props?.data?.orgId,
                    isAnd: true,
                    page: list?.data?.number,
                    size: config.PAGE_SIZE,
                })
            );
        }
        if (update) {
            reset();
            props.hideModal();
            window.scrollTo(0, 0);
            dispatch(
                rgcpHtmlList({
                    orgId: props?.data?.original?.fkOrgId,
                    isAnd: true,
                    page: list?.data?.number,
                    size: config.PAGE_SIZE,
                })
            );
        }
    }, [add, update]);

    useEffect(() => {
        if (props?.data?.original) {
            setValue('fkTblRgcpTypeId', props.data.original.fkTblRgcpTypeId);
            setValue('fkTblPaletteId', props.data.original.fkTblPaletteId);
            setValue('fkTemplateId', props.data.original.fkTemplateId);
            setValue('fkTblRgcpTypeId', props.data.original.fkTblRgcpTypeId);
            setValue('colHtmlTypeName', props.data.original.colHtmlTypeName);
            setValue('colHtml', props.data.original.colHtml);
            setValue('colDefault', props.data.original.colDefault === 'true' ? true : false);
        }
        if (props?.data?.orgId) setValue('orgId', props.data.orgId);
    }, [props?.data]);

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { errors },
    } = useForm({ resolver: ReportPanelValidation, mode: 'onBlur' });
    const watchAll = watch();
    const [isValidated, setIsValidated] = useState(false);

    useEffect(() => {
        if (
            watchAll &&
            watchAll.fkTemplateId !== 'Select Template Name' &&
            watchAll.fkTblPaletteId !== 'Select Palette' &&
            watchAll.fkTblRgcpTypeId !== 'Select RGCP Type' &&
            watchAll.colHtmlTypeName !== '' &&
            watchAll.colHtml !== ''
        ) {
            setIsValidated(true);
        } else {
            setIsValidated(false);
        }
    }, [watchAll]);

    const onSubmit = (data) => {
        if (props?.data?.orgId) {
            data.fkOrgId = props?.data?.orgId;
            dispatch(rgcpHtmlAdd(data));
        } else {
            data.fkOrgId = props.data.original.fkOrgId;
            data.pkRgcpHtmlId = props.data.original.pkRgcpHtmlId;
            dispatch(rgcpHtmlUpdate(data));
        }
    };

    const toggleCancel = () => props.hideModal();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={4}>
                    <FormInput type="select" id="fkTemplateId" label="Template Name *" register={register}>
                        <option value="">Select</option>
                        {brandlist?.map((item, index) => {
                            return (
                                <option key={index} value={item?.id}>
                                    {item?.name}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
                <Col md={4}>
                    <FormInput type="select" id="fkTblPaletteId" label="Palette *" register={register}>
                        <option value="">Select</option>
                        {paletteList?.map((item, index) => {
                            return (
                                <option key={index} value={item?.pkPaletteId}>
                                    {item?.colPaletteName}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
                <Col md={4}>
                    <FormInput type="select" id="fkTblRgcpTypeId" label="RGCP Type *" register={register}>
                        <option value="">Select</option>
                        {typesList?.map((item, index) => {
                            return (
                                <option key={index} value={item?.pkRgcpTypeId}>
                                    {item?.colRgcpTypeName}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
            </Row>
            <Row className="pb-1">
                <Col>
                    <span className="me-2">Default:</span>
                    <FormInput
                        type="switch"
                        id="colDefault"
                        register={register}
                        label={watchAll?.colDefault === 'true' || watchAll?.colDefault === true ? 'ON' : 'OFF'}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormInput
                        type="text"
                        id="colHtmlTypeName"
                        label="Html Name *"
                        maxLength="150"
                        placeholder="default_html"
                        errors={errors}
                        register={register}
                    />
                    <FormInput
                        className="custom-textarea mb-3"
                        type="textarea"
                        as="textarea"
                        id="colHtml"
                        cols="5"
                        label="Html *"
                        height="true"
                        errors={errors}
                        register={register}
                    />
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name={props?.data?.orgId ? 'Save' : 'Update'}
                    variant="primary"
                    type="submit"
                    disabled={!isValidated}
                />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggleCancel} />
            </div>
        </form>
    );
};

export default ReportPanelHtml;
