// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { getNotificationDomForSuccess } from '../notification/GenericAlert';
import { contentSlides } from '@ovrture/react-redux';
import service from '../../../constants/service.constant';
import { arrowInitHandler, indicatorsSingleInitHandler, updateCarouselId } from '../migration/TenantMigration';
import { setEditorChanges } from '../storage';
import { RedirectTo } from '../../../routes/role/path';
import { useNotification } from '@ovrture/react-redux/dist/helpers/toasterNotification/NotificationContext';

const AddOrChangeSlide = (props) => {
    const { Toast } = useNotification();
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const [sliderList, setSliderList] = useState([]);

    const { details, slides, loading } = useSelector((state) => ({
        details: state?.auth?.me,
        slides: state?.content?.slides,
        loading: state?.content?.loading,
    }));

    useEffect(() => {
        dispatch(contentSlides({ defaultContentPanelId: props?.data?.cpId }));
    }, []);

    useEffect(() => {
        if (slides?.code === service.success) {
            sliderViewList();
        }
    }, [slides]);

    const sliderViewList = () => {
        let items = parseDomFromString();
        let list = [];
        for (let index = 0; index < items?.length; index++)
            list.push({
                index: props?.data?.isReplace ? index + 1 : index,
                url: `${process?.env?.REACT_APP_S3_URL}/Org${details?.organaisationId}/assets/slider-view/SliderView${
                    index + 1
                }.png`,
            });
        setSliderList(list);
    };

    const parseDomFromString = () => {
        let doc = new DOMParser().parseFromString(slides?.data, 'text/html');
        return doc.querySelectorAll('.item.lastItem');
    };

    const slideAddOrReplace = (item) => {
        let index = null;
        let items = parseDomFromString();
        if (props?.data?.isReplace) {
            let activeElement = document?.querySelector('.standard-template .carousel-inner .item.active');
            if (
                window.location.pathname.includes(RedirectTo('/site-edit')) ||
                window.location.pathname.includes(RedirectTo('/report-edit'))
            ) {
                const ele = document.getElementById('rootComponent');
                const replacerId = ele.getAttribute('slideSecClass-id');
                const slideSecClass = document.getElementById(replacerId);
                index = getActiveIndex(slideSecClass?.querySelectorAll('.carousel .carousel-inner .item'));
                activeElement = document?.querySelector(
                    '#' + replacerId + ' .standard-template .carousel-inner .item.active'
                );
            }
            activeElement?.children[0].remove();
            if (item?.index === 0) {
                removeClassStd(items[item?.index].children[0]);
                activeElement.appendChild(items[item?.index].children[0]);
            } else {
                removeClassStd(items[item?.index - 1].children[0]);
                activeElement.appendChild(items[item?.index - 1].children[0]);
            }
            if (
                window.location.pathname.includes(RedirectTo('/site-edit')) ||
                window.location.pathname.includes(RedirectTo('/report-edit'))
            ) {
                setEditorChanges(true);
            }
        } else {
            if (
                window.location.pathname.includes(RedirectTo('/site-edit')) ||
                window.location.pathname.includes(RedirectTo('/report-edit'))
            ) {
                const ele = document.getElementById('rootComponent');
                const replacerId = ele.getAttribute('slideSecClass-id');
                const slideSecClass = document.getElementById(replacerId);
                const carouselItems = slideSecClass?.querySelectorAll('.carousel .carousel-inner .item')?.length;
                let carouselItemActive = slideSecClass.querySelector('.standard-template .carousel-inner .item.active');
                carouselItemActive.classList.remove('active');
                if (!items[item?.index].classList.contains('carousel-item'))
                    items[item?.index].classList.add('carousel-item');
                items[item?.index].classList.add('active');
                if (carouselItems === 1) {
                    removeClassStd(items[item?.index]);
                    document
                        .getElementById(replacerId)
                        .querySelector('.standard-template .carousel-inner')
                        .appendChild(items[item?.index]);
                } else {
                    removeClassStd(items[item?.index]);
                    document
                        .getElementById(replacerId)
                        .querySelector('.standard-template .carousel-inner')
                        .insertBefore(items[item?.index], carouselItemActive.nextSibling);
                }
                index = getActiveIndex(slideSecClass?.querySelectorAll('.carousel .carousel-inner .item'));
            } else {
                document.querySelector('.standard-template .carousel-inner .item.active').classList.remove('active');
                if (!items[item?.index].classList.contains('carousel-item'))
                    items[item?.index].classList.add('carousel-item');
                items[item?.index].classList.add('active');
                removeClassStd(items[item?.index]);
                document.querySelector('.standard-template .carousel-inner').appendChild(items[item?.index]);
                index = getActiveIndex(document?.querySelectorAll('.standard-template .carousel-inner .item'));
            }
        }
        Toast?.success({ description: props?.data?.isReplace ? 'Slide layout changed.' : 'Slide added.' });
        props?.data?.slideModified();
        updateCarouselId();
        indicatorsSingleInitHandler(index);
        arrowInitHandler();
        modalDispatch({ type: modalActions.CLOSE_MODAL });
        if (
            window.location.pathname.includes(RedirectTo('/site-edit')) ||
            window.location.pathname.includes(RedirectTo('/report-edit'))
        ) {
            setEditorChanges(true);
        }
    };

    const removeClassStd = (item) => {
        if (item?.classList?.contains('lastItem')) {
            item.classList.remove('lastItem');
        }
    };

    const getActiveIndex = (items) => {
        for (let i = 0; i < items.length; i++) {
            const element = items[i];
            if (element?.classList?.contains('active')) {
                return i;
            }
        }
    };

    return (
        <>
            <div className="add-change-slide">
                {sliderList?.map((item) => (
                    <img
                        key={item?.index}
                        className={props?.data?.isReplace ? 'm-2 replace' : 'm-2 add'}
                        alt="image"
                        src={item?.url}
                        onClick={() => {
                            if (!loading) {
                                props?.data?.isReplace
                                    ? modalDispatch({
                                          type: modalActions.OPEN_MODAL,
                                          payload: {
                                              modalAction: (e) => slideAddOrReplace(item),
                                              modalVariant: 'primary',
                                              modalDataKey: ['editor', 'changeSlideConfirmation'],
                                          },
                                      })
                                    : slideAddOrReplace(item);
                            }
                        }}
                    />
                ))}
            </div>

            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() => modalDispatch({ type: modalActions.CLOSE_MODAL })}
                />
            </div>
        </>
    );
};

export default AddOrChangeSlide;
