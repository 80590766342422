// @flow
import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { cpGetAllInitialFolder, cpPreviewImage, cpFolderReset } from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import TreeView from '../../../components/treeview';
import { useForm } from 'react-hook-form';

const SelectTemplateWithPreview = (props) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const { modalDispatch } = useModalContext();
    const [initialLoad, setInitialLoad] = useState(true);
    const [parentId, setParentId] = useState(false);
    const [template, setTemplate] = useState(null);
    const [loading, setLoading] = useState(true);

    const { initialList, previewImage } = useSelector((state) => ({
        initialList: state?.cpFolder?.getAllInitialFolder,
        previewImage: state?.cpFolder?.cpPreview,
    }));

    const { handleSubmit } = useForm({ mode: 'onBlur' });
    useEffect(() => {
        getInitialFolderList();
    }, []);

    const getInitialFolderList = (parentId = '#') => {
        dispatch(cpGetAllInitialFolder({ parentId, processId: 12 }));
    };

    useEffect(() => {
        if (initialList?.data) {
            async function createInitialData() {
                await manageTreeData(data);
            }
            setLoading(false);
            if (data.length > 0) {
                createInitialData(initialList?.data);
            } else {
                initialList?.data?.forEach((el) => {
                    data.push({
                        id: el.id,
                        parent_id: el.id,
                        text: el?.name || el?.text,
                        icon: el.icon,
                        children: [],
                        child: el.child,
                        adminId: el.adminId,
                        assetId: el.assetId,
                        brandId: el.brandId,
                        fkRoleId: el.fkRoleId,
                        fkOrgId: el.fkOrgId,
                        fkPaletteId: el.fkPaletteId,
                        assetRootNodeId: el.assetRootNodeId,
                        userId: el.userId,
                        permissionbyId: el.permissionbyId,
                        canBeDeleted: el.canBeDeleted,
                    });
                });
                setData([...data]);
            }
            if (initialList?.data[0]?.child && initialLoad) {
                getInitialFolderList(initialList?.data[0].id);
                setTimeout(() => {
                    setParentId(initialList?.data[0].id);
                }, 200);
            } else if (!initialList?.data[0]?.child) {
                setInitialLoad(false);
                setTimeout(() => {
                    setParentId(null);
                }, 200);
            }
            dispatch(cpFolderReset());
        }
    }, [initialList]);

    useEffect(() => {
        if (!parentId) {
            setTimeout(() => {
                document.getElementsByClassName('jstree-icon jstree-ocl loading')[0]?.remove('loading');
            }, 200);
        }
    }, [parentId]);

    const manageTreeData = (list) => {
        initialList?.data?.forEach((el) => {
            list?.forEach((d) => {
                if (el?.parentId === d?.id) {
                    d.child = false;
                    d.children.push({
                        id: el.id,
                        parent_id: el.parentId,
                        text: el?.text || el?.name,
                        icon: el.icon,
                        children: [],
                        child: el.child,
                        adminId: el.adminId,
                        assetId: el.assetId,
                        brandId: el.brandId,
                        fkRoleId: el.fkRoleId,
                        fkOrgId: el.fkOrgId,
                        fkPaletteId: el.fkPaletteId,
                        userId: el.userId,
                        assetRootNodeId: el.assetRootNodeId,
                        permissionbyId: el.permissionbyId,
                        canBeDeleted: el.canBeDeleted,
                    });
                    const filterArry = d.children.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
                    const ids = initialList?.data.map((d) => d.id);
                    const newList = filterArry.filter((f) => ids.indexOf(f.id) !== -1);
                    d.children = [...newList];
                } else {
                    if (d?.children?.length > 0) {
                        manageTreeData(d?.children);
                    }
                }
            });
        });
        setData([...list]);
    };

    function handleGetList(e, list) {
        if (list.event?.type === 'dblclick' && list?.node?.original?.id && list?.node?.original?.child) {
            getInitialFolderList(list?.node?.original?.id);
            //const id = document.getElementById(list?.node?.original?.id);
            //if (id) id.getElementsByClassName('jstree-icon jstree-ocl')[0].classList.add('loading');
        }
        setTimeout(() => {
            if (parentId) {
                const ele = document.getElementById(parentId);
                ele?.querySelectorAll('.jstree-icon.jstree-ocl')[0].click();
                if (ele && ele.querySelector('.jstree-anchor').querySelector('.fa-fa-folder-system-template') === null)
                    ele.getElementsByClassName('jstree-icon jstree-ocl')[0].classList.add('loading');
            }
        }, 300);
        if (list?.node?.original?.icon === 'glyphicon glyphicon-text-background-cp') {
            setTemplate({ ...list?.node?.original });
            sessionStorage.setItem(process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY, 'SKIP_LOADER');
            dispatch(cpPreviewImage({ cpFolderViewId: list?.node?.original?.id }));
        } else {
            setTemplate(null);
        }
    }
    const onSubmit = () => props?.data?.handleSelectTemplate(template);
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <p>What panel template would you like to use?</p>
            <Row>
                <Col lg={6}>
                    <Card className="select-template-modal">
                        <Card.Body className="overflow-auto">
                            {loading && (
                                <div className="jstree-default">
                                    <i className="loading jstree-icon jstree-ocl" />
                                    <span className="loading-text">Loading...</span>
                                </div>
                            )}
                            <TreeView data={data} handleChange={handleGetList} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6} className="px-1">
                    <Card>
                        <Card.Body>
                            <h5>Preview:</h5>
                            <img
                                src={previewImage?.data ? previewImage?.data?.imageSource : ''}
                                alt=""
                                className="w-100"
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <div className="button-list d-flex justify-content-center">
                <ButtonElement name="Select" variant="primary" type="submit" disabled={!template?.id} />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </form>
    );
};

export default SelectTemplateWithPreview;
