// @flow
import React from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { groupDelete } from '@ovrture/react-redux';
import { useDispatch } from 'react-redux';

const DeleteGroup = (props) => {
    const dispatch = useDispatch();
    return (
        <>
            <p>Are you sure you want to delete this group?</p>
            <div className="d-flex justify-content-center modal-footer">
                <ButtonElement
                    name="Delete"
                    variant="danger"
                    type="button"
                    onClick={() => {
                        if (props?.data?.lastPageData?.length === 1) {
                            const page = props?.data?.page !== 0 ? props?.data?.page - 1 : 0;
                            props?.data?.setPage(page);
                        }
                        dispatch(groupDelete({ groupId: props?.data?.selectedItem }));
                    }}
                />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={(e) => props.hideModal()} />
            </div>
        </>
    );
};

export default DeleteGroup;
