export const setRetainHistory = () => {
    localStorage.setItem(process.env.REACT_APP_HISTORY_RETAIN_STATUS_KEY, true);
};

export const resetRetainHistory = (tableId) => {
    try {
        let historyDataObj = JSON.parse(localStorage.getItem(process.env.REACT_APP_HISTORY_DATA_KEY));
        delete historyDataObj[tableId];
        localStorage.setItem(process.env.REACT_APP_HISTORY_DATA_KEY, JSON.stringify(historyDataObj));
    } catch (e) {}
};

export const getRetainHistory = (tableId) => {
    try {
        if (localStorage.getItem(process.env.REACT_APP_HISTORY_RETAIN_STATUS_KEY)) {
            setTimeout(() => {
                localStorage.removeItem(process.env.REACT_APP_HISTORY_RETAIN_STATUS_KEY);
            }, 800);
            let historyDataObj = JSON.parse(localStorage.getItem(process.env.REACT_APP_HISTORY_DATA_KEY));
            return historyDataObj[tableId];
        }
        return false;
    } catch (e) {
        return false;
    }
};
export const removeRetainHistory = (tableId) => {
    let historyDataObj = JSON.parse(localStorage.getItem(process.env.REACT_APP_HISTORY_DATA_KEY));
    if (historyDataObj) {
        delete historyDataObj[tableId];
        localStorage.setItem(process.env.REACT_APP_HISTORY_DATA_KEY, JSON.stringify(historyDataObj));
    }
};
export const getFilterRetainHistory = (tableId) => {
    let historyDataObj = JSON.parse(localStorage.getItem(process.env.REACT_APP_HISTORY_DATA_KEY));
    let filterData = historyDataObj[tableId];
    delete filterData?.isAnd;
    delete filterData?.page;
    delete filterData?.searchText;
    delete filterData?.size;
    delete filterData?.sortObj;
    return filterData;
};

export const setHistoryData = (data) => {
    localStorage.setItem(process.env.REACT_APP_HISTORY_DATA_KEY, data);
};

export const setRedirectionId = (page, id) => {
    if (page) {
        let existingObj = JSON.parse(localStorage.getItem('redirectionId')) || {};
        existingObj[page] = id;
        localStorage.setItem('redirectionId', JSON.stringify(existingObj));
    } else {
        localStorage.setItem('redirectionId', JSON.stringify({}));
    }
};

export const getRedirectionId = (page) => {
    let redirectionId = JSON.parse(localStorage.getItem('redirectionId'));
    return redirectionId[page];
};

// export const setHistoryDataOnLoadForFilter = (payload) => {
//     try {
//         let existingObj = getHistoryData() || {};
//         existingObj['userSites'] = payload;
//         setHistoryData(JSON.stringify(existingObj));
//     } catch (e) {}
// };

export const getHistoryData = () => {
    return JSON.parse(localStorage.getItem(process.env.REACT_APP_HISTORY_DATA_KEY));
};

export const getTempData = () => {
    return JSON.parse(localStorage.getItem(process.env.REACT_APP_TEMP_KEY));
};

export const setTempData = (data) => {
    localStorage.setItem(process.env.REACT_APP_TEMP_KEY, JSON.stringify(data));
};

export const getRetainHistoryJstree = (assetJstreeHistory) => {
    try {
        if (localStorage.getItem(process.env.REACT_APP_HISTORY_RETAIN_STATUS_KEY)) {
            setTimeout(() => {
                localStorage.removeItem(process.env.REACT_APP_HISTORY_RETAIN_STATUS_KEY);
            }, 800);
            return JSON.parse(localStorage.getItem(assetJstreeHistory + 'JstreeHistory'));
        }
        return false;
    } catch (e) {
        return false;
    }
};

export const setRetainHistoryJstree = (data, assetJstreeHistory) => {
    data?.length > 0 && localStorage.setItem(assetJstreeHistory + 'JstreeHistory', JSON.stringify(data));
};

export const setCPSaved = (obj) => {
    localStorage.setItem('setCPSaved', JSON.stringify(obj));
};
export const getCPSaved = () => {
    return JSON.parse(localStorage.getItem('setCPSaved'));
};
export const removeCPSaved = () => {
    localStorage.removeItem('setCPSaved');
};

export const setCPEdit = (obj) => {
    localStorage.setItem('content-panel-edit-data', JSON.stringify(obj));
};
export const getCPEdit = () => {
    return JSON.parse(localStorage.getItem('content-panel-edit-data'));
};

// export const getApiGatewayUrl = () => {
//     let obj = JSON.parse(localStorage.getItem('apiGatewayUrl')) || {};
//     return obj[getCurrentHost()];
// };
// export const setApiGatewayUrl = (backendApi) => {
//     let obj = JSON.parse(localStorage.getItem('apiGatewayUrl')) || {};
//     obj[getCurrentHost()] = backendApi;
//     localStorage.setItem('apiGatewayUrl', JSON.stringify(obj));
// };
export const getCurrentHost = () => {
    let currentHost = window.location.hostname;
    if (window.location.hostname === 'localhost') {
        // 'barnard.react.security.ovrture.com'
        //'react.ovrture.com' //'barnard.react.dev.ovrture.com'
        currentHost = process.env.REACT_APP_DASHBOARD_HOST_URL;
    }
    return currentHost;
};
