// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
// components
import Table from '../../../components/table/Table';
import ButtonElement from '../../../components/button/ButtonElement';
import { table as TableColumn } from '../../../constants/table';
import { FormInput } from '../../../components/form/input';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

import {
    reportFundtDataYearList,
    recordAssetList,
    recordAssetDownload,
    recordAssetDelete,
    recordAssetUpdate,
    recordAssetCreate,
    recordAssetDownloadReset,
} from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { downloadFile } from '../../../helpers/downloadFile/downloadFile';

const ManageDownloadsModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const [dataList, setDataList] = useState([]);
    const [currentYear, setcurrentYear] = useState(props?.data?.currentYear);
    const { yearList, assetRecordList, downloaded } = useSelector((state) => ({
        yearList: state?.reportDataFund?.yearList,
        assetRecordList: state?.assetRecord?.list,
        downloaded: state?.assetRecord?.download,
    }));

    useEffect(() => {
        if (props?.data?.loadData) {
            dispatch(reportFundtDataYearList());
            dispatch(recordAssetList({ recordId: props?.data?.recordId }));
        }
    }, []);

    useEffect(() => {
        if (yearList && currentYear === null) {
            setcurrentYear(yearList[0]);
        }
    }, [yearList, currentYear]);

    useEffect(() => {
        if (assetRecordList?.data) {
            assetRecordList?.data.forEach((asset) => {
                asset['year'] = asset?.colReportYear;
                asset['fileName'] =
                    asset?.colAssetName === null ? (
                        '-'
                    ) : (
                        <ButtonElement as="text" name={asset?.colAssetName} title={asset?.colAssetName} />
                    );
                asset['id'] = asset?.pkRecordAssetsId;
            });
            setDataList(assetRecordList?.data);
        }
        if (downloaded) {
            downloadFile(
                downloaded?.data,
                'application/zip',
                downloaded?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(recordAssetDownloadReset());
        }
    }, [assetRecordList, downloaded]);

    const handleDeleteData = (row) => {
        dispatch(recordAssetDelete({ recordAssetId: row?.original?.pkRecordAssetsId }));
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
        });
    };

    const handleData = (data, actionName, yearData) => {
        let obj = assetRecordList?.data?.find((f) => f.colReportYear === yearData && actionName === 'replace');
        if (obj) {
            dispatch(
                recordAssetUpdate({
                    colReportYear: yearData,
                    fkRecordId: props?.data?.recordId,
                    assetFolderViewId: data.id,
                    pkRecordAssetsId: obj?.pkRecordAssetsId,
                })
            );
        } else {
            dispatch(
                recordAssetCreate({
                    colReportYear: currentYear,
                    fkRecordId: props?.data?.recordId,
                    assetFolderViewId: data.id,
                })
            );
        }
    };

    const Actions = ({ row }) => {
        return (
            <>
                <div className="hstack gap-2">
                    <ButtonElement
                        className="action-icon hover-warning p-0"
                        icon={<i className="mdi mdi-file-replace font-20"></i>}
                        tooltip="Replace"
                        onClick={() => {
                            modalDispatch({
                                type: modalActions.OPEN_MODAL,
                                payload: {
                                    modalAction: (e) => modalDispatch({ type: modalActions.CLOSE_MODAL }),
                                    modalVariant: 'primary',
                                    modalDataKey: ['common', 'replaceAsset'],
                                    data: {
                                        handleActions: handleData,
                                        recordId: props?.data?.recordId,
                                        currentYear: row?.original?.colReportYear,
                                        name: 'replace',
                                    },
                                },
                            });
                        }}
                    />
                    <div className="vr"></div>
                    <ButtonElement
                        icon={<i className="mdi mdi-download font-24"></i>}
                        className="action-icon hover-primary"
                        tooltip="Download"
                        as="href"
                        onClick={() => {
                            dispatch(recordAssetDownload({ recordAssetId: row?.original?.pkRecordAssetsId }));
                        }}
                    />
                    <div className="vr"></div>
                    <ButtonElement
                        className="action-icon hover-danger p-0"
                        icon={<i className="mdi mdi-delete font-22"></i>}
                        tooltip="Delete"
                        onClick={() =>
                            modalDispatch({
                                type: modalActions.OPEN_MODAL,
                                payload: {
                                    modalAction: (e) => {
                                        handleDeleteData(row);
                                    },
                                    modalVariant: 'danger',
                                    modalDataKey: ['systemAdmin', 'deleteDownloads'],
                                    data: {
                                        id: recordAssetDelete,
                                    },
                                },
                            })
                        }
                    />
                </div>
            </>
        );
    };

    return (
        <>
            <Col>
                <b>Add for Fiscal Year:</b>
                <Row>
                    <Col lg={3} md={4} sm={4} xs={6}>
                        <FormInput
                            type="select"
                            defaultValue={currentYear && currentYear}
                            onChange={(e) => setcurrentYear(e.target.value)}
                            label="Select fiscal year">
                            {yearList &&
                                yearList.map((year, index) => (
                                    <option key={index} value={year}>
                                        {year}
                                    </option>
                                ))}
                        </FormInput>
                    </Col>
                    <Col lg={2} md={2} sm={2} xs={2}>
                        <ButtonElement
                            icon={<i className="mdi mdi-plus"></i>}
                            className="p-0 add-fiscal-year-btn mt-10"
                            variant="primary"
                            type="button"
                            onClick={() => {
                                modalDispatch({
                                    type: modalActions.OPEN_MODAL,
                                    payload: {
                                        modalAction: (e) => modalDispatch({ type: modalActions.CLOSE_MODAL }),
                                        modalVariant: 'primary',
                                        modalDataKey: ['common', 'replaceAsset'],
                                        data: {
                                            handleActions: handleData,
                                            recordId: props?.data?.recordId,
                                            currentYear: currentYear,
                                            name: 'Add',
                                        },
                                    },
                                });
                            }}
                        />
                    </Col>
                    <Col md={2} xs={2}>
                        <ButtonElement
                            className="action-icon"
                            onClick={() =>
                                modalDispatch({
                                    type: modalActions.OPEN_MODAL,
                                    payload: {
                                        modalAction: (e) => console.log(''),
                                        modalVariant: 'primary',
                                        modalDataKey: ['systemAdmin', 'addReplaceDownloads'],
                                    },
                                })
                            }>
                            <i className="mdi mdi-message-plus-outline" />
                        </ButtonElement>
                    </Col>
                </Row>
            </Col>
            <Card>
                <Table
                    columns={TableColumn.systemAdmin.manageDownloads([Actions])}
                    data={dataList}
                    pageSize={TableColumn.pageSize}
                    sizePerPageList={TableColumn.sizePerPageList(dataList)}
                    isSortable={true}
                    pagination={true}
                    isSearchable={false}
                    isFilter={false}
                    theadClass={TableColumn.table_light}
                    tableClass="manage-downloads"
                    tableParentClass="table-auto-height manage-downloads-wrap"
                />
            </Card>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement name="Done" variant="primary" type="button" onClick={props?.hideModal} />
            </div>
        </>
    );
};

export default ManageDownloadsModal;
