// @flow
import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { userValidationErrorDownload, userImportReset } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { downloadFile } from '../../../helpers/downloadFile/downloadFile';

const PreflightDataModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();

    const { userValidationErrorDownload_ } = useSelector((state) => ({
        userValidationErrorDownload_: state?.userImport?.userValidationErrorDownload,
    }));

    useEffect(() => {
        if (userValidationErrorDownload_) {
            downloadFile(
                userValidationErrorDownload_?.data,
                'application/zip',
                userValidationErrorDownload_?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(userImportReset());
        }
    }, [userValidationErrorDownload_]);

    const downloadAnnotationSheet = (errorId) => {
        if (props?.data?.KeyName) {
            props?.data?.handleDownloadError({
                pkXLSErrorId: errorId,
                isPreflight: true,
                isUpdater: props?.data?.isUpdater,
            });
        } else {
            dispatch(
                userValidationErrorDownload({
                    pkXLSErrorId: errorId,
                    isPreflight: true,
                    isUpdater: props?.data?.isUpdater,
                })
            );
        }
    };

    const handleImport = () => {
        props?.data?.handleImport();
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
        });
    };

    return (
        <>
            <Row>
                <Col lg={12}>
                    <p>The following results would be achieved by importing this data file as-is:</p>
                    {props?.data?.data?.successRowCount > 0 && (
                        <div className="success">
                            <p>
                                Data for <b>{props?.data?.data?.successRowCount}</b> rows <b>would pass</b> the
                                validation process.
                            </p>
                        </div>
                    )}
                    {props?.data?.data?.success && (
                        <div className="success">
                            <p>
                                Data for <b>All</b> rows <b>would pass</b> the validation process.
                            </p>
                        </div>
                    )}
                    {props?.data?.data?.failedRowCount > 0 && (
                        <div className="fail hide">
                            <p>
                                Data for{' '}
                                <b>
                                    {props?.data?.data?.failedRowCount > 0 && props?.data?.data?.successRowCount === 0
                                        ? 'all'
                                        : props?.data?.data?.failedRowCount}
                                </b>{' '}
                                rows <b>would fail</b> the validation process; download the annotated spreadsheet to
                                learn why, correct the data, and test again.
                            </p>
                            <div className="button-list d-flex justify-content-center mt-2">
                                <ButtonElement
                                    name="Download Annotated Spreadsheet"
                                    variant="outline-primary"
                                    type="button"
                                    onClick={() => downloadAnnotationSheet(props?.data?.data?.pkXlsErrorId)}
                                />
                            </div>
                        </div>
                    )}
                </Col>
            </Row>
            {!props?.data?.isNotification ? (
                <div className="modal-footer d-flex justify-content-center mt-3">
                    <ButtonElement
                        name="Upload"
                        variant="primary"
                        type="button"
                        onClick={handleImport}
                        disabled={props?.data?.data?.successRowCount > 0 || props?.data?.data?.success ? false : true}
                    />
                    <ButtonElement
                        name="Cancel"
                        variant="light"
                        type="button"
                        onClick={(e) => {
                            if (!props?.data?.isNotification) props.data.resetForm();
                            modalDispatch({
                                type: modalActions.CLOSE_MODAL,
                            });
                        }}
                    />
                </div>
            ) : (
                <div className="modal-footer d-flex justify-content-center mt-3">
                    <ButtonElement
                        name="Close"
                        variant="light"
                        type="button"
                        onClick={(e) => {
                            if (!props?.data?.isNotification) props.data.resetForm();
                            modalDispatch({
                                type: modalActions.CLOSE_MODAL,
                            });
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default PreflightDataModal;
