// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
// components
import ButtonElement from '../../../../components/button/ButtonElement';
import { useModalContext } from '../../../../contexts/ModalContext';
import { modalActions } from '../../../../reducers/actions';
import TinyMceTextEditor from '../../../../components/form/TinyMceTextEditor';
import {
    cpSiteUpdateGreeting,
    cpReportUpdateGreeting,
    cpSiteGetHeaderImage,
    cpReportGetHeaderImage,
} from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';

const BtdGreetingMessageModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [greetingMessage, setGreetingMessage] = useState('');
    const dispatchAction = useDispatch();
    const [accessCriteria, setaccessCriteria] = useState(null);
    const { updateSiteGreeting, updateReportGreeting, authInfo, imageAndMsgInfo, imageAndMsgInfoForReport } =
        useSelector((state) => ({
            updateSiteGreeting: state?.cpSite?.updateGreeting,
            updateReportGreeting: state?.cpReport?.updateGreeting,
            authInfo: state?.auth?.me,
            imageAndMsgInfo: state?.cpSite?.getHeaderImage?.data,
            imageAndMsgInfoForReport: state?.cpReport?.getHeaderImage?.data,
        }));
    const {
        control,
        register,
        formState: { errors },
    } = useForm({});
    useEffect(() => {
        if (props?.data?.siteOrReport == 'site') {
            dispatchAction(
                cpSiteGetHeaderImage({ templateId: props?.data?.templateId, orgId: authInfo?.organaisationId })
            );
        }
        if (props?.data?.siteOrReport == 'report') {
            dispatchAction(
                cpReportGetHeaderImage({
                    templateId: props?.data?.templateId,
                    orgId: authInfo?.organaisationId,
                    reportYear: props?.data?.year,
                })
            );
        }
    }, [props?.data?.templateId]);
    useEffect(() => {
        if (props?.data?.siteOrReport == 'site') {
            imageAndMsgInfo && setGreetingMessage(imageAndMsgInfo.colGreetingMessage);
            imageAndMsgInfo?.colGreetingMsgMaxCount && setaccessCriteria(imageAndMsgInfo?.colGreetingMsgMaxCount);
        }
        if (props?.data?.siteOrReport == 'report') {
            imageAndMsgInfoForReport && setGreetingMessage(imageAndMsgInfoForReport?.colGreetingMessage);
            imageAndMsgInfoForReport?.colGreetingMsgMaxCount &&
                setaccessCriteria(imageAndMsgInfoForReport?.colGreetingMsgMaxCount);
        }
    }, [imageAndMsgInfo, imageAndMsgInfoForReport]);

    useEffect(() => {
        if (updateSiteGreeting || updateReportGreeting) {
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
            });
        }
    }, [updateSiteGreeting, updateReportGreeting]);

    const handleUpdateGreeting = () => {
        const payload = {
            templateId: props?.data?.templateId,
            greetingMessage: greetingMessage,
        };
        props?.data?.siteOrReport == 'report' && (payload.reportYear = props?.data?.year);

        props?.data?.siteOrReport == 'site'
            ? dispatchAction(cpSiteUpdateGreeting(payload))
            : dispatchAction(cpReportUpdateGreeting(payload));
    };

    return (
        <Row>
            <Row>
                <Col lg={12}>
                    <Controller
                        control={control}
                        name="greetingMessage"
                        render={({ field }) => (
                            <TinyMceTextEditor
                                disableEnter={false}
                                maxLength={accessCriteria ? accessCriteria : 650}
                                errors={errors}
                                id="greetingMessage"
                                className="editor-height"
                                register={register}
                                value={greetingMessage}
                                setValue={setGreetingMessage}
                            />
                        )}
                    />{' '}
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Update"
                    variant="outline-primary"
                    type="button"
                    disabled={greetingMessage ? false : true}
                    onClick={() => (greetingMessage ? handleUpdateGreeting() : console.log('msg'))}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </Row>
    );
};

export default BtdGreetingMessageModal;
