import React from 'react';
// Platform Admin Left Menu Pages
export const Dashboard = React.lazy(() => import('../../pages/platform/dashboard/Dashboard'));
export const SystemForm = React.lazy(() => import('../../pages/platform/dashboard/SystemForm'));
export const DataMigration = React.lazy(() => import('../../pages/platform/dashboard/DataMigration'));
export const RestoreSystem = React.lazy(() => import('../../pages/platform/dashboard/RestoreSystem'));
export const NewRootNode = React.lazy(() => import('../../pages/platform/dashboard/NewRootNode'));
export const NewRole = React.lazy(() => import('../../pages/platform/dashboard/NewRole'));
export const PlatformManagement = React.lazy(() =>
    import('../../pages/platform/platformManagement/PlatformManagement')
);
export const EmailTemplate = React.lazy(() => import('../../pages/platform/platformManagement/EmailTemplate'));
export const SessionLog = React.lazy(() => import('../../pages/platform/platformManagement/SessionLog'));
export const SystemAnalytics = React.lazy(() => import('../../pages/platform/systemAnalytics/SystemAnalytics'));
export const Users = React.lazy(() => import('../../pages/platform/users/Users'));
export const UserForm = React.lazy(() => import('../../pages/platform/users/UserForm'));
export const Account = React.lazy(() => import('../../pages/platform/account/Account'));
export const SystemDetails = React.lazy(() => import('../../pages/platform/systemDetails/SystemDetails'));
export const PlatformAdminDetailsForm = React.lazy(() =>
    import('../../pages/platform/platformDetails/PlatformAdminDetailsForm')
);
export const EditBasicConfig = React.lazy(() => import('../../pages/platform/dashboard/EditBasicConfig'));
export const EditElements = React.lazy(() => import('../../pages/platform/editElements/EditElements'));
export const EmailTemplateForm = React.lazy(() => import('../../pages/platform/platformManagement/EmailTemplateForm'));
export const SessionLogDetail = React.lazy(() => import('../../pages/platform/platformManagement/SessionLogDetail'));
