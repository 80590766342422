// @flow
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { FormInput } from '../../../components/form/input';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { RedirectTo } from '../../../routes/role/path';
import { setEditorChanges } from '../storage';

const ReplaceScriptCodeModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [toggle, setToggle] = useState(false);
    const {
        handleSubmit,
        register,
        watch,
        formState: { errors },
    } = useForm({});
    const values = watch();
    const onSubmit = (data) => {
        setDynamicAsset(data?.scriptCode);
        if (
            window.location.pathname.includes(RedirectTo('/site-edit')) ||
            window.location.pathname.includes(RedirectTo('/report-edit'))
        ) {
            setEditorChanges(true);
        }
    };

    const setDynamicAsset = (embedCode) => {
        const ele = document.getElementById('rootComponent');
        let sectionNum = null;
        const sectionId = ele.getAttribute('section-id');

        if (sectionNum >= 0) {
            const oldElement = document.querySelectorAll(`[sectionid=${sectionId}]`);
            const sectionid = sectionId;
            let clss = oldElement[0]?.getAttribute('class');
            clss = clss.replace('document-background-color', '');
            clss = clss.replace('align-center-wrapper', '');
            const divElement = document.createElement('div');
            divElement.setAttribute('sectionid', sectionid);
            divElement.setAttribute('ineditmode', false);
            divElement.setAttribute('frameborder', 0);
            divElement.setAttribute('class', clss.trim());
            divElement.innerHTML = embedCode;
            oldElement[0].replaceWith(divElement);
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
            });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput
                className="custom-textarea mb-3"
                type="textarea"
                as="textarea"
                id="scriptCode"
                label="Embed Code"
                height="true"
                errors={errors}
                register={register}
            />
            <div className="button-list d-flex justify-content-center">
                <ButtonElement name="Insert" variant="primary" type="submit" disabled={!values?.scriptCode} />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </form>
    );
};

export default ReplaceScriptCodeModal;
