// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { GroupNameValidation } from '../../../components/form/validation';
import { groupUpdate } from '@ovrture/react-redux';

const RenameGroupModal = (props) => {
    let payload = props.data.unCheckedUserListId.map((item) => item.id);
    const [toggle, setToggle] = useState(false);
    const dispatch = useDispatch();

    const {
        register,
        setValue,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: GroupNameValidation, mode: 'onBlur' });
    const values = watch();

    const onSubmit = (data) => {
        setToggle(false);
        sessionStorage.setItem(process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY, 'Group renamed.');
        dispatch(groupUpdate({ id: props?.data?.selectedItem?.id, name: data.name, unCheckedUserListId: payload }));
    };
    useEffect(() => {
        if (props.data) {
            setValue('name', props?.data?.selectedItem?.title);
        }
    }, [props.data]);
    const textChangeHandle = (e) => {
        if (values?.name === e.target.value) {
            setToggle(false);
        } else {
            setValue('name', e.target.value);
            setToggle(true);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col lg={12}>
                    <FormInput
                        type="text"
                        id="name"
                        label="Group Name *"
                        errors={errors}
                        register={register}
                        onChange={(e) => textChangeHandle(e)}
                    />
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement name="Rename" variant="primary" type="submit" disabled={!toggle ? true : false} />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={(e) => props.hideModal()} />
            </div>
        </form>
    );
};

export default RenameGroupModal;
