import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActivities } from '@ovrture/react-redux';
import { clearBrowserCache } from '../helpers/browserCache';
import HandleCache from '../helpers/handleCache/HandleCache';

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => ({
        user: state?.auth?.user,
    }));
    const editors = ['/content-panel-edit', '/content-broadcast-create', '/site-edit', '/report-edit'];

    useEffect(() => {
        window.scrollTo(0, 0);
        clearBrowserCache();

        setTimeout(() => {
            if (!editors.find((f) => window.location.pathname.includes(f))) {
                dispatch(
                    userActivities({
                        colStatusCode: 200,
                        colRequestCompleteUrl: window.location.href,
                        colHttpVariables: null,
                    })
                );
            }
        }, 1000);
    }, []);

    return (
        <HandleCache>
            <Route
                {...rest}
                render={(props) => {
                    if (!user) {
                        return <Redirect to={{ pathname: '/public/login', state: { from: props.location } }} />;
                    }
                    return <Component {...props} />;
                }}
            />
        </HandleCache>
    );
};

export default PrivateRoute;
