// @flow
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Row, Col, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// components
import { FormInput } from '../../../../components/form/input';
import Table from '../../../../components/table/Table';
import ButtonElement from '../../../../components/button/ButtonElement';
import { table as TableColumn } from '../../../../constants/table';
import {
    paletteList,
    accessCriteriaForReportDataReset,
    accessCriteriaForReportDataFetchImpact,
    accessCriteriaForReportDataAddImpact,
    accessCriteriaForReportDataUpdateImpact,
} from '@ovrture/react-redux';
import { ObjToArrayConvert } from '../../../../utility';
import { dateTimeFormat } from '../../../../utility';

const FundImpactModal = (props) => {
    const {
        register,
        handleSubmit,
        setValue,
        control,
        setError,
        setFocus,
        reset,
        formState: { errors },
    } = useForm({});
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const [fundImpactTypeId, setFundImpactTypeId] = useState(null);
    const [paletteId, setPaletteId] = useState(null);
    const [updatedAt, setUpdatedAt] = useState();

    const { list, impactTypeGetList, paletteDataList, add, update, orgFetch, loading } = useSelector((state) => ({
        paletteDataList: state?.palette?.list?.data,
        impactTypeGetList: state?.impactType?.list?.data,
        list: state?.accessCriteriaForReportData?.fetchImpact,
        add: state?.accessCriteriaForReportData?.addImpact,
        update: state?.accessCriteriaForReportData?.updateImpact,
        loading: state?.accessCriteriaForReportData?.loading,
        orgFetch: state?.organization?.fetch?.data,
    }));
    useEffect(() => {
        if (list) setUpdatedAt(dateTimeFormat(list?.colUpdatedAt));
    }, [list]);
    useEffect(() => {
        if (list) {
            setValue('paletteId', list?.fkPaletteId);
            setValue('fundImpactTypeId', list?.fkFundImpactTypeId);
            setValue('colCallout1Size', list?.colCallout1Size);
            setValue('colCallout2Size', list?.colCallout2Size);
            setValue('colDescriptionSize', list?.colDescriptionSize);
            setValue('colMediumDescriptionSize', list?.colMediumDescriptionSize);
            setValue('colLongDescriptionSize', list?.colLongDescriptionSize);
            setValue('colImageHeightSize', list?.colImageHeightSize);
            setValue('colImageWidthSize', list?.colImageWidthSize);
            setValue('colDefaultImageUrl', list?.colDefaultImageUrl);
            setValue('colQuoteSize', list?.colQuoteSize);
            setData(ObjToArrayConvert(list, 4));
        }
        if (add) {
            dispatch(accessCriteriaForReportDataReset());
            props?.hideModal();
        }
        if (update) {
            dispatch(accessCriteriaForReportDataReset());
            props?.hideModal();
        }
    }, [list, add, update]);

    useEffect(() => {
        if (fundImpactTypeId) dispatch(paletteList({ orgId: orgFetch?.id }));
    }, [fundImpactTypeId]);

    useEffect(() => {
        if (paletteId && fundImpactTypeId) {
            reset();
            setValue('paletteId', paletteId);
            dispatch(
                accessCriteriaForReportDataFetchImpact({
                    orgId: orgFetch.id,
                    paletteId: paletteId,
                    fundImpactTypeId: fundImpactTypeId,
                })
            );
        }
    }, [paletteId, fundImpactTypeId]);

    const onSubmit = (data) => {
        if (data.fundImpactTypeId) data.fundImpactTypeId = parseInt(data.fundImpactTypeId);
        if (data?.paletteId) data.paletteId = parseInt(data.paletteId);
        if (data?.colDescriptionSize) data.colDescriptionSize = parseInt(data.colDescriptionSize);
        if (data?.colMediumDescriptionSize) data.colMediumDescriptionSize = parseInt(data.colMediumDescriptionSize);
        if (data?.colLongDescriptionSize) data.colLongDescriptionSize = parseInt(data.colLongDescriptionSize);
        if (data?.colImageHeightSize) data.colImageHeightSize = parseInt(data.colImageHeightSize);
        if (data?.colImageWidthSize) data.colImageWidthSize = parseInt(data.colImageWidthSize);
        if (data?.colCallout1Size) data.colCallout1Size = parseInt(data.colCallout1Size);
        if (data?.colCallout2Size) data.colCallout2Size = parseInt(data.colCallout2Size);
        if (data?.colQuoteSize) data.colQuoteSize = parseInt(data.colQuoteSize);

        list?.pkAccessCriteriaForFundImpactId
            ? dispatch(
                  accessCriteriaForReportDataUpdateImpact({
                      orgId: orgFetch.id,
                      pkAccessCriteriaForFundImpactId: list.pkAccessCriteriaForFundImpactId,
                      ...data,
                  })
              )
            : dispatch(accessCriteriaForReportDataAddImpact({ orgId: orgFetch.id, ...data }));
    };

    const toggle = () => {
        dispatch(accessCriteriaForReportDataReset());
        props?.hideModal();
    };

    const FieldName = ({ row }) => {
        return <ButtonElement as="text" name={row.original.fieldName} tooltip={row.original.replacer}></ButtonElement>;
    };

    const handleMandatory = (name, row) => {
        const check = !data[row.index][name];
        data[row.index][name] = check;
        setData(data);
        setError(name, row.original[name]);
        setValue(name, check);
    };

    const MandatoryColumn = ({ row }) => {
        return (
            <FormInput
                type="switch"
                className="mb-0 col-md-0"
                id={row.original.key + 'IsMandatory'}
                register={register}
                onChange={(e) => handleMandatory(row.original.key + 'IsMandatory', row)}
                checked={row.original[row.original.key + 'IsMandatory']}
            />
        );
    };
    const RenderColumn = ({ row }) => {
        return (
            <FormInput
                type="switch"
                className="mb-0 col-md-0"
                id={row.original.key}
                register={register}
                checked={row.original[row.original.key]}
                onChange={(e) => handleMandatory(row.original.key, row)}
            />
        );
    };
    const DefaultValue = ({ row }) => {
        return (
            <Controller
                name={row.original.key + 'DefaultValue'}
                control={control}
                rules={{ required: row.original[row.original.key + 'IsMandatory'] === true ? true : false }}
                defaultValue={row.original[row.original.key + 'DefaultValue']}
                render={({ field }) => (
                    <>
                        <FormInput
                            type="text"
                            id={row.original.key + 'DefaultValue'}
                            className="mb-0"
                            register={register}
                            errors={errors}
                            onChange={(e) => {
                                delete errors[e?.target?.name];
                                e?.target?.classList?.remove('is-invalid');
                            }}
                            feedback="hide"
                            floatinglabel="false"
                        />
                    </>
                )}
            />
        );
    };
    const PreprocessingValue = ({ row }) => {
        return (
            <FormInput
                type="text"
                id={row.original.key + 'PreProcessingValue'}
                className="mb-0"
                register={register}
                defaultValue={row.original[row.original.key + 'PreProcessingValue']}
                feedback="hide"
                floatinglabel="false"
            />
        );
    };

    const setFocusValue = () => {
        setTimeout(() => {
            let errorsArr = [];
            Object.keys(errors).forEach((k) => {
                errorsArr.push({ key: k, value: errors[k] });
            });
            let error = errorsArr.find((f) => f?.value?.type === 'required');
            setFocus(error?.key);
        }, 500);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {list?.colUpdatedAt && <p className="text-end custom-font mb-1">Last Edited: {updatedAt}</p>}
            <Card>
                <Card.Body>
                    <Row>
                        <Col md={6}>
                            <FormInput
                                type="select"
                                id="fundImpactTypeId"
                                className="mb-md-0"
                                label="Impact *"
                                register={register}
                                onChange={(e) => setFundImpactTypeId(e.target.value)}>
                                <option value="">Select</option>
                                {impactTypeGetList?.map((item, index) => {
                                    return (
                                        <option key={index} value={item?.pkImpactTypeId}>
                                            {item?.colImpactName}
                                        </option>
                                    );
                                })}
                            </FormInput>
                        </Col>
                        <Col md={6} className="custom-spacing">
                            <FormInput
                                type="select"
                                id="paletteId"
                                label="Palette *"
                                className={fundImpactTypeId ? 'mb-0' : 'custom-disabled'}
                                register={register}
                                onChange={(e) => setPaletteId(e.target.value)}>
                                <option value="">Select</option>
                                {paletteDataList?.map((item, index) => {
                                    return (
                                        <option key={index} value={item?.pkPaletteId}>
                                            {item?.colPaletteName}
                                        </option>
                                    );
                                })}
                            </FormInput>
                        </Col>
                    </Row>
                </Card.Body>
                {paletteId && (
                    <>
                        <Table
                            columns={TableColumn.editElent.reportData.fundImpact([
                                FieldName,
                                RenderColumn,
                                MandatoryColumn,
                                DefaultValue,
                                PreprocessingValue,
                            ])}
                            data={data}
                            pageSizes={list?.data?.size}
                            pageNumber={list?.data?.number}
                            totalElements={list?.data?.totalElements}
                            totalPages={list?.data?.totalPages}
                            paginationDispatch={accessCriteriaForReportDataFetchImpact}
                            paginationDispatchParam={{ orgId: orgFetch?.data?.id }}
                            sizePerPageList={TableColumn.sizePerPageList(data)}
                            isSortable={false}
                            pagination={false}
                            isSearchable={false}
                            isFilter={false}
                            isSelectable={false}
                            loading={loading}
                            theadClass={TableColumn.table_light}
                            tableClass="report-data-fund-impact"
                            tableParentClass="height-fixed"
                        />
                        <Card.Body>
                            <Row className="mt-1 pb-0">
                                <Col md={4}>
                                    <FormInput
                                        type="number"
                                        id="colQuoteSize"
                                        label="Quote Size"
                                        register={register}
                                        negative="false"
                                    />
                                </Col>
                                <Col md={4}>
                                    <FormInput
                                        type="number"
                                        id="colCallout1Size"
                                        label="Callout Size 1"
                                        register={register}
                                        negative="false"
                                    />
                                </Col>
                                <Col md={4}>
                                    <FormInput
                                        type="number"
                                        id="colCallout2Size"
                                        label="Callout Size 2"
                                        register={register}
                                        negative="false"
                                    />
                                </Col>
                                <Col md={4}>
                                    <FormInput
                                        type="number"
                                        id="colDescriptionSize"
                                        label="Description Size (Short)"
                                        register={register}
                                        negative="false"
                                    />
                                </Col>
                                <Col md={4}>
                                    <FormInput
                                        type="number"
                                        id="colMediumDescriptionSize"
                                        label="Description Size (Medium)"
                                        register={register}
                                        negative="false"
                                    />
                                </Col>
                                <Col md={4}>
                                    <FormInput
                                        type="number"
                                        id="colLongDescriptionSize"
                                        label="Description Size (Long)"
                                        register={register}
                                        negative="false"
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormInput
                                        type="number"
                                        id="colImageWidthSize"
                                        label="Image Width"
                                        register={register}
                                        negative="false"
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormInput
                                        type="number"
                                        id="colImageHeightSize"
                                        label="Image Height"
                                        register={register}
                                        negative="false"
                                    />
                                </Col>
                                <Col md={12}>
                                    <FormInput
                                        className="mb-0"
                                        type="text"
                                        id="colDefaultImageUrl"
                                        label=" Default Image Url"
                                        register={register}
                                        defaultValue={list?.colDefaultImageUrl ? list?.colDefaultImageUrl : ''}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </>
                )}
            </Card>

            <div className="button-list d-flex justify-content-center">
                {fundImpactTypeId && paletteId && (
                    <ButtonElement name="Update" variant="primary" type="submit" onClick={() => setFocusValue()} />
                )}
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggle} />
            </div>
        </form>
    );
};

export default FundImpactModal;
