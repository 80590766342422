import React, { useRef, useEffect, useState, useCallback } from 'react';
import RadioButtonSelect from './RadioButtonSelect';
import { ListGroup, InputGroup, Form } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import ListItem from '.';
import CheckboxSelect from './CheckboxSelect';

const ListItemSelection = ({
    id,
    data,
    name,
    list,
    setList,
    fetchMore,
    isSearchFilter,
    dynamicSearch,
    loading,
    type,
    onClick,
    disabled,
    isRightArray,
    isLeftArray,
    searchData,
    icon,
    getSearchValue,
    getHandleSearch,
    ...props
}) => {
    const listInnerRef = useRef();
    const [listItems, setListItems] = useState([]);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        if (searchText && !dynamicSearch) {
            handleSearch(searchText);
        } else {
            setListItems([...list]);
        }
    }, [list]);

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
                if (data?.number + 1 < data?.totalPages) {
                    setTimeout(() => {
                        fetchMore({ page: data?.number + 1, searchText: dynamicSearch ? searchText : '' });
                    }, 500);
                }
            }
        }
    };

    const handleSearch = (text) => {
        if (dynamicSearch) {
            dynamicSearch({ page: 0, searchText: text });
        } else {
            const search = text.toLocaleLowerCase();
            const filterList = list.filter((el) => {
                return (
                    el?.title?.toLocaleLowerCase()?.includes(search) ||
                    el?.name?.toLocaleLowerCase()?.includes(search) ||
                    el?.colFullName?.toLocaleLowerCase()?.includes(search)
                );
            });
            setListItems([...filterList]);
        }
        setSearchText(text);
        getHandleSearch && getHandleSearch();
    };

    const searchInput = useCallback(debounce((text) => handleSearch(text), 1000));

    return (
        <ListGroup className="custom-listgroup" onScroll={fetchMore && onScroll} ref={listInnerRef}>
            {isSearchFilter && (
                <InputGroup className="input-group-merge input-group-flush input-group p-2 custom-border">
                    <InputGroup.Text>
                        <i className="mdi mdi-magnify"></i>
                    </InputGroup.Text>
                    <Form.Control
                        placeholder={`Search...`}
                        aria-label={`Search for ${searchData && searchData}`}
                        // placeholder={`Search for ${searchData && searchData}`}
                        onChange={(e) => {
                            searchInput(e.target.value);
                            getSearchValue && getSearchValue(e.target.value);
                        }}
                    />
                </InputGroup>
            )}

            <div>
                {listItems?.map((item, index) => {
                    return (
                        <ListItem
                            key={index}
                            active={props?.activeSelection === item.id}
                            disabled={
                                disabled
                                    ? disabled?.filter((d) => item?.id === d?.id).length > 0
                                        ? true
                                        : (
                                              props.isBrandAdmin
                                                  ? item?.roleId === 5 ||
                                                    item.roleId === 6 ||
                                                    item.roleId === 7 ||
                                                    item.roleId === 10
                                                  : item?.roleId === 5 ||
                                                    item.roleId === 6 ||
                                                    item.roleId === 2 ||
                                                    item.roleId === 7 ||
                                                    item.roleId === 10
                                          )
                                        ? true
                                        : false
                                    : false
                            }
                            onClick={(e) => {
                                onClick && onClick(item);
                                e.preventDefault();
                            }}
                            className={
                                id
                                    ? (id === item.selectedLeftId && isLeftArray && item.selectedLeft && 'active') ||
                                      (id === item.selectedRightId && isRightArray && item.selectedRight && 'active')
                                    : (isRightArray && item.selectedRight && 'active') ||
                                      (isLeftArray && item.selectedLeft && 'active')
                            }>
                            {type === 'radio' && (
                                <RadioButtonSelect list={listItems} setList={setList} item={item} name={name} />
                            )}
                            {type === 'checkbox' && (
                                <CheckboxSelect
                                    list={listItems}
                                    setList={setList}
                                    item={item}
                                    name={name}
                                    checked={item?.checked}
                                />
                            )}
                            {/* {icon && icon} */}
                            {item?.title || item?.name || item?.colFullName}
                        </ListItem>
                    );
                })}
            </div>
            {!loading && listItems.length === 0 && (
                <div className="text-center">
                    <h5>No Data Available.</h5>
                </div>
            )}
        </ListGroup>
    );
};
export default ListItemSelection;
