import PrivateRoute from '../PrivateRoute';
import { COMMON_PATH, PLATFORM_ADMIN_PATH, PUBLIC_PATH } from '../role/path';
import { PrivacyPolicy } from './generalRoutes';
import {
    Account,
    SessionLog,
    SessionLogDetail,
    Dashboard,
    DataMigration,
    EditBasicConfig,
    EditElements,
    EmailTemplate,
    EmailTemplateForm,
    NewRole,
    NewRootNode,
    PlatformAdminDetailsForm,
    PlatformManagement,
    RestoreSystem,
    SystemAnalytics,
    SystemDetails,
    SystemForm,
    UserForm,
    Users,
} from './platformAdminRoutes';

export const tenantSystemsModule = {
    path: PLATFORM_ADMIN_PATH.TENANT_SYSTEMS,
    name: 'TENANT SYSTEMS',
    title: 'Tenant Systems',
    exact: true,
    component: Dashboard,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Tenant Systems', path: PLATFORM_ADMIN_PATH.TENANT_SYSTEMS, active: true }],
    children: [
        {
            path: PLATFORM_ADMIN_PATH.CREATE_NEW_SYSTEM,
            name: 'CREATE NEW SYSTEM',
            title: 'Create New',
            exact: true,
            component: SystemForm,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Tenant Systems ', path: PLATFORM_ADMIN_PATH.TENANT_SYSTEMS, active: false },
                { label: 'Create New', path: '/', active: true },
            ],
        },
        {
            path: PLATFORM_ADMIN_PATH.MIGRATE_SYSTEM_DATA,
            name: 'MIGRATE SYSTEM DATA',
            title: 'Migrate Data',
            exact: true,
            component: DataMigration,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Tenant Systems', path: PLATFORM_ADMIN_PATH.TENANT_SYSTEMS, active: false },
                { label: 'Migrate Data', path: '/', active: true },
            ],
        },
        {
            path: PLATFORM_ADMIN_PATH.RESTORE_PURGE_SYSTEM,
            name: 'RESTORE/PURGE SYSTEM',
            title: 'Restore/Purge',
            exact: true,
            component: RestoreSystem,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Tenant Systems', path: PLATFORM_ADMIN_PATH.TENANT_SYSTEMS, active: false },
                { label: 'Restore/Purge', path: '/', active: true },
            ],
        },
        {
            path: PLATFORM_ADMIN_PATH.ADD_ROOT_NODE,
            name: 'ADD ROOT NODE',
            title: 'Add Root Node',
            exact: true,
            component: NewRootNode,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Tenant Systems ', path: PLATFORM_ADMIN_PATH.TENANT_SYSTEMS, active: false },
                { label: 'Add Root Node', path: '/', active: true },
            ],
        },
        {
            path: PLATFORM_ADMIN_PATH.ADD_ROLE,
            name: 'ADD ROLE',
            title: 'Add Role',
            exact: true,
            component: NewRole,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Tenant Systems', path: PLATFORM_ADMIN_PATH.TENANT_SYSTEMS, active: false },
                { label: 'Add Role', path: '/', active: true },
            ],
        },
        {
            path: PLATFORM_ADMIN_PATH.EDIT_CONFIGURATION + '/:id',
            name: 'EDIT CONFIGURATION',
            title: 'Edit Configuration',
            exact: true,
            component: EditBasicConfig,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Tenant Systems', path: PLATFORM_ADMIN_PATH.TENANT_SYSTEMS, active: false },
                { label: 'Edit Configuration', path: '/', active: true },
            ],
        },
        {
            path: PLATFORM_ADMIN_PATH.EDIT_ELEMENTS + '/:id',
            name: 'EDIT ELEMENTS',
            title: 'Edit Elements',
            exact: true,
            component: EditElements,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Tenant Systems', path: PLATFORM_ADMIN_PATH.TENANT_SYSTEMS, active: false },
                { label: 'Edit Elements', path: '/', active: true },
            ],
        },
    ],
};

export const tenantDataModule = {
    path: PLATFORM_ADMIN_PATH.TENANT_ANALYTICS,
    name: 'TENANT SYSTEM DATA',
    title: 'Tenant Data',
    exact: true,
    component: SystemAnalytics,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Tenant Data', path: '/', active: true }],
};
export const platformManagementModule = {
    path: PLATFORM_ADMIN_PATH.PLATFORM,
    name: 'PLATFORM',
    title: 'Platform',
    exact: true,
    component: PlatformManagement,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Platform', path: '/', active: true }],
    children: [
        {
            path: PLATFORM_ADMIN_PATH.EMAIL_CORRESPONDENCE,
            name: 'EMAIL NOTIFICATIONS',
            title: 'Email',
            exact: true,
            component: EmailTemplate,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Platform', path: PLATFORM_ADMIN_PATH.PLATFORM, active: false },
                { label: 'Email Notifications', path: '/', active: true },
            ],
        },
        {
            path: PLATFORM_ADMIN_PATH.CREATE_NEW_EMAIL,
            name: 'CREATE NEW EMAIL',
            title: 'Create New',
            exact: true,
            component: EmailTemplateForm,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Platform', path: PLATFORM_ADMIN_PATH.PLATFORM, active: false },
                { label: 'Email Notifications', path: PLATFORM_ADMIN_PATH.EMAIL_CORRESPONDENCE, active: false },
                { label: 'Create New', path: '/', active: true },
            ],
        },
        {
            path: PLATFORM_ADMIN_PATH.EDIT_EMAIL + '/:id',
            name: 'EDIT EMAIL',
            title: 'Edit Email',
            exact: true,
            component: EmailTemplateForm,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Platform', path: PLATFORM_ADMIN_PATH.PLATFORM, active: false },
                { label: 'Email Notifications', path: PLATFORM_ADMIN_PATH.EMAIL_CORRESPONDENCE, active: false },
                { label: 'Edit Email', path: '/', active: true },
            ],
        },
        {
            path: PLATFORM_ADMIN_PATH.SESSION_LOG,
            name: 'SESSION LOG',
            title: 'Session Log',
            exact: true,
            component: SessionLog,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Platform', path: PLATFORM_ADMIN_PATH.PLATFORM, active: false },
                { label: 'Session Log', path: '/', active: true },
            ],
        },
        {
            path: PLATFORM_ADMIN_PATH.SESSION_ACTIVITY_DETAIL + '/:pkUserSessionLogId',
            name: 'SESSION ACTIVITY DETAIL',
            title: 'Session Activity Detail',
            exact: true,
            component: SessionLogDetail,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Platform', path: PLATFORM_ADMIN_PATH.PLATFORM, active: false },
                { label: 'Session Log', path: PLATFORM_ADMIN_PATH.SESSION_LOG, active: false },
                { label: 'Activity Detail', path: '/', active: true },
            ],
        },
    ],
};
export const cloudDetailsModule = {
    path: PLATFORM_ADMIN_PATH.PLATFORM_DETAILS,
    name: 'CLOUD ENVIRONMENT DETAILS',
    title: 'Cloud Details',
    exact: true,
    component: PlatformAdminDetailsForm,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Cloud Details', path: '/', active: true }],
};
export const latestBuildModule = {
    path: PLATFORM_ADMIN_PATH.LATEST_PLATFORM_BUILD,
    name: 'LATEST PLATFORM BUILD',
    title: 'Latest Build',
    exact: true,
    component: SystemDetails,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Latest Build', path: '/', active: true }],
};
export const usersModule = {
    path: PLATFORM_ADMIN_PATH.USERS,
    name: 'USERS',
    title: 'Users',
    exact: true,
    component: Users,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Users', path: '/', active: true }],
    children: [
        {
            path: PLATFORM_ADMIN_PATH.CREATE_NEW_USER,
            name: 'CREATE NEW USER',
            title: 'Create New',
            exact: true,
            component: UserForm,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Users', path: PLATFORM_ADMIN_PATH.USERS, active: false },
                { label: 'Create New', path: '/', active: true },
            ],
        },
        {
            path: PLATFORM_ADMIN_PATH.EDIT_USER + '/:id',
            name: 'EDIT USER',
            title: 'Edit User',
            exact: true,
            component: UserForm,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Users', path: PLATFORM_ADMIN_PATH.USERS, active: false },
                { label: 'Edit User', path: '/', active: true },
            ],
        },
    ],
};
export const accountModule = {
    path: COMMON_PATH.ACCOUNT,
    name: 'ACCOUNT',
    title: 'Account',
    exact: true,
    component: Account,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Account Settings & Security', path: '/', active: true }],
};
export const privacyPolicyModule = {
    path: PUBLIC_PATH.PRIVACY_POLICY,
    name: 'PRIVACY POLICY',
    title: 'Privacy Policy',
    component: PrivacyPolicy,
    exact: true,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Privacy Policy', path: '/', active: true }],
    pathExact: '/privacypolicy',
};
