import loaderIcon from '../../assets/images/loader@2x.gif';
export const CustomLoader = (props) => {
    return (
        props.loader && (
            <>
                <div className="spinner-border-loader">
                    <img src={loaderIcon}></img>
                </div>
                {document.getElementsByClassName('modal-backdrop')?.length === 0 && (
                    <div className="modal-backdrop-notification opacity-less loader-backdrop"></div>
                )}
            </>
        )
    );
};
