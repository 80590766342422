// @flow
import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import TreeView from '../../../components/treeview';
import {
    assetFolderViewInitialListChange,
    assetFolderViewReset,
    assetFolderViewInitialListChangeReset,
} from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTreeViewContext } from '../../../contexts/TreeViewContext';
import { treeViewActions } from '../../../reducers/actions';
import { treeData } from '../../../constants/treeData';
import { useModalContext } from '../../../contexts/ModalContext';

const CreateFundReplaceAssetModal = (props) => {
    const { treeState, treeDispatch } = useTreeViewContext();
    const [addfolderDisabled, setAddFolderDisabled] = useState(true);
    const [treeListData, setTreeListData] = useState(null);
    const [data, setData] = useState([]);
    const [toNode, setToNode] = useState(null);
    const dispatch = useDispatch();

    const { initialList } = useSelector((state) => ({
        initialList: state?.assetFolderView?.initialListChange,
    }));

    useEffect(() => {
        getInitialFolderList();
    }, []);

    const getInitialFolderList = (parentId = '#') => {
        dispatch(assetFolderViewInitialListChange({ processId: '', parentId }));
    };

    useEffect(() => {
        if (treeState?.treeData) {
            treeDispatch({
                type: treeViewActions.GET_TREE_DATA,
                payload: {
                    treeData: treeData,
                },
            });
        }
        if (treeState?.treeData) {
            setTreeListData(treeState);
        }
    }, [treeState?.treeData]);

    useEffect(() => {
        if (initialList?.data) {
            dispatch(assetFolderViewReset());
            async function createInitialData() {
                await manageTreeData(data);
            }
            if (data.length > 0) {
                createInitialData(initialList?.data);
            } else {
                initialList?.data?.forEach((el) => {
                    data.push({
                        id: el.id,
                        parent_id: el.id,
                        text: el?.name || el?.text,
                        icon: el.icon,
                        children: [],
                        child: el.child,
                        adminId: el.adminId,
                        assetId: el.assetId,
                        brandId: el.brandId,
                        fkRoleId: el.fkRoleId,
                        fkOrgId: el.fkOrgId,
                        fkPaletteId: el.fkPaletteId,
                        assetRootNodeId: el.assetRootNodeId,
                        userId: el.userId,
                        permissionbyId: el.permissionbyId,
                        canBeDeleted: el.canBeDeleted,
                    });
                });
                setData([...data]);
            }
            dispatch(assetFolderViewReset());
        }
    }, [initialList]);

    const manageTreeData = (list) => {
        initialList?.data?.forEach((el) => {
            list?.forEach((d) => {
                if (el?.parentId === d?.id) {
                    let disabled = false;
                    d.child = false;
                    if (props?.data?.fromNode?.id === el.id || el.icon === 'glyphicon glyphicon-text-background-cp')
                        disabled = true;
                    d.children.push({
                        id: el.id,
                        parent_id: el.parentId,
                        text: el?.text || el?.name,
                        icon: el.icon,
                        children: [],
                        child: el.child,
                        adminId: el.adminId,
                        assetId: el.assetId,
                        brandId: el.brandId,
                        fkRoleId: el.fkRoleId,
                        fkOrgId: el.fkOrgId,
                        fkPaletteId: el.fkPaletteId,
                        userId: el.userId,
                        assetRootNodeId: el.assetRootNodeId,
                        permissionbyId: el.permissionbyId,
                        canBeDeleted: el.canBeDeleted,
                        state: {
                            disabled,
                        },
                    });
                    const filterArry = d.children.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
                    const ids = initialList?.data.map((d) => d.id);
                    const newList = filterArry.filter((f) => ids.indexOf(f.id) !== -1);
                    d.children = [...newList];
                } else {
                    if (d.children.length > 0) {
                        manageTreeData(d.children);
                    }
                }
            });
        });
        setData([...list]);
    };

    function handleGetList(e, list) {
        setToNode(list?.node);
        if (list.event?.type === 'dblclick' && list?.node?.original?.id && list?.node?.original?.child) {
            getInitialFolderList(list?.node?.original?.id);
        }
        if (list?.node?.original?.id) {
            if (list?.node.parent === '#' && list?.node?.original.text === 'SYSTEM CLOUD') {
                setAddFolderDisabled(true);
            } else if (
                (list?.node?.original.icon === 'mdi mdi-cloud' && list?.node?.original.text === 'BRAND CLOUD') ||
                list?.node?.original.icon === 'mdi mdi-folder-lock'
            ) {
                setAddFolderDisabled(true);
            } else if (
                list?.node?.original.icon === 'fa-fa-folder-brand-closed' ||
                list?.node?.original.icon === 'fa-fa-folder-palette-closed' ||
                list?.node?.original.icon === 'mdi mdi-folder-account' ||
                list?.node.icon === 'mdi mdi-folder' ||
                list?.node.icon === 'glyphicon glyphicon-doc-file' ||
                list?.node.icon === 'glyphicon glyphicon-pdf-file' ||
                list?.node.icon === 'glyphicon-doc-file'
            ) {
                setAddFolderDisabled(true);
            } else if (list?.node.children.length) {
                setAddFolderDisabled(true);
            } else if (
                list?.node.icon === 'glyphicon glyphicon-video' ||
                list?.node.icon === 'glyphicon glyphicon-image'
            ) {
                setAddFolderDisabled(false);
            }
        }
    }

    const onSubmit = () => {
        dispatch(assetFolderViewInitialListChangeReset());
        props.data.handleActions(toNode?.original);
        props.hideModal();
    };

    return (
        <Row className="jstree-custom-style">
            {treeListData && <TreeView data={data} handleChange={handleGetList} />}
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Replace"
                    variant="outline-primary"
                    type="button"
                    disabled={addfolderDisabled}
                    onClick={() => onSubmit()}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() => {
                        props.hideModal();
                    }}
                />
            </div>
        </Row>
    );
};

export default CreateFundReplaceAssetModal;
