import tinymce from 'tinymce';
/* Default icons are required for TinyMCE 5.3 or above */
import 'tinymce/icons/default';
/* A theme is also required */
import 'tinymce/themes/silver';
/* Import the skin */
import 'tinymce/skins/ui/oxide/skin.css';
/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import { lockedAndUnlocked } from '../notification/lockUnlock';
import { getEditorStorage, setEditorChanges } from '../storage';
import { RedirectTo } from '../../../routes/role/path';

document?.addEventListener('click', (e) => {
    if (window.location.pathname === RedirectTo('/content-panel-edit')) lockedAndUnlocked(e, tinymce);
});

export const tinymceInlineInit = (e) => {
    tinymceInlineInitFactory('.inline-textEditor:not(.non-editable)');
};

export const tinymceInlineInitFactory = (param) => {
    tinymce.init({
        selector: param,
        icons: 'material',
        menubar: false,
        inline: true,
        plugins: ['lists', 'wordcount', 'autolink', 'link', 'contextmenu'],
        contextmenu: '',
        //plugins: ['lists', 'powerpaste', 'wordcount', 'autolink', 'link'],
        toolbar: 'bold italic underline bullist numlist link unlink wordCountHolder',
        toolbar_items_size: 'small',
        browser_spellcheck: false,
        link_assume_external_targets: 'false',
        paste_webkit_styles: 'none',
        paste_data_images: false,
        paste_as_text: true,
        paste_merge_formats: false,
        paste_block_drop: false,
        paste_word_valid_elements: 'b,strong,i,em,h1,h2',
        paste_retain_style_properties: 'color font-size',
        setup: function (editor) {
            editor.ui.registry.addButton('wordCountHolder', {
                text: '',
                onAction: () => {},
            });
            editor.on('ExecCommand', function (e) {
                if (
                    tinymce?.activeEditor?.contentAreaContainer?.attributes?.maxlength &&
                    !tinymce?.activeEditor?.contentAreaContainer?.classList?.contains('non-editable')
                ) {
                    setCharCount(e);
                } else if (tinymce?.activeEditor?.contentAreaContainer?.classList?.contains('non-editable')) {
                    tinymce.EditorManager.execCommand('mceRemoveEditor', true, e.target.id);
                    e.target.container.style.display = 'none';
                }
            });
            editor.on('keyup keypress keydown', function (e) {
                if (e.type === 'keypress') return negativeValidation();
                if (e.type === 'keydown') return hardReturnBlock(e);
                if (e.type === 'keyup') setCharCount(e);
            });
            editor.on('blur', function (e) {
                removeTox();
            });
            editor.on('dragstart dragover dragend', (e) => {
                e.preventDefault();
                e.stopPropagation();
            });
            editor.on('paste', function (e) {
                var clipboardData, pastedData, inputEditorData, selectedData, maxLengthNumber, noOfHardReturns, total;
                clipboardData = e.clipboardData || window.clipboardData;
                pastedData = clipboardData.getData('Text');
                if (window.getSelection())
                    selectedData = tinymce.activeEditor.selection
                        .getContent({ format: 'text' })
                        .toString()
                        .replace(/\n|\r/g, '');
                maxLengthNumber = Number(tinymce.activeEditor.contentAreaContainer.attributes.maxlength.value);
                inputEditorData = tinymce.activeEditor.contentAreaContainer.innerText.length - selectedData.length;
                noOfHardReturns = pastedData?.split(/\r|\r\n|\n/)?.length - 1;
                total = inputEditorData + pastedData?.length;
                if (total > maxLengthNumber) {
                    e.stopPropagation();
                    e.preventDefault();
                }
            });
        },
    });
};

export const tinymceRemove = () => tinymce.remove();

// export const checkLockedContent = (e) => {
//     let textLength = document.querySelectorAll('.assetseditor.inline-textEditor');
//     for (let i = 0; i < textLength.length; i++) {
//         if (textLength[i].classList.contains('non-editable')) {
//             textLength[i]?.classList.remove('tinymceEditorAllowed');
//         } else {
//             textLength[i].classList.add('tinymceEditorAllowed');
//         }
//     }
// };

const negativeValidation = () => {
    let getCharacterCount = Number(tinymce.activeEditor.plugins.wordcount.body.getCharacterCount());
    let selectedChar = tinymce?.activeEditor?.selection
        ?.getContent({ format: 'text' })
        ?.toString()
        ?.replace(/\n|\r/g, '')?.length;
    if (window.getSelection() && selectedChar > 0) getCharacterCount = getCharacterCount - selectedChar;
    return Number(tinymce.activeEditor.contentAreaContainer.attributes.maxlength.value) - Number(getCharacterCount) > 0
        ? true
        : false;
};

const hardReturnBlock = (e) => {
    let checkForHardReturnFuntionality = tinymce.activeEditor.contentAreaContainer.classList;
    let getHardReturnFunctionalityDisableClasses = getEditorStorage('checkforHardReturnVarInit');
    //Hard return block for specific classes
    for (let i = 0; i < getHardReturnFunctionalityDisableClasses?.length; i++) {
        if (checkForHardReturnFuntionality.contains(getHardReturnFunctionalityDisableClasses[i]) && e.key === 'Enter') {
            return false;
        } else {
            setCharCount(e);
            setEditorChanges(true);
        }
    }
};

const setCharCount = (e) => {
    // tinymce
    var tinymceInlineActiveToolbar = document.querySelector('.tox-tinymce-inline:not([style*="display: none"])');

    let maxlength = Number(tinymce.activeEditor.contentAreaContainer.attributes.maxlength.value);
    let eleCount = Number(tinymce.activeEditor.plugins.wordcount.body.getCharacterCount());
    var checkHardReturn =
        tinymce.activeEditor.contentAreaContainer.classList.contains('hard-return-block') && e.key === 'Enter';
    let getLinkFunctionalityDisableClasses = getEditorStorage('checkIfLinkFunctionalityAvailable');
    var LinkFunctionActive = true;
    tinymce.activeEditor.contentAreaContainer.classList.forEach((item) => {
        if (getLinkFunctionalityDisableClasses?.includes(item)) {
            LinkFunctionActive = false;
            return;
        }
    });
    if (
        tinymce.activeEditor.contentAreaContainer?.parentElement?.classList?.contains(
            'inner-positioner-header-panel-heading'
        )
    ) {
        LinkFunctionActive = false;
    }
    if (tinymceInlineActiveToolbar == null) {
        return;
    }
    if (!LinkFunctionActive) {
        var linkbtn = tinymceInlineActiveToolbar.querySelector('[aria-label="Insert/edit link"]');
        linkbtn.classList.add('tox-tbtn--disabled');
        linkbtn.setAttribute('aria-disabled', 'true');
    }
    var countbtn = tinymceInlineActiveToolbar.querySelector('.tox-tbtn:last-child');

    if (
        tinymce?.activeEditor?.contentAreaContainer?.innerText === '\n' ||
        tinymce?.activeEditor?.contentAreaContainer?.innerText === ''
    ) {
        countbtn.innerText = maxlength;
    } else if (!checkHardReturn && e.key === 'Enter') {
        countbtn.innerText = maxlength - eleCount;
        setEditorChanges(true);
    } else if (!checkHardReturn && e.key === 'Backspace') {
        if (eleCount === 1 && tinymce.activeEditor.contentAreaContainer.classList.contains('report-update-name')) {
            tinymce.activeEditor.contentAreaContainer.innerText = ' ';
            countbtn.innerText = maxlength - eleCount;
        } else {
            countbtn.innerText = maxlength - eleCount;
        }
        setEditorChanges(true);
    } else {
        countbtn.innerText = maxlength - eleCount;
    }
};

export const removeTox = () => {
    if (
        window.location.pathname.includes(RedirectTo('/site-edit')) ||
        window.location.pathname.includes(RedirectTo('/report-edit')) ||
        window.location.pathname.includes(RedirectTo('/content-panel-edit'))
    ) {
        let toxArray = document?.querySelectorAll('.tox.tox-tinymce');
        toxArray?.forEach((element) => {
            element.style.display = 'none';
        });
    }
};
