// @flow
import React from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { modalActions } from '../../../reducers/actions';
import { useDispatch } from 'react-redux';
import { useModalContext } from '../../../contexts/ModalContext';
import { userAdminPlatformBulkHardDelete } from '@ovrture/react-redux';
const DeleteUserModal = (props) => {
    const dispatch = useDispatch();
    const { modalDispatch } = useModalContext();
    return (
        <>
            <p>Are you sure you want to delete this user(s) permanently?</p>
            <div className="modal-footer d-flex justify-content-center">
                <ButtonElement
                    name="Proceed"
                    variant="danger"
                    type="button"
                    onClick={() => {
                        const isSelectAll = props.data.isSelectAll;
                        if (props.data.name === 'bulkDelete') {
                            modalDispatch({ type: modalActions.CLOSE_MODAL });
                            dispatch(
                                userAdminPlatformBulkHardDelete({
                                    userIdList: isSelectAll ? [] : props.data.selectedUserArr.map((item) => item.id),
                                    isSelectAll,
                                    isAnd: true,
                                    filterCriteria: {},
                                })
                            );
                        }
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default DeleteUserModal;
