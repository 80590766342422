export const PLATFORM_ADMIN_PATH = {
    TENANT_SYSTEMS: '/tenants',
    EDIT_CONFIGURATION: '/tenants-edit-configuration',
    EDIT_ELEMENTS: '/tenants-edit-elements',
    CREATE_NEW_SYSTEM: '/tenants-create-new',
    MIGRATE_SYSTEM_DATA: '/tenants-migrate',
    RESTORE_PURGE_SYSTEM: '/tenants-restore-purge',
    ADD_ROOT_NODE: '/tenants-add-root-node',
    ADD_ROLE: '/tenants-add-role',

    PLATFORM: '/platform',
    EMAIL_CORRESPONDENCE: '/email',
    CREATE_NEW_EMAIL: '/email-create',
    EDIT_EMAIL: '/email-edit',
    SESSION_LOG: '/session-log',
    SESSION_ACTIVITY_DETAIL: '/session-activity-detail',

    TENANT_ANALYTICS: '/tenants-data',

    USERS: '/users',
    EDIT_USER: '/users-edit',
    CREATE_NEW_USER: '/users-create',

    LATEST_PLATFORM_BUILD: '/build',

    PLATFORM_DETAILS: '/cloud-details',
};

export const PUBLIC_PATH = {
    PRIVACY_POLICY: '/privacypolicy',
    LOGIN: '/public/login',
};

export const SYSTEM_ADMIN_PATH = {
    USERS: '/users',
    EDIT_USER: '/users-edit',
    EDIT_PROFILE: '/users-edit-profile',
    DATA_AND_ALERTS: '/users-data-alerts',
    CREATE_USER: '/users-create',
    IMPORT_USERS: '/users-import',
    UPDATE_USERS: '/users-update-import',

    DELETE_RESTORE_RECORDS: '/records-delete-restore',

    BROADCAST_ALERT: '/content-broadcast',
    EDIT_BROADCAST: '/content-broadcast-edit',
    CREATE_BROADCAST: '/content-broadcast-create',

    ADMINISTRATORS: '/systemsettings-administrators',
    GROUPS: '/systemsettings-groups',
    BRAND_SETTINGS: '/systemsettings-brands',
    RECORD_SETTINGS: '/systemsettings-records',
};

// export const REPORT_ADMIN_PATH = {};

// export const CONTENT_ADMIN_PATH = {};

// export const BRAND_ADMIN_PATH = {};

// export const DATA_ADMIN_PATH = {};

// export const USER_PATH = {};

export const COMMON_PATH = {
    ROOT: '/',
    REVIEW_AND_APPROVAL: '/review',

    RECORD_PORTFOLIO: '/portfolio',
    RECORD_DATA_AND_SETTINGS: '/portfolio-edit-record',
    CREATE_SITE: '/portfolio-create-site',
    CREATE_NEW_RECORD: '/portfolio-create-record',
    UPDATE_RECORDS: '/portfolio-update-records',

    DIGITAL_ASSETS: '/digitalassets',
    ASSET_CONTROL_ACCESS: '/assets-control-access',

    CONTENT_PANELS: '/content',
    CREATE_PANEL: '/content-panel-create',
    EDIT_PANEL: '/content-panel-edit',
    CONTENT_CONTROL_ACCESS: '/content-control-access',

    CULTIVATION_SITES: '/sites',
    CREATE_SITES: '/sites-create',
    EDIT_SITE: '/site-edit',
    EDIT_CONTACT: '/contact-edit',
    DATA_AND_ALERTS: '/sites-data',
    SITE_ANALYTICS_DATA: '/sites-analytics-data',

    STEWARDSHIP_REPORTS: '/reports',
    CREATE_REPORTS: '/reports-create',
    EDIT_REPORT: '/report-edit',
    REPORT_ANALYTICS_DATA: '/reports-analytics-data',

    USER_PROFILE: '/profile',

    REPORT_DATA: '/reportdata',
    EDIT_FUND_DETAILS: '/reportdata-edit-details',
    EDIT_FY_PERFORMANCE: '/reportdata-edit-performance',
    EDIT_FY_IMPACT: '/reportdata-edit-impact',
    ADD_FY_IMPACT_DETAILS: '/reportdata-add-impact-details',
    EDIT_FY_IMPACT_DETAILS: '/reportdata-edit-impact-details',
    DELETE_FUND: '/reportdata-delete-fund',
    CREATE_FUND: '/reportdata-create-fund',
    IMPORT_FUND_DATA: '/reportdata-import-funds',
    IMPORT_DIGITAL_ASSETS: '/reportdata-import-assets',
    IMPORT_RECORD_GIVING_DATA: '/reportdata-import-record-data',
    IMPORT_RECORD_FY_DOWNLOADS: '/reportdata-import-record-FYasset',

    SYSTEM_SETTINGS: '/systemsettings',
    BUILDER_TABLE_DEFAULTS: '/systemsettings-btd',
    SITE_SETTINGS: '/systemsettings-sites',
    COMMUNICATION_SETTINGS: '/systemsettings-communicatons',
    REPORT_SETTINGS: '/systemsettings-reports',
    ANALYTICS_SETTINGS: '/systemsettings-analytics',
    API_KEY: '/systemsettings-api',

    ENGAGEMENT_LOG: '/engagement',
    ANALYTICS_DASHBOARD: '/engagement-dashboard',

    SYSTEM_DATA_EXPORTS: '/exports',

    ACCOUNT: '/account',
};

export const RedirectTo = (redirectTo) => {
    return process.env.REACT_APP_ROUTE_PREFIX + redirectTo;
};
