// @flow
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { printBridgeSitePreview, printBridgeSiteDownload, printBridgeReset } from '@ovrture/react-redux';
import service from '../../../constants/service.constant';
import { downloadFile } from '../../downloadFile/downloadFile';

const PrintPreview = (props) => {
    console.log(props);
    const { modalDispatch } = useModalContext();
    const [toggle, setToggle] = useState(false);
    const [cpId, setCpId] = useState(null);
    const dispatch = useDispatch();
    const {
        handleSubmit,
        register,
        watch,
        formState: { errors },
    } = useForm({});
    const values = watch();
    const { details, sitePreview, downloaded } = useSelector((state) => ({
        sitePreview: state?.printBridge?.sitePreview,
        details: state?.auth?.me,
        downloaded: state?.printBridge?.siteDownload,
    }));
    useEffect(() => {
        const slideNumber = document.querySelector('#rootComponent[slidesecclass-id]').getAttribute('slidesecclass-id');
        const cpId = document.querySelector('#' + slideNumber + ' [cpid]').getAttribute('cpid');
        setCpId(cpId);
        dispatch(
            printBridgeSitePreview({
                cpHeadeing: props?.data?.panelHeading,
                cpSubHeadeing: 'This is Sub Heading',
                cpHtml: document.querySelector('#' + slideNumber).innerHTML,
                url: props?.data?.colUrl,
            })
        );
    }, []);
    useEffect(() => {
        if (sitePreview?.code === service.success) {
            document.getElementById('previewOnly').innerHTML = sitePreview?.data;
        }
    }, [sitePreview]);
    useEffect(() => {
        if (downloaded) {
            downloadFile(
                downloaded?.data,
                'application/zip',
                downloaded?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(printBridgeReset());
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
            });
        }
    }, [downloaded]);
    const onSubmit = (data) => {
        dispatch(
            printBridgeSiteDownload({
                param: false,
                data: {
                    cpHeadeing: props?.data?.panelHeading,
                    cpSubHeadeing: 'This is Sub Heading',
                    cpHtml: document.querySelector("[cpid='" + cpId + "']").outerHTML,
                    url: props?.data?.colUrl,
                },
            })
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
                <iframe className="previewOnly" id="previewOnly" srcDoc={sitePreview?.data}></iframe>
            </Card>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Download"
                    variant="primary"
                    type="submit"
                    disabled={!sitePreview?.data ? true : false}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </form>
    );
};

export default PrintPreview;
