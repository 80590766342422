import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import 'jstree/dist/jstree.min';
import 'jstree/dist/themes/default/style.css';
import { RedirectTo } from '../../routes/role/path';

export const openMovedPath = (activeJstreePath) => {
    $('.jstree').jstree(true).close_all();
    for (let index = 0; index < activeJstreePath?.length; index++) {
        const m = activeJstreePath[index];
        if (index === activeJstreePath?.length - 1) {
            let element = document.getElementById(m);
            element?.classList?.contains('jstree-closed') && element.querySelector('.jstree-icon.jstree-ocl').click();
        } else {
            let node = $('.jstree').jstree(true)?.get_node(Number(m));
            $('.jstree').jstree(true)?.open_node(node);
        }
    }
};

export const closeAll = () => {
    $('.jstree').jstree(true).close_all();
};

export const removeNode = (nodeId) => {
    var node = $('.jstree').jstree(true).get_node(Number(nodeId));
    $('.jstree').jstree('delete_node', node);
    $('.jstree').jstree(true).refresh();
};

export const parentCustom = (nodeId) => {
    var node = $('.jstree').jstree(true).get_node(Number(nodeId));
    return node.text === 'CUSTOMS';
};

export const parentTemplate = (nodeId) => {
    var node = $('.jstree').jstree(true).get_node(Number(nodeId));
    return node.text === 'TEMPLATES';
};

const historyPage = [
    process.env.REACT_APP_ROUTE_PREFIX + '/content',
    process.env.REACT_APP_ROUTE_PREFIX + '/digitalassets',
    process.env.REACT_APP_ROUTE_PREFIX + '/content-panel-edit',
    process.env.REACT_APP_ROUTE_PREFIX + '/sites-create',
    process.env.REACT_APP_ROUTE_PREFIX + '/reports-create',
    process.env.REACT_APP_ROUTE_PREFIX + '/systemsettings-btd',
];

class TreeView extends Component {
    constructor(props) {
        super(props);
        this.tempData = '';
        this.activateNode = '';
        this.arrowNode = 0;
    }

    static propTypes = {
        treeData: PropTypes.object.isRequired,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        onChange: () => false,
    };

    shouldComponentUpdate(nextProps) {
        return nextProps.treeData !== this.props.treeData;
    }

    componentDidMount() {
        const { treeData } = this.props;
        if (treeData) {
            $(this.treeContainer).jstree(treeData);
            let jstree = $(this.treeContainer);
            const changeLocation = $('.changeLocation');
            if (changeLocation.length > 0) {
                jstree = $('.changeLocation');
            }
            // single click feature
            // $(this.treeContainer).on('changed.jstree', (e, data) => {
            //     if (data?.event?.type) data.event.type = 'dblclick';
            //     this.props.onChange(e, data);
            // });
            // dblclick feature
            $(this.treeContainer).on('changed.jstree', (e, data) => {
                //if (data?.event?.type) data.event.type = 'dblclick';
                this.props.onChange(e, data);
                this.tempData = data;
            });
            $(this.treeContainer).on('dblclick.jstree', (e) => {
                if (this?.tempData?.event?.type) {
                    this.tempData.event.type = 'dblclick';
                    this.props.onChange(e, this.tempData);
                }
            });
            // single click feature for arrow
            $(this.treeContainer).on('click.jstree', (e) => {
                if (e.target.classList.contains('jstree-icon')) {
                    let node = e.target.parentElement;
                    var childrens = jstree.jstree(true).get_node(node.id);
                    if (childrens.children.length === 0) this.openArrowOnSingleClick(node.id);
                    this.disableCP();
                }
            });
            $(this.treeContainer).on('activate_node.jstree', function (event, data) {
                if (data?.node) {
                    localStorage.setItem('selectedNode', JSON.stringify(data?.node));
                    try {
                        if (
                            historyPage.includes(window.location.pathname) ||
                            window?.location?.pathname?.includes('/site-edit') ||
                            window?.location?.pathname?.includes('/report-edit')
                        ) {
                            if (
                                (window.location.pathname === RedirectTo('/content') ||
                                    window.location.pathname === RedirectTo('/content-panel-edit') ||
                                    window.location.pathname === RedirectTo('/sites-create') ||
                                    window.location.pathname === RedirectTo('/reports-create')) &&
                                (data?.node?.icon === 'glyphicon glyphicon-text-background-cp-draft' ||
                                    data?.node?.icon === 'glyphicon glyphicon-text-background-cp')
                            ) {
                                localStorage.removeItem('activeJstreePath');
                                let path = data.instance.get_path(data.node.parent, '/', true).split('/');
                                localStorage.setItem('activeJstreePath', JSON.stringify(path));
                            } else if (
                                window.location.pathname === RedirectTo('/digitalassets') &&
                                (data?.node.icon === 'glyphicon glyphicon-image' ||
                                    data?.node.icon === 'glyphicon glyphicon-doc-file' ||
                                    data?.node.icon === 'glyphicon glyphicon-video' ||
                                    data?.node.icon === 'glyphicon glyphicon-pdf-file')
                            ) {
                                localStorage.removeItem('activeJstreePath');
                                let path = data.instance.get_path(data.node.parent, '/', true).split('/');
                                localStorage.setItem('activeJstreePath', JSON.stringify(path));
                            } else {
                                localStorage.removeItem('activeJstreePath');
                                let path = data.instance.get_path(data.node, '/', true).split('/');
                                localStorage.setItem('activeJstreePath', JSON.stringify(path));
                            }
                        }
                    } catch (ex) {}
                }
            });
            $(this.treeContainer).on('after_open.jstree', function (event, data) {
                //localStorage.setItem('activeJstreeNode', JSON.stringify(data.node));
                data.node.children.forEach((id) => {
                    let dt = data.instance.get_node(id).original;
                    const ele = document.getElementById(dt.id);
                    if (dt?.child && !ele?.classList?.contains('jstree-open')) {
                        if (ele !== null) {
                            ele?.classList?.remove('jstree-leaf');
                            ele?.classList?.add('jstree-closed');
                        } else {
                            // Retry after 1 milisec.
                            setTimeout(() => {
                                data.node.children.forEach((id) => {
                                    let dt = data.instance.get_node(id).original;
                                    if (dt?.child) {
                                        const ele = document.getElementById(dt.id);
                                        ele?.classList?.remove('jstree-leaf');
                                        ele?.classList?.add('jstree-closed');
                                    }
                                });
                            }, 500);
                        }
                    }
                });
            });
            $(this.treeContainer).on('ready.jstree', function (event, data) {
                if (
                    historyPage.includes(window.location.pathname) ||
                    window?.location?.pathname?.includes('/site-edit') ||
                    window?.location?.pathname?.includes('/report-edit')
                ) {
                    try {
                        setTimeout(() => {
                            try {
                                if ($('.jstree')?.jstree(true)) $('.jstree').jstree(true).close_all();
                            } catch (ex) {}
                            let cpObj = JSON.parse(localStorage.getItem('setCPSaved'));
                            if (
                                (window.location.pathname === RedirectTo('/content') ||
                                    window.location.pathname === RedirectTo('/content-panel-edit')) &&
                                cpObj?.draft
                            ) {
                                localStorage.removeItem('setCPSaved');
                                let draftElement = document.getElementById('3');
                                draftElement.classList.contains('jstree-closed') &&
                                    draftElement.querySelector('.jstree-icon.jstree-ocl').click();
                            } else {
                                let activeJstreePath = JSON.parse(localStorage.getItem('activeJstreePath'));
                                localStorage.removeItem('activeJstreePath');
                                // if (window.location.pathname === RedirectTo('/systemsettings-btd')) activeJstreePath.splice(activeJstreePath?.length - 1);
                                for (let index = 0; index < activeJstreePath?.length; index++) {
                                    const m = activeJstreePath[index];
                                    // if (index === activeJstreePath?.length - 1) {
                                    //     let element = document.getElementById(m);
                                    //     if (element?.classList?.contains('jstree-closed')) {
                                    //         setTimeout(() => {
                                    //             element.querySelector('.jstree-icon.jstree-ocl').click();
                                    //         }, 500);
                                    //     }
                                    // } else {
                                    let node = data?.instance?.get_node(Number(m));
                                    data?.instance?.open_node(node);
                                    // }
                                }
                            }
                        }, 2000);
                    } catch (ex) {}
                    //setTimeout(() => $('.jstree').jstree(true).open_all(null, 200), 1000);
                }
            });
            // $(this.treeContainer).on('open_node.jstree', function (event, data) {
            //     if (historyPage.includes(window.location.pathname)) {
            //         localStorage.removeItem('activeJstreePath');
            //         let path = data.instance.get_path(data.node, '/', true).split('/');
            //         localStorage.setItem('activeJstreePath', JSON.stringify(path));
            //     }
            // });
        }
    }

    openArrowOnSingleClick(id) {
        const changeLocation = $('.changeLocation');
        let element =
            changeLocation?.length > 0
                ? $('.changeLocation')
                      ?.find('#' + id)
                      ?.find('.jstree-anchor')
                : $('#' + id)?.find('.jstree-anchor');
        if (element) {
            element.click();
            element.dblclick();
        }
    }

    componentDidUpdate() {
        const { treeData } = this.props;
        if (treeData) {
            let jstree = $(this.treeContainer);
            const changeLocation = $('.changeLocation');
            if (changeLocation.length > 0) {
                jstree = $('.changeLocation');
            }
            jstree.jstree(true).settings = treeData;
            jstree.jstree(true).refresh();
            let selectedNode = JSON.parse(localStorage.getItem('selectedNode'));
            localStorage.removeItem('selectedNode');
            setTimeout(() => {
                if (selectedNode?.state?.opened === false) jstree.jstree(true).open_node(selectedNode);
            }, 500);
            treeData?.core?.data && this.addIcons(treeData?.core?.data);
            this.disableCP();
        }
    }

    disableCP() {
        if (
            window.location.pathname.includes(RedirectTo('/site-edit')) ||
            window.location.pathname.includes(RedirectTo('/report-edit'))
        ) {
            setTimeout(() => {
                const folderViewList = document.querySelectorAll('.slideSecClass [folder_view_id]');
                folderViewList?.forEach((folderView) => {
                    try {
                        const folderViewId = folderView.getAttribute('folder_view_id');
                        document
                            ?.getElementById(folderViewId)
                            ?.querySelector('.jstree-anchor')
                            ?.classList.add('jstree-disabled');
                    } catch (ex) {}
                });
            }, 1000);
        }
    }

    addIcons(data) {
        const changeLocation = $('.changeLocation');
        if (changeLocation.length > 0) {
            data.forEach((dt) => {
                if (dt?.child && dt?.children?.length === 0) {
                    const ele = changeLocation.find('#' + dt.id);
                    ele.removeClass('jstree-leaf');
                    ele.addClass('jstree-closed');
                }
            });
        } else {
            data.forEach((dt) => {
                if (dt?.child && dt?.children?.length === 0) {
                    const ele = document.getElementById(dt.id);
                    ele?.classList?.remove('jstree-leaf');
                    ele?.classList?.add('jstree-closed');
                }
            });
        }
    }

    render() {
        return (
            <div
                ref={(div) => (this.treeContainer = div)}
                className={
                    this?.props?.treejsClass
                        ? this.props.treejsClass + ' ' + this.props?.identifier
                        : 'custom-card-height' + ' ' + this.props?.identifier
                }
            />
        );
    }
}

export default TreeView;
