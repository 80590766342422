import { removeTox, tinymceInlineInitFactory } from '../tinymce/TinyMceInlineTextEditor';

export const lockedAndUnlocked = (e, tinymce) => {
    try {
        if (e.offsetX > e.target.offsetWidth && e.srcElement.classList.contains('assetseditor')) {
            removeTox();
            if (e.target.classList.contains('non-editable')) {
                e.target.setAttribute('contenteditable', 'true');
                e.target.classList.remove('lock', 'non-editable');
                // tinymce edit
                // tinymce.EditorManager.execCommand('mceAddEditor', true, e.target.id);
                tinymceInlineInitFactory('#' + e.target.id);
            } else {
                // this method has to be on top
                tinymce.EditorManager.execCommand('mceRemoveEditor', true, e.target.id);
                e.target.setAttribute('contenteditable', 'false');
                e.target.classList.remove(
                    'mce-content-body',
                    'mce-edit-focus',
                    'tinymceEditorAllowed',
                    'mce-visual-caret'
                );
                e.target.classList.add('lock', 'non-editable');
                document.activeElement.blur();
            }
        }
    } catch (err) {
        console.log(err);
    }
};
