// @flow
import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
    reportDataExportReset,
    reportFundtDataYearList,
    reportPrintBridgeGeneratePdf,
    reportUpdateGetLinkFund,
    reportUpdateResetData,
    reportPrintBridgeReset,
    fundFind,
} from '@ovrture/react-redux';
import { getHtmlString, fundPanelSequenceDetail } from '../migration/TenantMigration';
import { downloadFile } from '../../downloadFile/downloadFile';
import { RedirectTo } from '../../../routes/role/path';

const PrintReportElements = (props) => {
    const { modalDispatch } = useModalContext();
    const [isForPrintFundsAsSinglePDF, setIsForPrintFundsAsSinglePDF] = useState(1);
    const [reportPreferenceId, setReportPreferenceId] = useState(1);
    const [fundId, setFundId] = useState('');
    const [isForNarrativePDFDocument, setisForNarrativePDFDocument] = useState(false);
    const [isForMostRecentUpdates, setisForMostRecentUpdates] = useState(2);
    const [isForImpactPDFDocument, setisForImpactPDFDocument] = useState(false);
    const [toggle1, setToggle1] = useState(true);
    const [organized, setOrganized] = useState('One continuous listing (i.e. one folder)');

    const dispatchAction = useDispatch();
    const [reportYear, setReportYear] = useState(null);

    const { recordUserActivity, yearList, recordUserActivityError, downloaded, reportTabLinkedFund, linkedFundList } =
        useSelector((state) => ({
            recordUserActivity: state?.reportDataExport?.fundPrintBridgeExport,
            recordUserActivityError: state?.reportDataExport?.error,
            yearList: state?.reportDataFund?.yearList,
            downloaded: state?.reportPrintBridge?.pdfFetch,
            reportTabLinkedFund: state?.reportUpdate?.getLinkFund,
            linkedFundList: state?.fund?.find,
        }));

    // reportTabLinkedFund?.map((i) => console.log(i));
    // const data_pk_report_update_id = document
    //     ?.getElementById('rootComponent')
    //     ?.getAttribute('data-pk_report_update_id');
    const data_pk_report_update_id = document
        .getElementsByClassName('report-update-data active')[0]
        ?.getAttribute('data-pk_report_update_id');

    useEffect(() => {
        if (downloaded) {
            dispatchAction(reportPrintBridgeReset());
            dispatchAction(reportUpdateResetData());
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
            });
        }
    }, [downloaded]);

    useEffect(() => {
        dispatchAction(reportFundtDataYearList());
        if (document.getElementsByClassName('reportUpdateOff').length) {
            const payload = {
                reportYear: props?.data?.year,
                pkRecordsId: props?.data?.recordId,
            };
            dispatchAction(fundFind(payload));
        } else {
            dispatchAction(
                reportUpdateGetLinkFund({
                    reportUpdateId: data_pk_report_update_id,
                    reportYear: props?.data?.year,
                })
            );
        }
    }, []);

    useEffect(() => {
        if (recordUserActivity || recordUserActivityError) {
            modalDispatch({ type: modalActions.CLOSE_MODAL });
            dispatchAction(reportDataExportReset());
        }
    }, [recordUserActivity, recordUserActivityError]);

    const downloadFyReportPrintBridge = () => {
        const payload = {
            reportYear: props?.data?.year ?? (reportYear || yearList[0]),
            isForPrintFundsAsSinglePDF: isForPrintFundsAsSinglePDF === 1 ? true : false,
            forNarrativePDFDocument: isForNarrativePDFDocument,
            forImpactPDFDocument: isForImpactPDFDocument,
            forMostRecentUpdates: isForMostRecentUpdates == 1 ? true : false,
            recordId: props?.data?.recordId,
        };
        fundId && (payload.fundIdList = [fundId]);

        if (window.location.pathname.includes(RedirectTo('/report-edit'))) {
            fundPanelSequenceDetail(true);
            payload['reportUpdateId'] = data_pk_report_update_id;
            payload['forEdtitor'] = true;
            payload['html'] = getHtmlString(props.html);
        }
        dispatchAction(reportPrintBridgeGeneratePdf(payload));
    };

    let fundList = [];
    if (reportTabLinkedFund) {
        fundList = reportTabLinkedFund;
    } else if (linkedFundList) {
        fundList = linkedFundList;
    }

    return (
        <>
            <div className="custom-padding print-report-modal">
                <Card className="mb-0">
                    <Card.Body>
                        <Row className="mt-0">
                            <Col md={6}>
                                <FormInput
                                    type="radio"
                                    id="printAllSinglePdf"
                                    name="printType"
                                    className="mt-0"
                                    value={1}
                                    label="Print all funds as a single PDF."
                                    onChange={(e) => setIsForPrintFundsAsSinglePDF(Number(e.target.value))}
                                    checked={isForPrintFundsAsSinglePDF === 1 ? true : false}
                                />

                                <FormInput
                                    type="radio"
                                    id="printAllSeparatePdf"
                                    name="printType"
                                    value={2}
                                    label="Print all funds as separate PDFs."
                                    className="mt-1"
                                    onChange={(e) => setIsForPrintFundsAsSinglePDF(Number(e.target.value))}
                                    checked={isForPrintFundsAsSinglePDF === 2 ? true : false}
                                />
                                <FormInput
                                    type="radio"
                                    id="printSingleFund"
                                    name="printType"
                                    value={3}
                                    label="Print a single fund..."
                                    className="mt-1"
                                    onChange={(e) => setIsForPrintFundsAsSinglePDF(Number(e.target.value))}
                                    checked={isForPrintFundsAsSinglePDF === 3 ? true : false}
                                />
                                <FormInput
                                    type="select"
                                    className="custom-select mt-1 ms-3"
                                    disabled={isForPrintFundsAsSinglePDF === 3 ? false : true}
                                    onChange={(e) => setFundId(e.target.value)}
                                    label="Select Fund">
                                    <option>Select Fund</option>
                                    {fundList?.map((y) => {
                                        return (
                                            <option key={y.id} value={y.pkFundId}>
                                                {y.colFundName}
                                            </option>
                                        );
                                    })}
                                </FormInput>
                            </Col>
                            <Col md={6} className="mt-0">
                                <div className="mt-0 alert alert-info">
                                    <FormInput
                                        type="checkbox"
                                        id="printSupplementDocument"
                                        className="mt-0"
                                        label="Include supplemental documents."
                                        onChange={(e) => setisForImpactPDFDocument(e.target.checked)}
                                    />
                                    <FormInput
                                        type="checkbox"
                                        id="printNarrative"
                                        className="mt-1"
                                        label="Include narrative report content."
                                        onChange={(e) => setisForNarrativePDFDocument(e.target.checked)}
                                    />
                                    <FormInput
                                        type="radio"
                                        id="mostRecentUpdate"
                                        name="reportUpdates"
                                        value={1}
                                        label="Most Recent Update"
                                        className="mt-1 ms-2"
                                        onChange={(e) => setisForMostRecentUpdates(Number(e.target.value))}
                                        checked={isForMostRecentUpdates === 1 ? true : false}
                                        disabled={!isForNarrativePDFDocument}
                                    />
                                    <FormInput
                                        type="radio"
                                        id="allUpdates"
                                        value={2}
                                        name="reportUpdates"
                                        label="All Updates"
                                        className="ms-2 mt-1"
                                        onChange={(e) => setisForMostRecentUpdates(Number(e.target.value))}
                                        checked={isForMostRecentUpdates === 2 ? true : false}
                                        disabled={!isForNarrativePDFDocument}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
            <div className="d-flex justify-content-center modal-footer">
                <ButtonElement
                    name="Print"
                    variant="primary"
                    type="button"
                    onClick={() => {
                        if (toggle1) {
                            setToggle1(false);
                            downloadFyReportPrintBridge();
                        }
                    }}
                />

                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default PrintReportElements;
