const EnterKeyFormSubmit = (props) => {
    document.onkeydown = function (e) {
        try {
            if (e.code === 'Enter' || e.keyCode === 13) {
                if (
                    e?.target?.attributes?.type?.value !== 'textarea' &&
                    e?.target?.attributes?.class?.value?.includes('prevent-form-enter') !== true &&
                    e?.target?.attributes?.class?.value?.includes('inline-textEditor') !== true
                ) {
                    e.preventDefault();
                    const modalFormSubmitBtn = document?.querySelector('.modal-open .show form button[type*="submit"]');
                    const pageFormSubmitBtn = document?.querySelector('.content-page form button[type*="submit"]');
                    //Login screen
                    const publicPagesFormSubmitBtn = document?.querySelector(
                        '.account-pages form button[type*="submit"]'
                    );
                    const modalActionBtn = document.querySelector(
                        '.modal.show button[type*="button"]:not([disabled]):not(.skippable):not(.btn-prev):not(.modal-info-icon):not(.action-icon)'
                    );
                    //Administrator setting page
                    const pageActionBtn = document?.querySelector(
                        '.content-page .tab-pane.active.show button[type*="button"].action-btn'
                    );
                    if (!modalFormSubmitBtn?.attributes?.disabled && modalFormSubmitBtn !== null) {
                        modalFormSubmitBtn.click();
                    } else if (!modalFormSubmitBtn?.attributes?.disabled && modalActionBtn) {
                        modalActionBtn.click();
                    } else if (
                        !document?.body?.classList?.contains('modal-open') &&
                        pageFormSubmitBtn !== null &&
                        !pageFormSubmitBtn?.attributes?.disabled
                    ) {
                        pageFormSubmitBtn.click();
                    } else if (
                        !document?.body?.classList?.contains('modal-open') &&
                        publicPagesFormSubmitBtn !== null &&
                        !publicPagesFormSubmitBtn?.attributes?.disabled
                    ) {
                        publicPagesFormSubmitBtn.click();
                    } else if (
                        !document?.body?.classList?.contains('modal-open') &&
                        pageActionBtn !== null &&
                        !pageActionBtn?.attributes?.disabled
                    ) {
                        pageActionBtn.click();
                    }
                }
            } else if (e.code === 'Escape' || e.keyCode === 27) {
                console.log('esc key pressed');
            }
        } catch (e) {}
    };
    return props.children;
};

export default EnterKeyFormSubmit;
