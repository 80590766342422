// @flow
import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
// components
import ButtonElement from '../../../components/button/ButtonElement';

const SetBroadcastTimerModal = (props) => {
    const [timer, setTimer] = useState('');

    return (
        <>
            
            <p>When would you like this alert to expire?</p>
            <Row>
                <Col xs={5} className="d-flex align-items-center col-md-auto">
                    <span>Remove this alert in</span>
                </Col>
                <Col md={3} xs={4}>
                    <Form.Control
                        value={timer}
                        onChange={(e) => setTimer(e.target.value)}
                        type="number"
                        id="removeExpire"
                    />
                </Col>
                <Col md={2} xs={3} className="d-flex justify-content-start align-items-center px-0">
                    <span>days.</span>
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Set Timer & Broadcast"
                    variant="primary"
                    type="button"
                    disabled={!timer}
                    onClick={() => props?.data?.handleCreateActiveBroadcast(timer)}
                />
                <ButtonElement
                    name="Do Not Set Timer But Broadcast"
                    variant="light"
                    type="button"
                    onClick={() => props?.data?.handleCreateActiveBroadcast()}
                />
            </div>
        </>
    );
};

export default SetBroadcastTimerModal;
