// @flow
import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { reportDataExportReset, fundPerformanceExport, reportFundtDataYearList } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';

const ExportFyPerformanceModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatchAction = useDispatch();
    const [reportYear, setReportYear] = useState('');
    const [toggle, setToggle] = useState(true);
    const { userInfo, performanceExported, yearList } = useSelector((state) => ({
        userInfo: state?.auth?.me,
        performanceExported: state?.reportDataExport?.fundPerformanceExport,
        yearList: state?.reportDataFund?.yearList,
    }));

    useEffect(() => {
        dispatchAction(reportFundtDataYearList());
    }, []);

    useEffect(() => {
        if (performanceExported) {
            modalDispatch({ type: modalActions.CLOSE_MODAL });
            dispatchAction(reportDataExportReset());
        }
    }, [performanceExported]);

    const downloadFyPerformance = () => {
        dispatchAction(fundPerformanceExport({ reportYear: reportYear || yearList[0] }));
    };

    return (
        <>
            <div className="custom-padding">
                <p>Download fund performance data for a specific fiscal year.</p>
                <Col md={6} className="mt-3">
                    <FormInput
                        type="select"
                        onChange={(e) => setReportYear(e.target.value)}
                        className="custom-select"
                        label="Fiscal Year">
                        {yearList &&
                            yearList.map((y) => {
                                return (
                                    <option key={y} value={y}>
                                        {y}
                                    </option>
                                );
                            })}
                    </FormInput>
                </Col>
            </div>
            <div className="d-flex justify-content-center modal-footer">
                <ButtonElement
                    name="Export"
                    variant="primary"
                    type="button"
                    onClick={() => {
                        if (toggle) {
                            setToggle(false);
                            downloadFyPerformance();
                        }
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default ExportFyPerformanceModal;
