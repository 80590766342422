// @flow
import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
// components
import Table from '../../../components/table/Table';
import ButtonElement from '../../../components/button/ButtonElement';
import { table as TableColumn } from '../../../constants/table';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import {
    siteViewEditorList,
    reportEditorList,
    siteEditorFind,
    siteEditorReset,
    reportEditorReset,
} from '@ovrture/react-redux';
import config from '../../../config';
import { dateTimeFormat } from '../../../utility';

const EditorsListModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    let { list, siteEditorList, reportEditorItems, siteLoading, reportLoading } = useSelector((state) => ({
        list: state?.site?.siteViewEditorList,
        siteEditorList: state?.siteEditor?.find,
        siteLoading: state?.siteEditor?.loading,
        reportLoading: state?.reportEditor?.loading,
        reportEditorItems: state?.reportEditor?.list,
    }));
    useEffect(() => {
        if (props?.data?.original?.tblType?.colTypeName === 'Report' || props?.data?.original?.pkReportId) {
            dispatch(
                reportEditorList({
                    pkReportId: props?.data?.original?.fkReportId || props?.data?.original?.pkReportId,
                    sort: 'colLastUpdatedAt,DESC',
                })
            );
        } else if (props?.data?.original?.tblType?.colTypeName === 'Site' || props?.data?.original?.pkSiteId) {
            dispatch(
                siteEditorFind({
                    pkSiteId: props?.data?.original?.fkSiteId || props?.data?.original?.pkSiteId,
                    sort: 'colLastUpdatedAt,DESC',
                })
            );
        } else {
            dispatch(
                siteViewEditorList({
                    page: 0,
                    size: config.PAGE_SIZE,
                    isAnd: true,
                })
            );
        }
    }, []);
    const getData = (itemList) => {
        if (itemList.length > 0) {
            itemList.forEach((item) => {
                item['lastEdited'] = item?.colLastUpdatedAt ? (
                    <ButtonElement
                        as="text"
                        name={dateTimeFormat(item?.colLastUpdatedAt)}
                        title={dateTimeFormat(item?.colLastUpdatedAt)}
                    />
                ) : (
                    '-'
                );
                item['userName'] =
                    <ButtonElement as="text" name={item?.user?.colFullName} title={item?.user?.colFullName} /> || '-';
                item['action'] =
                    <ButtonElement as="text" name={item?.colEditorActionName} title={item?.colEditorActionName} /> ||
                    '-';
            });
            setData(itemList);
        } else {
            setData([]);
        }
    };

    useEffect(() => {
        const data = siteEditorList || reportEditorItems || list?.content || [];
        if (data && data?.length > 0) {
            getData(data);
        } else {
            setData([]);
        }
    }, [siteEditorList, reportEditorItems]);
    console.log('props?.data', props?.data);
    return (
        <>
            {props?.data?.original?.tblType?.colTypeName === 'Report' || props?.data?.original?.pkReportId ? (
                <p>The following changes have been made to this report:</p>
            ) : (
                <p>The following changes have been made to this site:</p>
            )}
            <Card>
                <Table
                    columns={TableColumn.systemAdmin.editorsList()}
                    data={data}
                    isSortable={true}
                    pagination={false}
                    isSearchable={false}
                    isSelectable={false}
                    isFilter={false}
                    theadClass={TableColumn.table_light}
                    tableClass="editors-list"
                    loading={siteLoading || reportLoading}
                />
            </Card>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Close"
                    variant="light"
                    type="button"
                    onClick={(e) => {
                        dispatch(siteEditorReset());
                        dispatch(reportEditorReset());
                        setData([]);
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        });
                    }}
                />
            </div>
        </>
    );
};

export default EditorsListModal;
