//@flow
import React from 'react';
//components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

const ApprovalSendNotification = (props) => {
    const { modalDispatch } = useModalContext();
    return (
        <>
            <p className="pb-2">
                Would you like to send an email notification to the following user that
                <span>{props?.data?.data?.name === 'Site' ? ' this/these site(s)' : ' this/these report(s)'}</span> has
                been approved?
            </p>
            <p className="text-center pt-0">
                <u>{props?.data?.data?.row?.original?.tblRecords?.colRecordOwnerEmailId}</u>
            </p>
            <div className="modal-footer">
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
                <ButtonElement
                    name="No"
                    variant="outline-primary"
                    className="btn-next"
                    type="button"
                    onClick={() => {
                        props?.data?.data.onAprrove(props?.data?.data?.actionFor, props?.data?.data?.row, false);
                    }}
                />
                <ButtonElement
                    name="Yes"
                    variant="primary"
                    className="btn-next"
                    type="button"
                    onClick={() => {
                        props?.data?.data.onAprrove(props?.data?.data?.actionFor, props?.data?.data?.row, true);
                    }}
                />
            </div>
        </>
    );
};

export default ApprovalSendNotification;
