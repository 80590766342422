import PrivateRoute from '../PrivateRoute';
import { COMMON_PATH, SYSTEM_ADMIN_PATH, PUBLIC_PATH } from '../role/path';
import {
    UserSites,
    EditSite,
    MultipleSites,
    AnalyticsData,
    UserReports,
    EditReport,
    MultipleReports,
    Export,
    Analytics,
    AnalyticsDashboard,
    AccountSecurity,
    ReviewAndApproval,
    Users,
    CreateNewUser,
    ImportAndUpdateUsers,
    UserPortfolio,
    EditProfileInformation,
    Assets,
    FyImpactDetail,
    FundDetails,
    ImportFyDownloads,
    ImportGivingData,
    DeleteFund,
    ImportFundResources,
    ImportFund,
    AddFyImpact,
    PerformanceDetails,
    ReportData,
    AssetControlAccess,
    PrivacyPolicy,
} from './generalRoutes';

export const sitesModule = {
    path: COMMON_PATH.CULTIVATION_SITES,
    name: 'CULTIVATION SITES',
    title: 'Sites',
    exact: true,
    component: UserSites,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Sites', path: COMMON_PATH.ROOT, active: true }],
    children: [
        {
            path: COMMON_PATH.CREATE_SITES,
            name: 'CREATE NEW SITES',
            title: 'Create New Sites',
            exact: true,
            component: MultipleSites,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Sites', path: COMMON_PATH.CULTIVATION_SITES, active: false },
                { label: 'Create New Sites', path: COMMON_PATH.ROOT, active: true },
            ],
        },
        {
            path: COMMON_PATH.SITE_ANALYTICS_DATA + '/:id',
            name: 'SITE ANALYTICS DATA',
            title: 'Site Analytics Data',
            exact: true,
            component: AnalyticsData,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Sites', path: COMMON_PATH.CULTIVATION_SITES, active: false },
                { label: 'Site Analytics Data', path: COMMON_PATH.ROOT, active: true },
            ],
        },
        {
            path: COMMON_PATH.EDIT_SITE + '/:id',
            name: 'EDIT SITE',
            title: 'Edit Site',
            exact: true,
            component: EditSite,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Sites', path: COMMON_PATH.CULTIVATION_SITES, active: false },
                { label: 'Edit Site', path: COMMON_PATH.ROOT, active: true },
            ],
        },
    ],
};

export const reportsModule = {
    path: COMMON_PATH.STEWARDSHIP_REPORTS,
    name: 'STEWARDSHIP REPORTS',
    title: 'Reports',
    exact: true,
    component: UserReports,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Reports', path: COMMON_PATH.ROOT, active: true }],
    children: [
        {
            path: COMMON_PATH.CREATE_REPORTS,
            name: 'CREATE NEW REPORTS',
            title: 'Create New Reports',
            exact: true,
            component: MultipleReports,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Reports', path: COMMON_PATH.STEWARDSHIP_REPORTS, active: false },
                { label: 'Create New Reports', path: COMMON_PATH.ROOT, active: true },
            ],
        },
        {
            path: COMMON_PATH.REPORT_ANALYTICS_DATA + '/:id',
            name: 'REPORT ANALYTICS DATA',
            title: 'Report Analytics Data',
            exact: true,
            component: AnalyticsData,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Reports', path: COMMON_PATH.STEWARDSHIP_REPORTS, active: false },
                { label: 'Report Analytics Data', path: COMMON_PATH.ROOT, active: true },
            ],
        },
        {
            path: COMMON_PATH.EDIT_REPORT + '/:id',
            name: 'EDIT REPORT',
            title: 'Edit Report',
            exact: true,
            component: EditReport,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Reports', path: COMMON_PATH.STEWARDSHIP_REPORTS, active: false },
                { label: 'Edit Report', path: COMMON_PATH.ROOT, active: true },
            ],
        },
    ],
};

export const engagementModule = {
    path: COMMON_PATH.ENGAGEMENT_LOG,
    name: 'ENGAGEMENT LOG',
    title: 'Engagement Log',
    exact: true,
    component: Analytics,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Engagement Log', path: '/', active: true }],
    children: [
        {
            path: COMMON_PATH.ANALYTICS_DASHBOARD,
            name: 'ANALYTICS DASHBOARD',
            title: 'Analytics Dashboard',
            exact: true,
            component: AnalyticsDashboard,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Engagement Log', path: COMMON_PATH.ENGAGEMENT_LOG, active: false },
                { label: 'Analytics Dashboard', path: '/', active: true },
            ],
        },
    ],
};

export const exportsModule = {
    path: COMMON_PATH.SYSTEM_DATA_EXPORTS,
    name: 'SYSTEM DATA EXPORTS',
    title: 'Exports',
    exact: true,
    component: Export,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Exports', path: '/', active: true }],
};

export const accountModule = {
    path: COMMON_PATH.ACCOUNT,
    name: 'ACCOUNT SETTINGS & SECURITY',
    title: 'Account Settings & Security',
    exact: true,
    component: AccountSecurity,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Account Settings & Security', path: '/', active: true }],
};

export const reviewAndApprovalModule = {
    path: COMMON_PATH.REVIEW_AND_APPROVAL,
    name: 'REVIEW & APPROVAL',
    title: 'Review & Approval',
    component: ReviewAndApproval,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Review & Approval', path: '/', active: true }],
};

export const usersModule = {
    path: SYSTEM_ADMIN_PATH.USERS,
    name: 'USERS',
    title: 'Users',
    exact: true,
    component: Users,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Users', path: SYSTEM_ADMIN_PATH.USERS, active: true }],
    children: [
        {
            path: SYSTEM_ADMIN_PATH.CREATE_USER,
            name: 'CREATE NEW USER(S)',
            title: 'Create New User(s)',
            exact: true,
            component: CreateNewUser,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Users', path: SYSTEM_ADMIN_PATH.USERS, active: false },
                { label: 'Create New User(s)', path: '/', active: true },
            ],
        },
        {
            path: SYSTEM_ADMIN_PATH.UPDATE_USERS,
            name: 'UPDATE USER(S)',
            title: 'Update User(s)',
            exact: true,
            component: ImportAndUpdateUsers,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Users', path: SYSTEM_ADMIN_PATH.USERS, active: false },
                { label: 'Update User(s)', path: '/', active: true },
            ],
        },
        {
            path: COMMON_PATH.RECORD_PORTFOLIO + '/:id',
            name: 'RECORD PORTFOLIO',
            title: 'Portfolio',
            exact: true,
            component: UserPortfolio,
            route: PrivateRoute,
            breadcrumbs: [{ label: 'Portfolio', path: '/', active: true }],
        },
        {
            path: COMMON_PATH.CULTIVATION_SITES + '/:id',
            name: 'USER SITES',
            title: 'User Sites',
            exact: true,
            component: UserSites,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Users', path: SYSTEM_ADMIN_PATH.USERS, active: false },
                { label: 'Sites', path: '/', active: true },
            ],
        },
        {
            path: COMMON_PATH.STEWARDSHIP_REPORTS + '/:id',
            name: 'USER REPORTS',
            title: 'User Reports',
            exact: true,
            component: UserReports,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Users', path: SYSTEM_ADMIN_PATH.USERS, active: false },
                { label: 'Reports', path: '/', active: true },
            ],
        },
        {
            path: SYSTEM_ADMIN_PATH.EDIT_USER + '/:id',
            name: 'EDIT USER',
            title: 'Edit User',
            exact: true,
            component: CreateNewUser,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Users', path: SYSTEM_ADMIN_PATH.USERS, active: false },
                { label: 'Edit User', path: '/', active: true },
            ],
        },
        {
            path: SYSTEM_ADMIN_PATH.EDIT_PROFILE + '/:id',
            name: 'EDIT PROFILE',
            title: 'Edit Profile',
            exact: true,
            component: EditProfileInformation,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Users', path: SYSTEM_ADMIN_PATH.USERS, active: false },
                { label: 'Edit User', path: SYSTEM_ADMIN_PATH.EDIT_USER + '/id', active: false, params: 'id' },
                { label: 'Profile', path: '/', active: true },
            ],
        },
    ],
};

export const assetsModule = {
    path: COMMON_PATH.DIGITAL_ASSETS,
    name: 'DIGITAL ASSETS',
    title: 'Digital Assets',
    exact: true,
    component: Assets,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Digital Assets', path: '/', active: true }],
    children: [
        {
            path: COMMON_PATH.ASSET_CONTROL_ACCESS,
            name: 'ASSET CONTROL ACCESS',
            title: 'Asset Control Access',
            exact: true,
            component: AssetControlAccess,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Digital Assets', path: COMMON_PATH.DIGITAL_ASSETS, active: false },
                { label: 'Asset Control Access', path: '/', active: true },
            ],
        },
    ],
};

export const reportDataModule = {
    path: COMMON_PATH.REPORT_DATA,
    name: 'REPORT DATA',
    title: 'Report Data',
    exact: true,
    component: ReportData,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Report Data', path: '/', active: true }],
    children: [
        {
            path: COMMON_PATH.CREATE_FUND,
            name: 'CREATE NEW FUND',
            title: 'Create New Fund',
            exact: true,
            component: FundDetails,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Report Data', path: COMMON_PATH.REPORT_DATA, active: false },
                { label: 'Create New Fund', path: '/', active: true },
            ],
        },
        {
            path: COMMON_PATH.EDIT_FUND_DETAILS + '/:id',
            name: 'EDIT FUND DETAILS',
            title: 'Edit Fund Details',
            exact: true,
            component: FundDetails,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Report Data', path: COMMON_PATH.REPORT_DATA, active: false },
                { label: 'Edit Fund Details', path: '/', active: true },
            ],
        },
        {
            path: COMMON_PATH.EDIT_FY_PERFORMANCE + '/:id',
            name: 'EDIT FY PERFORMANCE',
            title: 'Edit FY Performance',
            exact: true,
            component: PerformanceDetails,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Report Data', path: COMMON_PATH.REPORT_DATA, active: false },
                { label: 'Edit FY Performance', path: '/', active: true },
            ],
        },
        {
            path: COMMON_PATH.EDIT_FY_IMPACT + '/:id',
            name: 'EDIT FY IMPACT',
            title: 'Edit FY Impact',
            exact: true,
            component: AddFyImpact,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Report Data', path: COMMON_PATH.REPORT_DATA, active: false },
                { label: 'Edit FY Impact', path: '/', active: true },
            ],
        },
        {
            path: COMMON_PATH.ADD_FY_IMPACT_DETAILS + '/:id/:pkFundId',
            name: 'ADD FY IMPACT DETAILS',
            title: 'Add FY Impact Details',
            exact: true,
            component: FyImpactDetail,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Report Data', path: COMMON_PATH.REPORT_DATA, active: false },
                { label: 'Edit FY Impact', path: COMMON_PATH.EDIT_FY_IMPACT + '/id', active: false, params: 'id' },
                { label: 'Add FY Impact Details', path: '/', active: true },
            ],
        },

        {
            path: COMMON_PATH.IMPORT_FUND_DATA,
            name: 'UPLOAD FUND DATA',
            title: 'Upload Fund Data',
            exact: true,
            component: ImportFund,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Report Data', path: COMMON_PATH.REPORT_DATA, active: false },
                { label: 'Upload Fund Data', path: '/', active: true },
            ],
        },
        {
            path: COMMON_PATH.IMPORT_DIGITAL_ASSETS,
            name: 'UPLOAD DIGITAL ASSETS',
            title: 'Upload Digital Assets',
            exact: true,
            component: ImportFundResources,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Report Data', path: COMMON_PATH.REPORT_DATA, active: false },
                { label: 'Upload Digital Assets', path: '/', active: true },
            ],
        },
        {
            path: COMMON_PATH.DELETE_FUND,
            name: 'DELETE FUND',
            title: 'Delete Fund',
            exact: true,
            component: DeleteFund,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Report Data', path: COMMON_PATH.REPORT_DATA, active: false },
                { label: 'Delete Fund', path: '/', active: true },
            ],
        },
        {
            path: COMMON_PATH.IMPORT_RECORD_GIVING_DATA,
            name: 'UPLOAD RECORD GIVING DATA',
            title: 'Upload Record Giving Data',
            exact: true,
            component: ImportGivingData,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Report Data', path: COMMON_PATH.REPORT_DATA, active: false },
                { label: 'Upload Record Giving Data', path: '/', active: true },
            ],
        },
        {
            path: COMMON_PATH.IMPORT_RECORD_FY_DOWNLOADS,
            name: 'UPLOAD RECORD FY DOWNLOADS',
            title: 'Upload Record FY Downloads',
            exact: true,
            component: ImportFyDownloads,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Report Data', path: COMMON_PATH.REPORT_DATA, active: false },
                { label: 'Upload Record FY Downloads', path: '/', active: true },
            ],
        },

        {
            path: COMMON_PATH.EDIT_FY_IMPACT_DETAILS + '/:id/:pkFundId',
            name: 'EDIT FY IMPACT DETAILS',
            title: 'Edit FY Impact Details',
            exact: true,
            component: FyImpactDetail,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Report Data', path: COMMON_PATH.REPORT_DATA, active: false },
                { label: 'Edit FY Impact', path: COMMON_PATH.EDIT_FY_IMPACT + '/id', active: false, params: 'id' },
                { label: 'Edit FY Impact Details', path: '/', active: true },
            ],
        },
    ],
};

export const profileModule = {
    path: COMMON_PATH.USER_PROFILE,
    name: 'USER PROFILE',
    title: 'PROFILE',
    exact: true,
    component: EditProfileInformation,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Profile', path: '/', active: true }],
};

export const privacyPolicyModule = {
    path: PUBLIC_PATH.PRIVACY_POLICY,
    name: 'PRIVACY POLICY',
    title: 'Privacy Policy',
    component: PrivacyPolicy,
    exact: true,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Privacy Policy', path: '/', active: true }],
    pathExact: '/privacypolicy',
};
