// @flow
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { FormInput } from '../../../components/form/input';
import { embedLinkValidation } from '../../../components/form/validation';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { RedirectTo } from '../../../routes/role/path';
import { setEditorChanges } from '../storage';

const EmbedVideoModal = (props) => {
    const { modalDispatch } = useModalContext();
    const {
        handleSubmit,
        register,
        watch,
        formState: { errors },
    } = useForm({ resolver: embedLinkValidation, mode: 'onBlur' });

    const onSubmit = (data) => {
        setDynamicAsset(data?.embedUrl);
        if (
            window.location.pathname.includes(RedirectTo('/site-edit')) ||
            window.location.pathname.includes(RedirectTo('/report-edit'))
        ) {
            setEditorChanges(true);
        }
    };

    const setDynamicAsset = (src) => {
        const ele = document.getElementById('rootComponent');
        let sectionNum = null;
        const sectionId = ele.getAttribute('section-id');

        if (sectionNum >= 0) {
            const oldElement = document.querySelectorAll(`[sectionid=${sectionId}]`);
            const sectionid = sectionId;
            let clss = oldElement[0]?.getAttribute('class');
            clss = clss.replace('document-background-color', '');
            clss = clss.replace('align-center-wrapper', '');
            const ifrmElement = document.createElement('iframe');
            ifrmElement.setAttribute('sectionid', sectionid);
            ifrmElement.setAttribute('ineditmode', false);
            ifrmElement.setAttribute('src', src);
            ifrmElement.setAttribute('frameborder', 0);
            ifrmElement.setAttribute('class', clss.trim());
            ifrmElement.setAttribute('webkitallowfullscreen', true);
            ifrmElement.setAttribute('mozallowfullscreen', true);
            ifrmElement.setAttribute('allowfullscreen', true);
            oldElement[0].replaceWith(ifrmElement);

            modalDispatch({
                type: modalActions.CLOSE_MODAL,
            });
        }
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput type="text" id="embedUrl" label="Enter Embed URL *" errors={errors} register={register} />
            <div className="button-list d-flex justify-content-center">
                <ButtonElement name="Link" variant="primary" type="submit" />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </form>
    );
};

export default EmbedVideoModal;
