// @flow
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import TreeView from '../../../components/treeview';
import {
    assetFolderViewReset,
    assetFolderViewInitialListChange,
    assetFolderViewInitialListChangeReset,
    assetBulkUploadResource,
    assetBulkUploadReset,
    assePreview,
    assetReset,
} from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTreeViewContext } from '../../../contexts/TreeViewContext';
import { modalActions, treeViewActions } from '../../../reducers/actions';
import { treeData } from '../../../constants/treeData';
import { useModalContext } from '../../../contexts/ModalContext';
import FileUploader from '../../../components/FileUploader';
import { FormInput } from '../../../components/form/input';
import Timeline from './Timeline';
import TimelineItem from './TimelineItem';
import { getFileExtension, covertFileUrlToByte } from '../../../utility';
import $ from 'jquery';
import checkIcon from '../../../assets/images/icons/download-file-icon.svg';
import { CustomLoader } from '../../customLoader';
import { setEditorChanges } from '../storage';
import { RedirectTo } from '../../../routes/role/path';
import { useNotification } from '@ovrture/react-redux/dist/helpers/toasterNotification/NotificationContext';

const ReplaceAssetEditorModal = (props) => {
    const { modalDispatch } = useModalContext();
    const { treeState, treeDispatch } = useTreeViewContext();
    const [addfolderDisabled, setAddFolderDisabled] = useState(true);
    const [uploadDisabled, setuploadDisabled] = useState(true);
    const [treeListData, setTreeListData] = useState(null);
    const [data, setData] = useState([]);
    const [toNode, setToNode] = useState(null);
    const dispatch = useDispatch();
    const [files, setFiles] = useState();
    const [image, setImage] = useState(null);
    const [loader, setLoader] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [isSubmited, setIsSubmited] = useState(false);
    const [index, setIndex] = useState(0);
    const [isSubmit, setIsSubmit] = useState(false);
    const [contentData, setContentData] = useState();
    const errorMessage = 'Asset upload failed due to heavy file size.';
    const { Toast } = useNotification();

    const { initialList, uploadResource, socketSuccess, assetPreviewImage, progressBar } = useSelector((state) => ({
        initialList: state?.assetFolderView?.initialListChange,
        uploadResource: state?.assetBulkUpload?.uploadResource,
        socketSuccess: state?.socket?.success,
        assetPreviewImage: state?.asset?.preview,
        progressBar: state?.socket?.loadingList,
    }));

    useEffect(() => {
        if (props?.data?.contentData) {
            setData(props?.data?.contentData);
            let activeJstreePath = JSON.parse(localStorage.getItem('activeJstreePath'));
            if (activeJstreePath?.length > 0) {
                setTimeout(() => {
                    const ele = document.getElementById(activeJstreePath[0]);
                    if (ele) {
                        ele.getElementsByClassName('jstree-icon jstree-ocl')[0].classList.add('loading');
                    }
                }, 100);
                let id = activeJstreePath[activeJstreePath?.length - 1];
                setTimeout(() => {
                    getInitialFolderList(id);
                }, 1000);
                setTimeout(() => {
                    let element = document?.getElementById(id)?.querySelector('.jstree-anchor');
                    if (element) element.click();
                }, 2500);
            }
        } else {
            getInitialFolderList();
        }
        return () => {
            setIsSubmited(false);
            setLoader(false);
            setIsSubmit(false);
        };
    }, []);
    useEffect(() => {
        if (openModal) {
            modalDispatch({
                type: modalActions.OPEN_MODAL,
                payload: {
                    modalAction: () => {
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        });
                    },
                    data: {
                        data: contentData,
                        contentData: data,
                        activeJstreePath: JSON.parse(localStorage.getItem('activeJstreePath')),
                        assetPreviewProps: props?.data,
                    },
                    modalVariant: 'primary',
                    modalDataKey: ['common', 'assetPanelPreview'],
                },
            });
        }
    }, [openModal]);

    const getInitialFolderList = (parentId = '#') => {
        const ele = document.getElementById(parentId);
        if (ele) ele.getElementsByClassName('jstree-icon jstree-ocl')[0].classList.add('loading');
        dispatch(assetFolderViewInitialListChange({ processId: '', parentId }));
    };

    useEffect(() => {
        if (treeState?.treeData) {
            treeDispatch({
                type: treeViewActions.GET_TREE_DATA,
                payload: {
                    treeData: treeData,
                },
            });
        }
        if (treeState?.treeData) {
            setTreeListData(treeState);
        }
    }, [treeState?.treeData]);

    useEffect(() => {
        if (initialList?.data) {
            async function createInitialData() {
                await manageTreeData(data);
            }
            if (data.length > 0) {
                createInitialData(initialList?.data);
            } else {
                initialList?.data?.forEach((el) => {
                    data.push({
                        id: el.id,
                        parent_id: el.id,
                        text: el?.name || el?.text,
                        icon: el.icon,
                        children: [],
                        child: el.child,
                        adminId: el.adminId,
                        assetId: el.assetId,
                        brandId: el.brandId,
                        fkRoleId: el.fkRoleId,
                        fkOrgId: el.fkOrgId,
                        fkPaletteId: el.fkPaletteId,
                        assetRootNodeId: el.assetRootNodeId,
                        userId: el.userId,
                        permissionbyId: el.permissionbyId,
                        canBeDeleted: el.canBeDeleted,
                    });
                });
                setData([...data]);
            }
            dispatch(assetFolderViewReset());
        }
    }, [initialList]);

    const onCroppedImage = (img) => {
        setDynamicAsset(img, 'image');
        setImage(img);
    };

    useEffect(() => {
        if (assetPreviewImage?.data) {
            if (
                props?.data?.page !== 'asset-replacer' &&
                assetPreviewImage?.data?.toLocaleLowerCase()?.includes('.pdf') &&
                contentData?.icon === 'glyphicon glyphicon-pdf-file'
            ) {
                window.open(assetPreviewImage?.data, '_blank');
            } else {
                const ele = document.getElementById('rootComponent');
                const sectionId = ele.getAttribute('section-id');
                const oldElement = document.querySelectorAll(`.item.active [sectionid=${sectionId}]`);
                const oE = document.getElementsByClassName(sectionId);
                let divWidth = oldElement[0]?.width;
                let divHeight = oldElement[0]?.height;
                const tempRatio = divHeight / divWidth;
                if (window.innerWidth > 1200) {
                    if (700 < divWidth) {
                        divWidth = 700;
                        divHeight = 700 * tempRatio;
                    }
                } else if (1201 > window.innerWidth) {
                    if (550 < divWidth) {
                        divWidth = 550;
                        divHeight = 550 * tempRatio;
                    }
                }
                if (isSubmited) {
                    setIsSubmited(false);
                    const fileData = getFileExtension({ text: assetPreviewImage?.data });
                    if (
                        fileData.toLowerCase() === 'jpg' ||
                        fileData.toLowerCase() === 'jpeg' ||
                        fileData.toLowerCase() === 'png' ||
                        fileData.toLowerCase() === 'svg' ||
                        fileData.toLowerCase() === 'gif'
                    ) {
                        if (fileData.toLowerCase() === 'gif') {
                            covertFileUrlToByte(assetPreviewImage?.data, setImage, toNode?.original?.text);
                        } else {
                            modalDispatch({
                                type: modalActions.OPEN_MODAL,
                                payload: {
                                    modalAction: (e) => {
                                        modalDispatch({
                                            type: modalActions.CLOSE_MODAL,
                                        });
                                    },
                                    modalVariant: 'primary',
                                    modalDataKey: ['systemAdmin', 'cropImage'],
                                    data: {
                                        image: assetPreviewImage?.data,
                                        setCropData: onCroppedImage,
                                        filename: toNode?.original?.text,
                                        name: 'assetReplacer',
                                        minCropBoxWidth: divWidth,
                                        minCropBoxHeight: divHeight,
                                    },
                                },
                            });
                        }
                    } else if (assetPreviewImage?.data.includes('video/')) {
                        setDynamicAsset(assetPreviewImage?.data, 'video');
                    } else {
                        setDynamicAsset(assetPreviewImage?.data, 'file');
                    }
                }
                dispatch(assetReset());
            }
        }
    }, [assetPreviewImage]);

    const manageTreeData = (list) => {
        initialList?.data?.forEach((el) => {
            list?.forEach((d) => {
                if (el?.parentId === d?.id) {
                    d.child = false;
                    let disabled = false;
                    const flag = props?.data?.flag || 0;
                    if (
                        flag === 2 &&
                        (el.icon == 'glyphicon glyphicon-doc-file' ||
                            el.icon == 'glyphicon glyphicon-video' ||
                            el.icon == 'glyphicon glyphicon-pdf-file')
                    ) {
                        // image only
                        disabled = true;
                    }
                    if (
                        flag === 3 &&
                        (el.icon == 'glyphicon glyphicon-image' || el.icon == 'glyphicon glyphicon-video')
                    ) {
                        // doc only
                        disabled = true;
                    }
                    if (
                        flag === 4 &&
                        (el.icon == 'glyphicon glyphicon-doc-file' || el.icon == 'glyphicon glyphicon-pdf-file')
                    ) {
                        // doc not only
                        disabled = true;
                    }

                    d.children.push({
                        id: el.id,
                        parent_id: el.parentId,
                        text: el?.text || el?.name,
                        icon: el.icon,
                        children: [],
                        child: el.child,
                        adminId: el.adminId,
                        assetId: el.assetId,
                        brandId: el.brandId,
                        fkRoleId: el.fkRoleId,
                        fkOrgId: el.fkOrgId,
                        fkPaletteId: el.fkPaletteId,
                        userId: el.userId,
                        assetRootNodeId: el.assetRootNodeId,
                        permissionbyId: el.permissionbyId,
                        canBeDeleted: el.canBeDeleted,
                        state: {
                            disabled,
                        },
                    });
                    const filterArry = d.children.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
                    const ids = initialList?.data.map((d) => d.id);
                    const newList = filterArry.filter((f) => ids.indexOf(f.id) !== -1);
                    d.children = [...newList];
                } else {
                    if (d.children.length > 0) {
                        manageTreeData(d.children);
                    }
                }
            });
        });
        setData([...list]);
    };
    function handleGetList(e, list) {
        setToNode(list?.node);
        if (list.event?.type === 'dblclick' && list?.node?.original?.id && list?.node?.original?.child) {
            getInitialFolderList(list?.node?.original?.id);
        }
        setContentData({ ...list?.node?.original, parentRootId: list?.node?.parent });
        if (list?.node?.original?.id) {
            const iconName = list?.node?.original?.icon?.trim() || '';
            if (
                (list?.node.parent === '#' && list?.node?.original.text === 'SYSTEM CLOUD') ||
                (iconName == 'mdi mdi-cloud' && list?.node?.original.text == 'BRAND CLOUD') ||
                (iconName == 'mdi mdi-cloud' && list?.node?.original.text == 'USER CLOUD') ||
                (iconName === 'mdi mdi-folder-lock' && list?.node?.original.text == 'COVER IMAGES')
                // iconName === 'fa-fa-folder-brand-closed' ||
                // iconName === 'fa-fa-folder-palette-closed' ||
                //iconName === 'mdi mdi-folder-account'
            ) {
                setAddFolderDisabled(true);
                setuploadDisabled(true);
            } else if (
                list?.node.children.length ||
                list?.node.icon.trim() === 'mdi mdi-folder' ||
                list?.node.icon.trim() === 'mdi mdi-folder-lock' ||
                list?.node.icon.trim() === 'fa-fa-folder-brand-closed' ||
                list?.node.icon.trim() === 'mdi mdi-folder-account'
            ) {
                setAddFolderDisabled(true);
                setuploadDisabled(false);
            } else if (
                list?.node.icon === 'glyphicon glyphicon-video' ||
                list?.node.icon === 'glyphicon glyphicon-image'
            ) {
                const icon =
                    list?.node.icon.trim() === 'glyphicon glyphicon-image' ||
                    list?.node.icon.trim() === 'glyphicon glyphicon-video';
                const status = props?.data?.isImageDisable === false && icon ? false : true;
                setAddFolderDisabled(status);
                setuploadDisabled(true);
            } else if (
                list?.node.icon === 'glyphicon glyphicon-doc-file' ||
                list?.node.icon === 'glyphicon glyphicon-pdf-file'
            ) {
                setAddFolderDisabled(false);
                setuploadDisabled(true);
            }
        }
    }

    useEffect(() => {
        if (uploadResource && socketSuccess) {
            setLoader(false);
            // setFiles(null);
            getInitialFolderList(toNode?.id);
            setTimeout(() => {
                files[index].uploaded = true;
                setFiles(files);
                dispatch(assetBulkUploadReset());
                fileUploaded(index);
            }, 1000);
        }
    }, [uploadResource, socketSuccess]);

    const dummyUpload = (index) => {
        files[index].progressBar = { error: errorMessage };
        files[index].uploaded = true;
        setFiles(files);
        setTimeout(() => {
            fileUploaded(index);
        }, 2000);
    };

    const fileUploaded = (index) => {
        let newIndex = index + 1;
        if (newIndex <= files.length - 1) {
            setIndex(newIndex);
            onUploadFile(newIndex);
        } else {
            setTimeout(() => {
                setIndex(newIndex);
                localStorage.removeItem('skipBulkOperation');
                setFiles(null);
                setIsSubmit(false);
                // !files.find((f) => f?.progressBar?.error === errorMessage) && setIsSubmit(false);
            }, 1500);
        }
    };

    useEffect(() => {
        if (progressBar?.length > 0) {
            files[index].progressBar = progressBar;
            setFiles(files);
        }
    }, [progressBar]);

    const onUploadFile = (index = 0) => {
        setIsSubmit(true);
        localStorage.setItem('skipBulkOperation', true);
        if (files[index].typeFile === 'image' && files[index].fileSizeMore32MB) {
            dummyUpload(index);
        } else if (files[index].typeFile === 'video' && files[index].fileSizeMore1GB) {
            dummyUpload(index);
        } else {
            dispatch(assetBulkUploadResource({ parentId: parseInt(toNode?.id) }, { multipartFiles: [files[index]] }));
        }
        // dispatch(assetBulkUploadResource({ parentId: parseInt(toNode?.id) }, { multipartFiles: files }));
    };

    const onSubmit = () => {
        setLoader(true);
        setIsSubmited(true);
        dispatch(assePreview({ assetFolderViewId: toNode?.original?.id }));
    };

    useEffect(() => {
        if (image?.base64Image) {
            setDynamicAsset(image, 'image');
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
            });
        }
    }, [image]);

    const setDynamicAsset = (img, asset = 'image') => {
        let ele = document.getElementById('rootComponent');
        const att = ele?.getAttribute('slidesecclass-id');
        let sectionNum = null;
        const sectionId = ele.getAttribute('section-id');
        const idx = props?.data?.index || 0;
        var clss;
        if (sectionNum >= 0) {
            let oldElement = document.querySelectorAll(`#${att} .item.active [sectionid=${sectionId}]`);
            if (oldElement.length > 0) {
                oldElement = document.querySelectorAll(`#${att} .item.active [sectionid=${sectionId}]`);
                clss = oldElement[0].getAttribute('class');
                if (sectionId === 'imagegallery2 ') {
                    oldElement = document.querySelectorAll(`#${att} .item.active [sectionid="imagegallery2 "]`);
                    clss = oldElement[0].getAttribute('class');
                }
            } else {
                oldElement = document.querySelectorAll(`[sectionid=${sectionId}]`);
                clss = oldElement[0].getAttribute('class');
                if (sectionId === 'imagegallery2 ') {
                    oldElement = document.querySelectorAll(`[sectionid="imagegallery2 "]`);
                    clss = oldElement[0].getAttribute('class');
                }
            }

            const sectionid = sectionId;

            // if (document.querySelectorAll('.broadCastCollapsible.collapse').length > 0) {
            //     if (document.querySelectorAll('.broadCastCollapsible.collapse img')[0]) {
            //         clss = document.querySelectorAll('.broadCastCollapsible.collapse img')[0]?.getAttribute('class');
            //     }
            //     if (document.querySelectorAll('.broadCastCollapsible.collapse iframe')[0]) {
            //         clss = document.querySelectorAll('.broadCastCollapsible.collapse iframe')[0]?.getAttribute('class');
            //     }
            //     if (
            //         document
            //             .querySelectorAll('.broadCastCollapsible.collapse div')[0]
            //             .children[0].getAttribute('onclick')
            //     ) {
            //         clss = document
            //             .querySelectorAll('.broadCastCollapsible.collapse div')[0]
            //             .children[0]?.getAttribute('class');
            //     }
            // }

            var clss;
            if (document.querySelectorAll('.broadCastCollapsible.collapse img')[0]) {
                clss = document.querySelectorAll('.broadCastCollapsible.collapse img')[0]?.getAttribute('class');
            }
            if (document.querySelectorAll('.broadCastCollapsible.collapse iframe')[0]) {
                clss = document.querySelectorAll('.broadCastCollapsible.collapse iframe')[0]?.getAttribute('class');
            }
            if (
                document
                    ?.querySelectorAll('.broadCastCollapsible.collapse div')[0]
                    ?.children[0]?.getAttribute('onclick')
            ) {
                clss = document
                    .querySelectorAll('.broadCastCollapsible.collapse div')[0]
                    .children[0]?.getAttribute('class');
            }
            clss = clss?.replace('document-background-color', '');
            clss = clss?.replace('align-center-wrapper', '');
            // inline-textEditor
            if (asset == 'image') {
                const imgElement = document.createElement('img');
                imgElement.setAttribute('sectionid', sectionid + '_img');
                imgElement.setAttribute('ineditmode', false);
                imgElement.setAttribute('src', img?.base64Image);
                imgElement.setAttribute('frameborder', 0);
                imgElement.setAttribute('alt', sectionId + '_img');
                imgElement.setAttribute('class', clss?.trim());
                imgElement.classList.add(sectionid + '_img');
                if (window.location.pathname === RedirectTo('/content-panel-edit')) {
                    if (oldElement?.length > 1) {
                        oldElement[idx]?.replaceWith(imgElement);
                    } else {
                        oldElement[idx]?.replaceWith(imgElement);
                    }
                } else {
                    oldElement[idx]?.replaceWith(imgElement);
                }
            } else if (asset == 'video') {
                const ifrmElement = document.createElement('iframe');
                ifrmElement.setAttribute('sectionid', sectionid);
                ifrmElement.setAttribute('ineditmode', false);
                ifrmElement.setAttribute('src', img);
                ifrmElement.setAttribute('frameborder', 0);
                ifrmElement.setAttribute('class', clss.trim());
                ifrmElement.classList.add(sectionid);
                ifrmElement.setAttribute('webkitallowfullscreen', true);
                ifrmElement.setAttribute('mozallowfullscreen', true);
                ifrmElement.setAttribute('allowfullscreen', true);

                if (window.location.pathname === RedirectTo('/content-panel-edit')) {
                    if (props?.data?.item?.panelData?.cpFolderView?.name?.toLocaleLowerCase()?.includes('gallery')) {
                        oldElement[idx]?.replaceWith(ifrmElement);
                    } else {
                        oldElement[idx]?.replaceWith(ifrmElement);
                    }
                } else {
                    oldElement[idx]?.replaceWith(ifrmElement);
                }
            } else if (asset == 'file') {
                if (oldElement[idx]?.children[0]?.children[0]?.className?.includes('inline-textEditor')) {
                    oldElement[idx].setAttribute('href', img);
                    const srcArr = img.split('/');
                    oldElement[idx].setAttribute('title', srcArr[srcArr.length - 1]);
                } else {
                    const wOpenUrl = "window.open('" + img + "', '_blank' );";
                    const divElement = document.createElement('div');
                    divElement.setAttribute('sectionid', sectionid);
                    divElement.setAttribute('ineditmode', false);
                    divElement.setAttribute('frameborder', 0);
                    divElement.setAttribute('class', clss.trim() + ' document-background-color align-center-wrapper');
                    divElement.setAttribute('onClick', wOpenUrl);

                    const imgElement = document.createElement('img');
                    imgElement.setAttribute('class', 'documentImg');
                    imgElement.setAttribute('alt', sectionId + '_img');
                    imgElement.setAttribute('src', checkIcon);

                    divElement.appendChild(imgElement);
                    if (window.location.pathname === RedirectTo('/content-panel-edit')) {
                        if (
                            props?.data?.item?.panelData?.cpFolderView?.name?.toLocaleLowerCase()?.includes('gallery')
                        ) {
                            oldElement[idx]?.replaceWith(divElement);
                        } else {
                            oldElement[idx]?.replaceWith(divElement);
                        }
                    } else {
                        oldElement[idx]?.replaceWith(divElement);
                    }
                }
            }
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
            });
        }
        setLoader(false);
        setTimeout(() => {
            Toast.success({ description: 'Asset replaced.' });
        }, 200);
        if (
            window.location.pathname.includes(RedirectTo('/site-edit')) ||
            window.location.pathname.includes(RedirectTo('/report-edit'))
        ) {
            setEditorChanges(true);
        }
    };

    document.addEventListener('keydown', (e) => {
        if (
            (window?.location?.pathname === '/content-panel-edit' ||
                window?.location?.pathname?.includes('/site-edit') ||
                window?.location?.pathname?.includes('/report-edit')) &&
            (e.key == ' ' || e.code == 'Space' || e.keyCode == 32)
        ) {
            if (
                contentData?.icon === 'glyphicon glyphicon-image' ||
                contentData?.icon === 'glyphicon glyphicon-video'
            ) {
                let isModalOpen = document?.querySelector('.modal .modal-title')?.innerText;
                if (!isModalOpen?.includes('Asset Preview')) {
                    setOpenModal(true);
                    return false;
                }
            } else if (contentData?.icon === 'glyphicon glyphicon-pdf-file') {
                dispatch(assePreview({ assetFolderViewId: contentData?.id }));
            }
        }
    });

    return (
        <div className="jstree-custom-style">
            <p>Select file from cloud:</p>
            <Card className="p-1">{treeListData && <TreeView data={data} handleChange={handleGetList} />}</Card>
            <p>Upload file(s): {files?.length}</p>
            <Card className="p-2">
                <CustomLoader loader={loader} />
                <Row>
                    <Col className="uploader-block">
                        <FileUploader
                            className="asset-editor-replacer"
                            onFileUpload={(files) => setFiles(files)}
                            onRemoveFiles={(files) => setFiles(files)}
                            index={index}
                            files={files}
                            isSubmit={isSubmit}
                        />
                    </Col>
                    {!isSubmit && (
                        <Col md={4} className="text-block">
                            <SimpleBar>
                                <Timeline>
                                    <TimelineItem>
                                        {/* For success plz use mdi-check-bold bg-success-lighten text-success classes */}
                                        <i
                                            className={`mdi
                                        ${
                                            files?.length > 0
                                                ? 'mdi-check-bold bg-success-lighten text-success timeline-icon'
                                                : 'mdi-file bg-info-lighten text-info timeline-icon'
                                        }
                                        `}></i>
                                        {/* For success plz use text-primary class */}
                                        <div
                                            className={` block-1 ${
                                                files?.length > 0
                                                    ? 'text-primary timeline-item-info'
                                                    : 'timeline-item-info'
                                            }`}>
                                            <b>Select File</b>
                                        </div>
                                    </TimelineItem>

                                    <TimelineItem>
                                        <i
                                            className={`mdi 
                                    ${
                                        files?.length > 0 && !uploadDisabled
                                            ? 'mdi-check-bold bg-success-lighten text-success timeline-icon'
                                            : 'mdi-folder-open bg-info-lighten text-info timeline-icon'
                                    }
                                    `}></i>
                                        <div
                                            className={` block-2  ${
                                                files?.length > 0 && !uploadDisabled
                                                    ? 'text-primary timeline-item-info'
                                                    : 'timeline-item-info'
                                            }`}>
                                            <b>Select Location</b>
                                        </div>
                                    </TimelineItem>
                                </Timeline>
                            </SimpleBar>
                            <ButtonElement
                                disabled={files?.length > 0 && !uploadDisabled ? false : true}
                                name="Upload"
                                variant="primary"
                                type="button"
                                className="upload-btn"
                                onClick={() => {
                                    setLoader(true);
                                    onUploadFile();
                                }}
                            />
                        </Col>
                    )}
                </Row>
            </Card>

            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Replace"
                    variant="outline-primary"
                    type="button"
                    disabled={addfolderDisabled}
                    onClick={() => onSubmit()}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </div>
    );
};

export default ReplaceAssetEditorModal;
